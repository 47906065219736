import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

// import { getReasons, AddReports, DeleteReasons, EditReason, getReportType } from "../Services/UserServices/Index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import Img from "../../assets/images/small/img-2.jpg";
import { Player } from "video-react";
import "video-react/dist/video-react.css"; // import css
import { getSocialGroups } from "../Services/SocialGroups/index";
import Pagination from "../VerticalLayout/pagination/Pagination";
import defaultUserImage from "../../assets/userDefaulDammyImage/userDefaulDammyImage.png";
import profileImage from "../../assets/images/profile_image.jpg";
class SocialGroups extends Component {
   constructor(props) {
      super(props);

      // this.setEditReason = this.setEditReason.bind(this);
      // this.setAddReasons = this.setAddReasons.bind(this);
      this.setInitialValue = this.setInitialValue.bind(this);

      this.state = {
         pageIDs: [],

         firstName: "",
         lastName: "",
         groupNames: "",
         selectedGroupPosts: [],
         groupID: null,

         profilesImg: "",
         ownerId: "",
         ownerFirstName: "",
         ownerLastName: "",
         ownerEmail: "",
         ownerPhone: "",
         ownerGender: "",
         ownerStatus: "",
         ownerCountry: "",

         allReports: [],
         allSocialGroups: [],

         currentPage: "",
         lastPage: "",
         loading: false,

         reasonTitle: "",
         slectetdIDReason: "",
         slectetdIDReasons: "",
         reasonTitleVal: false,
         slectetdReasonIdVal: false,
      };
   }

   async componentDidMount() {
      this.getAllSocialGroups();
      // this.getAllRoports();
   }

   async getAllSocialGroups(page) {
      try {
         let IDArray = [];

         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         const result = await getSocialGroups(accessToken, page);
         if (result.status === true) {
            for (
               let i = result.all_group_invitations.from;
               i <= result.all_group_invitations.to;
               i++
            ) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });

            this.setState({ loading: true });
            this.setState({
               currentPage: result.all_group_invitations.current_page,
            });
            this.setState({ lastPage: result.all_group_invitations.last_page });
            this.setState({
               allSocialGroups: result.all_group_invitations.data,
            });
         } else {
            console.log("sorry");
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async setInitialValue(groupName, posts) {
      this.setState({
         groupNames: groupName,
         selectedGroupPosts: posts,
      });
   }
   dataLength;
   async setInitialGroupValue(
      data,
      ownerImage,
      groupId,
      OwnerFirstName,
      OwnerLastName,
      OwnerEmail,
      OwnerPhone,
      OwnerGender,
      OwnerStatus,
      OwnerCountry
   ) {
      this.dataLength = data.plates.length;
      this.setState({
         profilesImg: ownerImage,
         ownerId: groupId,
         ownerFirstName: OwnerFirstName,
         ownerLastName: OwnerLastName,
         ownerEmail: OwnerEmail,
         ownerPhone: OwnerPhone,
         ownerGender: OwnerGender,
         ownerStatus: OwnerStatus,
         ownerCountry: OwnerCountry,
      });
   }

   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };

   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllSocialGroups(page);
   };
   render() {
      return (
         <>
            <div className="page-content">
               <div>
                  <h5 className="fs-5 mb-3 fw-bold">Social Groups </h5>
                  {this.state.loading === true ? (
                     <Card className="p-4">
                        <div className="overflow-auto">
                           <table class="table table-responsive">
                              <thead>
                                 <tr>
                                    <th scope="col">No.</th>
                                    <th scope="col">Group Name</th>
                                    <th scope="col">Created By</th>
                                    <th scope="col">Members</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state?.allSocialGroups?.length > 0 ? (
                                    this.state.allSocialGroups.map(
                                       (itemGroup, outer) => (
                                          <tr key={outer}>
                                             <td>
                                                {this.state.pageIDs?.map(
                                                   (item, inner) =>
                                                      outer === inner ? (
                                                         <td key={inner}>
                                                            {item.id}
                                                         </td>
                                                      ) : null
                                                )}
                                             </td>
                                             <td>{itemGroup.name ?? "--"}</td>
                                             <td>
                                                {itemGroup?.group_owner
                                                   ?.first_name
                                                   ? `${
                                                        itemGroup.group_owner
                                                           .first_name
                                                     } ${
                                                        itemGroup.group_owner
                                                           .last_name ?? ""
                                                     }`
                                                   : "--"}
                                             </td>
                                             <td>
                                                <span className="badge bg-light text-dark">
                                                   {itemGroup.total_requests ??
                                                      "--"}
                                                </span>
                                                <br />
                                                <span className="badge bg-success">
                                                   Accepted{" "}
                                                   {itemGroup.accepted ?? "--"}
                                                </span>
                                                <span className="badge bg-warning text-dark mx-2">
                                                   Pending{" "}
                                                   {itemGroup.pending_requests ??
                                                      "--"}
                                                </span>
                                                <span className="badge bg-danger">
                                                   Rejected{" "}
                                                   {itemGroup.rejected ?? "--"}
                                                </span>
                                             </td>
                                             <td>
                                                {this.getDate(
                                                   itemGroup.created_at
                                                )}
                                             </td>
                                             <td>
                                                <Link
                                                   to="#"
                                                   className="me-3 text-primary"
                                                   onClick={() =>
                                                      this.setInitialValue(
                                                         itemGroup.name,
                                                         itemGroup.posts
                                                      )
                                                   }
                                                >
                                                   <i
                                                      className="mdi mdi-eye font-size-18"
                                                      title="View"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#staticBackdropTwo"
                                                   ></i>
                                                </Link>
                                             </td>
                                          </tr>
                                       )
                                    )
                                 ) : (
                                    <p>No Record Found</p>
                                 )}
                              </tbody>
                           </table>
                        </div>
                        {this.state.lastPage > 1 && (
                           <Pagination
                              pageCount={this.state.lastPage}
                              paginate={this.paginate}
                           />
                        )}
                     </Card>
                  ) : (
                     // </div>
                     <div
                        className="text-center d-flex align-items-center justify-content-center text-bold"
                        style={{
                           height: "50vh",
                        }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </div>
            </div>
            {/* /////////////////////////////Edit model */}
            <div
               className="modal fade"
               id="staticBackdropTwo"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           {this.state.groupNames ?? "--"}
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <PerfectScrollbar style={{ maxHeight: "450px" }}>
                           {this.state.selectedGroupPosts.length > 0 ? (
                              this.state.selectedGroupPosts.map(
                                 (item, index) => (
                                    <div key={index} className=" mb-3">
                                       <li style={{ listStyle: "none" }}>
                                          <div className="d-flex">
                                             <div>
                                                {item?.user?.profile_image ? (
                                                   <img
                                                      src={
                                                         item?.user
                                                            ?.profile_image
                                                            ?.folder_path +
                                                         "thumb/" +
                                                         item?.user
                                                            ?.profile_image
                                                            ?.file_name
                                                      }
                                                      className="rounded-circle avatar-xs"
                                                      style={{
                                                         maxWidth: "40px",
                                                         maxHeight: "40px",
                                                      }}
                                                   />
                                                ) : (
                                                   <img
                                                      src={defaultUserImage}
                                                      className="rounded-circle avatar-xs"
                                                   />
                                                )}
                                             </div>

                                             <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate px-2 text-primary">
                                                   {item?.user?.first_name ??
                                                      "" +
                                                         " " +
                                                         item?.user
                                                            ?.last_name ??
                                                      "--"}
                                                </p>
                                             </div>

                                             <div className="font-size-11 text-primary">
                                                {this.getDate(item?.created_at)}
                                             </div>
                                          </div>
                                          {item?.post_type == "text" ? (
                                             <div className="mt-3">
                                                <p>{item?.post}</p>
                                             </div>
                                          ) : item?.post_type == "image" ? (
                                             <div className=" mb-3">
                                                <img
                                                   src={
                                                      item?.post_media[0]?.path
                                                   }
                                                   style={{
                                                      width: "100%",
                                                      height: "100%",
                                                   }}
                                                />
                                             </div>
                                          ) : item?.post_type == "video" ? (
                                             <div className=" mb-3">
                                                <Player
                                                   playsInline
                                                   src={
                                                      item?.post_media[0]?.path
                                                   }
                                                   width="auto"
                                                   height="200px"
                                                />
                                                {/* <ReactPlayer
                                                   width="100%"
                                                   height="200px"
                                                   // url={message?.conversation_media[0]?.path}
                                                   url={
                                                      item?.post_media[0]?.path
                                                   }
                                                   playing={false}
                                                   controls={true}
                                                   loop={false}
                                                   muted={false}
                                                   playsinline={true}
                                                   // onReady={onLoadedData}
                                                /> */}
                                             </div>
                                          ) : (
                                             ""
                                          )}
                                       </li>
                                    </div>
                                 )
                              )
                           ) : (
                              <h5>No Posts</h5>
                           )}
                        </PerfectScrollbar>
                     </div>

                     <div className="modal-footer ">
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>
                     </div>
                  </div>
               </div>
            </div>

            {/* //////////////Profile model */}
            <div
               className="modal fade"
               id="staticBackdropFiveth"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           User Profile
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <div className="image-input-wrapper  p-3">
                           <center>
                              {this.state.profilesImg ===
                              "undefinedundefined" ? (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src={profileImage}
                                    alt=""
                                 />
                              ) : (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src={this.state.profilesImg}
                                    alt=""
                                 />
                              )}
                           </center>
                        </div>

                        {/* <div className="d-flex justify-content-around text-dark"> */}
                        <div className="Detail border shadow rounded bg-light p-2">
                           <div className="row ">
                              <div className="col-3">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    First Name
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>
                                    {this.state.ownerFirstName == undefined
                                       ? "--"
                                       : this.state.ownerFirstName ?? "--"}
                                 </p>
                              </div>

                              <div className=" col-3">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Last Name
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>
                                    {this.state.ownerLastName == undefined
                                       ? "--"
                                       : this.state.ownerLastName ?? "--"}
                                 </p>
                              </div>

                              <div className="col-3">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Email
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>
                                    {this.state.ownerEmail == undefined
                                       ? "--"
                                       : this.state.ownerEmail ?? "--"}
                                 </p>
                              </div>

                              <div className="col-3">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Gender
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>
                                    {this.state.ownerGender == undefined
                                       ? "--"
                                       : this.state.ownerGender ?? "--"}
                                 </p>
                              </div>

                              <div className="col-3">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Phone
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>
                                    {this.state.ownerPhone == undefined
                                       ? "--"
                                       : this.state.ownerPhone ?? "--"}
                                 </p>
                              </div>

                              <div className="col-3">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Number of Plates
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>
                                    {this.dataLength == undefined
                                       ? "0"
                                       : this.state.dataLength ?? "--"}
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="modal-footer">
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default SocialGroups;
