import React, { Component } from "react";
import { Row, Col, Alert, Container, Label } from "reactstrap";
// availity-reactstrap-validation
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
   registerUser,
   registerUserFailed,
   apiError,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";

const charmOption = [
   {
      label: "Select Your Organization Type",
      options: [
         { label: "Commercial Profit Making Company", value: "1" },
         { label: "Professional Organizations:", value: "2" },
         { label: "Educational Institutions:", value: "3" },
         { label: "Medical", value: "4" },
         { label: "Development", value: "5" },
         { label: "Government Agency", value: "6" },
         { label: "Religious", value: "7" },
         { label: "NGO’s Lobby, Sponsor, Promote", value: "8" },
         { label: "Community Groups", value: "9" },
      ],
   },
];

const recipientOption = [
   {
      label: "Who are they receiving or sending a charm from or to?",
      options: [
         { label: "Petitions", value: "Petitions" },
         { label: "Poll Questions", value: "Poll Questions" },
         {
            label: "Solicitation For Assistance",
            value: "Solicitation For Assistance",
         },
      ],
   },
];
class CorporateRegister extends Component {
   constructor(props) {
      super(props);
      this.state = {
         email: "",
         username: "",
         password: "",
         RepeatPassword: "",
         phoneNumber: "",

         //   selectedGroup: null,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
   }

   handleSubmit(event, values) {
      this.props.registerUser(values);
   }

   componentDidMount() {
      this.props.registerUserFailed("");
      this.props.apiError("");
      document.body.classList.add("auth-body-bg");
   }

   render() {
      return (
         <React.Fragment>
            <div>
               <Container fluid className="p-0">
                  <Row className="g-0">
                     <Col lg={6}>
                        <div className="p-2 lg-p-5 d-flex align-items-center">
                           <div className="w-100">
                              <Row className="justify-content-center">
                                 <Col lg={9}>
                                    <div>
                                       {this.props.user && (
                                          <Alert color="success">
                                             Registration Done Successfully.
                                          </Alert>
                                       )}

                                       {this.props.registrationError && (
                                          <Alert color="danger">
                                             {this.props.registrationError}
                                          </Alert>
                                       )}

                                       <div className="p-2 mt-5">
                                          <AvForm
                                             onValidSubmit={this.handleSubmit}
                                             className="form-horizontal"
                                          >
                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="username">
                                                   Please Enter Name
                                                </Label>
                                                <AvField
                                                   name="username"
                                                   value={this.state.username}
                                                   type="text"
                                                   className="form-control"
                                                   id="username"
                                                   placeholder="Enter Your Name"
                                                />
                                             </div>
                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                                <Label htmlFor="useremail">
                                                   Email
                                                </Label>
                                                <AvField
                                                   name="email"
                                                   value={this.state.email}
                                                   validate={{
                                                      email: true,
                                                      required: true,
                                                   }}
                                                   type="email"
                                                   className="form-control"
                                                   id="useremail"
                                                   placeholder="Enter email"
                                                />
                                             </div>

                                             <Row>
                                                <Col md="6">
                                                   <div className="mb-4">
                                                      <Label
                                                         className="form-label"
                                                         htmlFor="validationCustom04"
                                                      >
                                                         Please Enter Physical
                                                         Address
                                                      </Label>
                                                      <AvField
                                                         name="state"
                                                         placeholder="Please Enter Physical Address"
                                                         type="text"
                                                         // errorMessage="Please provide a valid state."
                                                         className="form-control"
                                                         validate={{
                                                            required: {
                                                               value: true,
                                                            },
                                                         }}
                                                         id="validationCustom04"
                                                      />
                                                   </div>
                                                </Col>
                                                <Col md="6">
                                                   <div className="mb-4">
                                                      <Label
                                                         className="form-label"
                                                         htmlFor="validationCustom03"
                                                      >
                                                         Please Enter Permanent
                                                         Address
                                                      </Label>
                                                      <AvField
                                                         name="city"
                                                         placeholder="P.O. Box Address"
                                                         type="text"
                                                         // errorMessage=" Please provide a valid city."
                                                         className="form-control"
                                                         validate={{
                                                            required: {
                                                               value: true,
                                                            },
                                                         }}
                                                         id="validationCustom03"
                                                      />
                                                   </div>
                                                </Col>
                                             </Row>

                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="usernumber">
                                                   Mobile number
                                                </Label>
                                                <AvField
                                                   name="email8"
                                                   value={
                                                      this.state.phoneNumber
                                                   }
                                                   validate={{
                                                      phoneNumber: true,
                                                      required: true,
                                                   }}
                                                   type="email"
                                                   className="form-control"
                                                   id="useremail3"
                                                   placeholder="Enter Mobile number"
                                                />
                                             </div>

                                             <div className="mb-3">
                                                <Label className="form-label">
                                                   Please Select Type of
                                                   Organization
                                                </Label>
                                                <Select
                                                   //   value={selectedMulti}
                                                   //   onChange={this.handleMulti}
                                                   options={charmOption}
                                                   classNamePrefix="select2-selection"
                                                />
                                             </div>

                                             <div className="mb-3">
                                                <Label className="form-label">
                                                   How would you like your charm
                                                   recipients to know you as?
                                                </Label>
                                                <Select
                                                   //   value={selectedGroup}
                                                   //   onChange={this.handleSelectGroup}
                                                   options={recipientOption}
                                                   classNamePrefix="select2-selection"
                                                />
                                             </div>

                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="userpassword">
                                                   Password
                                                </Label>
                                                <AvField
                                                   name="password"
                                                   value={this.state.password}
                                                   type="password"
                                                   className="form-control"
                                                   id="userpassword"
                                                   placeholder="Enter password"
                                                />
                                             </div>

                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="userpassword">
                                                   Repeat Password
                                                </Label>
                                                <AvField
                                                   name="password"
                                                   value={
                                                      this.state.RepeatPassword
                                                   }
                                                   type="password"
                                                   className="form-control"
                                                   id="userpassword1"
                                                   placeholder="Enter Repeat Password"
                                                />
                                             </div>
                                          </AvForm>
                                       </div>
                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        </div>
                     </Col>
                     <Col lg={6}>
                        <div className="authentication-bg h-100">
                           <div className="bg-overlay"></div>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </div>
         </React.Fragment>
      );
   }
}

const mapStatetoProps = (state) => {
   const { user, registrationError, loading } = state.Account;
   return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
   registerUser,
   apiError,
   registerUserFailed,
})(CorporateRegister);
