import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Pagination from "../VerticalLayout/pagination/Pagination";

import "./Coupon.css";
import {
   Button,
   Card,
   CardBody,
   Col,
   Container,
   Modal,
   ModalBody,
   ModalFooter,
   ModalHeader,
   Row,
   Table,
} from "reactstrap";
import Swal from "sweetalert2";
import {
   createCoupon,
   deleteCoupon,
   getCoupons,
} from "../../components/Services/manage-coupon";
function ManageCouponCode() {
   const [loading, setLoading] = useState(true);
   const [allCoupon, setAllCoupon] = useState([]);

   const [name, setName] = useState("");
   const [calenderModel, setCalenderModel] = useState(false);
   const [btnLoading, setBtnLoading] = useState(false);
   const [percentageRange, setPercentageRange] = useState(null);
   const [amountOFF, setAmountOFF] = useState(null);
   const [duration, setDuration] = useState("");
   const [title, setTitle] = useState("");
   const [couponCode, setCouponCode] = useState("");
   const [minSpend, setMinSpend] = useState("");
   const [maxSpend, setMaxSpend] = useState("");
   const [usagePerCoupon, setUsagePerCoupon] = useState("");
   const [usagePerUser, setUsagePerUser] = useState("");
   const [description, setDescription] = useState("");
   const [titleError, setTitleError] = useState(false);
   const [percentageError, setPercentageError] = useState(false);
   const [amountOFFError, setAmountOFFError] = useState(false);
   const [showData, setShowData] = useState([]);
   const [typeOfListing, setTypeOfListing] = useState("Percentage discount");
   const [loader, setLoader] = useState(false);
   const [openModel, setOpenModel] = useState(false);
   const [openEditModel, setOpenEditModel] = useState(false);
   const [selectedReasonID, setSelectedReasonID] = useState();
   const [lastPage, setLastPage] = useState([]);
   const [date, setDate] = useState("");
   const [amount, setAmount] = useState("");
   const [minSpendError, setMinSpendError] = useState(false);
   const [minSpendErrorValue, setMinSpendErrorValue] = useState("");
   const [maxSpendError, setMaxSpendError] = useState(false);
   const [maxSpendErrorValue, setMaxSpendErrorValue] = useState("");
   const [couponCodeError, setCouponCodeError] = useState(false);
   const [couponCodeErrorValue, setCouponCodeErrorValue] = useState("");
   const [usagePerCouponError, setUsagePerCouponError] = useState(false);
   const [usagePerCouponValue, setUsagePerCouponValue] = useState("");
   const [usagePerUserError, setUsagePerUserError] = useState(false);
   const [usagePerUserValue, setUsagePerUserValue] = useState("");
   const [durationError, setDurationError] = useState(false);
   const [usagePerCouponErrorValue, setUsagePerCouponErrorValue] = useState("");
   const [usagePerUserErrorValue, setUsagePerUserErrorValue] = useState("");
   const [pageIDs, setPageIDs] = useState([]);
   var regExp = /^\d*(\.)?(\d{0,2})?$/;

   useEffect(() => {
      getAllCoupon(1);
   }, []);
   const getAllCoupon = async (page) => {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      let IDArray = [];
      try {
         setLoading(false);
         const result = await getCoupons(accessToken, page);

         if (result.status === true) {
            setLoading(true);
            setAllCoupon(result.coupons.data);
            setLastPage(result.coupons.last_page);
            for (let i = result.coupons.from; i <= result.coupons.to; i++) {
               IDArray.push({ id: i });
            }
            setPageIDs(IDArray);
         } else {
            setLoading(false);
         }
      } catch (err) {
         setLoading(false);
      }
   };
   const dateFormateHandler = (createdAt) => {
      let d = new Date(createdAt),
         month = "" + (d.getMonth() + 1),
         day = "" + d.getDate(),
         year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [day, month, year].join("/");
   };

   const WhichTypeOfDiscountChange = async (e) => {
      if (e.target.id == "percentage") {
         setTypeOfListing("Percentage discount");
         setAmountOFF(null);
      } else if (e.target.id == "amount") {
         setTypeOfListing("Fixed discount");
         setPercentageRange(null);
      }
   };

   //
   //
   //
   const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
         toast.addEventListener("mouseenter", Swal.stopTimer);
         toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
   });

   const addCoupon = async () => {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      try {
         if (name == "" || name == undefined) {
            setTitleError(true);
         }
         if (duration == "" || duration == undefined) {
            setDurationError(true);
         }
         if (usagePerCoupon == "" || usagePerCoupon == undefined) {
            setUsagePerCouponError(true);
            setUsagePerCouponValue("Enter coupon limit");
         }
         if (usagePerUser == "" || usagePerUser == undefined) {
            setUsagePerUserError(true);
            setUsagePerUserValue("Enter user limit");
         }
         if (minSpend == "" || minSpend == undefined) {
            setMinSpendError(true);
            setMinSpendErrorValue("Enter minimum spend ");
         }
         if (maxSpend == "" || maxSpend == undefined) {
            setMaxSpendError(true);
            setMaxSpendErrorValue("Enter maximum spend ");
         }
         if (couponCode == "" || couponCode == undefined) {
            setCouponCodeError(true);
            setCouponCodeErrorValue(" Enter coupon code");
         }
         if (typeOfListing == "Percentage discount") {
            if (
               percentageRange == null ||
               percentageRange == 0 ||
               percentageRange.length < 1
            ) {
               setPercentageError(true);
            }
         } else if (typeOfListing == "Fixed discount") {
            if (amountOFF == null || amountOFF == "") {
               setAmountOFFError(true);
            }
         }

         if (
            (name !== "" &&
               name !== undefined &&
               duration !== "" &&
               duration !== undefined &&
               minSpend !== "" &&
               minSpend !== undefined &&
               maxSpend !== "" &&
               maxSpend !== undefined &&
               duration !== "" &&
               duration !== undefined &&
               couponCode !== "" &&
               couponCode !== undefined &&
               percentageRange !== null &&
               titleError == false &&
               durationError == false &&
               usagePerCouponError == false &&
               usagePerUserError == false &&
               minSpendError == false &&
               maxSpendError == false &&
               couponCodeError == false &&
               percentageError == false) ||
            (name !== "" &&
               name !== undefined &&
               duration !== "" &&
               duration !== undefined &&
               minSpend !== "" &&
               minSpend !== undefined &&
               maxSpend !== "" &&
               maxSpend !== undefined &&
               duration !== "" &&
               duration !== undefined &&
               couponCode !== "" &&
               couponCode !== undefined &&
               amountOFF !== null &&
               amountOFF !== undefined &&
               titleError == false &&
               durationError == false &&
               usagePerCouponError == false &&
               usagePerUserError == false &&
               minSpendError == false &&
               maxSpendError == false &&
               couponCodeError == false &&
               amountOFFError == false)
         ) {
            setBtnLoading(true);
            const result = await createCoupon(
               accessToken,
               typeOfListing,
               name,
               couponCode,
               minSpend,
               maxSpend,
               usagePerCoupon,
               usagePerUser,
               description,
               duration,
               amountOFF,
               percentageRange
            );

            if (result.status === true) {
               setBtnLoading(false);
               Toast.fire({
                  icon: "success",
                  title: "Coupon added successfully",
               });
               setOpenModel(false);
               let couponsArray = [...allCoupon];
               couponsArray.unshift(result?.coupon);
               setAllCoupon(couponsArray);
               clear();
            } else {
               if (result.error) {
                  setCouponCodeError(true);
                  setCouponCodeErrorValue(result.error);
               }
               setBtnLoading(false);
            }
         }
      } catch (err) {
         setBtnLoading(false);
         Toast.fire({
            icon: "error",
            title: "Please try again",
         });
      }
   };
   //
   //
   const removeCoupon = async (code) => {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      try {
         const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#7e8299",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (result.isConfirmed) {
            const couponResult = await deleteCoupon(accessToken, code);

            if (couponResult.status === true) {
               Toast.fire({
                  icon: "success",
                  title: "Coupon remove successfully",
               });
               let couponArray = [...allCoupon];

               couponArray.map((item, index) => {
                  if (item.code === code) {
                     couponArray.splice(index, 1);
                  }
               });

               setAllCoupon(couponArray);
            }
         } else if (result.isDismissed) {
            // console.log('isDenied')
         }
      } catch (err) {
         Toast.fire({
            icon: "error",
            title: "Please try again",
         });
      }
   };
   const [style, setStyle] = useState("sideMenu");
   const [menuStatus, setMenuStatus] = useState("open");
   const myFunction = (data) => {
      setShowData(data);
      switch (menuStatus) {
         case "open":
            setMenuStatus("close");
            setStyle("sideMenu activeSideMenu");
            break;
         // case 'close':
         //   setMenuStatus('open')
         //   setStyle('sideMenu')
         //   break
      }
   };
   const clear = () => {
      setName("");

      setPercentageRange(null);
      setAmountOFF(null);
      setDuration("");
      setTitleError(false);
      setDurationError(false);
      setUsagePerCouponError(false);
      setUsagePerUserError(false);
      setMinSpendError(false);
      setMaxSpendError(false);
      setCouponCodeError(false);
      setPercentageError(false);
      setAmountOFFError(false);
      setCouponCode("");
      setMinSpend("");
      setMaxSpend("");
      setUsagePerCoupon("");
      setUsagePerUser("");
      setDescription("");
   };

   const handleChange = (e) => {
      switch (e.target.name) {
         case "title":
            setName(e.target.value);
            setTitleError(false);
            break;
         case "per_off":
            setPercentageRange(parseInt(e.target.value));
            setPercentageError(false);

            if (e.target.value > 100) {
               setPercentageRange(100);
            } else if (e.target.value < 1) {
               // setPercentageRange(0)
               setPercentageError(true);
            }
            break;
         case "amountOFF":
            setAmountOFF(e.target.value);
            setAmountOFFError(false);
            break;
         case "duration":
            setDuration(e.target.value);
            if (e.target.value !== "") {
               setDurationError(false);
            }
            break;
         case "percentage_range":
            setPercentageError(false);
            setPercentageRange(e.target.value);

            break;

         case "couponCode":
            if (e.target.value == "") {
               setCouponCodeError(true);
               setCouponCodeErrorValue("Enter minimum spend ");
            } else {
               setCouponCodeError(false);
               setCouponCode(e.target.value);
            }
            break;
         case "minSpend":
            if (e.target.value == "") {
               setMinSpendError(true);
               setMinSpendErrorValue("Enter minimum spend ");
            } else if (regExp.test(e.target.value) == true) {
               setMinSpendError(false);
               setMinSpend(e.target.value);
            } else {
               setMinSpendError(true);
               setMinSpendErrorValue("Enter valid number");
            }

            break;

         case "maxSpend":
            if (e.target.value == "") {
               setMaxSpendError(true);
               setMaxSpendErrorValue("Enter maximum  spend ");
            } else if (regExp.test(e.target.value) == true) {
               setMaxSpendError(false);
               setMaxSpend(e.target.value);
            } else {
               setMaxSpendError(true);
               setMaxSpendErrorValue("Enter valid number");
            }
            break;
         case "usagePerCoupon":
            if (e.target.value == "") {
               setUsagePerCouponError(true);
               setUsagePerCouponValue("Enter maximum  spend");
            } else if (regExp.test(e.target.value) == true) {
               setUsagePerCouponError(false);
               setUsagePerCoupon(e.target.value);
            } else {
               setUsagePerCouponError(true);
               setUsagePerCouponValue("Enter valid number");
            }

            break;
         case "usagePerUser":
            if (e.target.value == "") {
               setUsagePerUserError(true);
               setUsagePerUserValue("Enter maximum  spend");
            } else if (regExp.test(e.target.value) == true) {
               setUsagePerUserError(false);
               setUsagePerUser(e.target.value);
            } else {
               setUsagePerUserError(true);
               setUsagePerUserValue("Enter valid number");
            }

            break;
         case "description":
            setDescription(e.target.value);
            break;
         default:
            break;
      }
   };
   const onKeyPressAdd = (e) => {
      if (e.key === "Enter") {
         getAllCoupon();
      }
   };
   const handleClick = () => {
      if (menuStatus == "close") {
         // setStyle('sideMenu activeSideMenu')
         setStyle("sideMenu");
         setMenuStatus("open");
      }
   };
   const onChangeDate = async (e) => {
      setDate(e);
      setDurationError(false);
      setDuration(e.toLocaleDateString("zh-Hans-CN"));
   };
   const paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await getAllCoupon(page);
   };
   return (
      <>
         <div className="page-content" onClick={() => handleClick()}>
            <h5 className="fs-5 mb-3 fw-bold">Manage Coupon Code </h5>
            <Card className="p-4 ">
               {loading == true && (
                  <div className="d-flex justify-content-between">
                     <div></div>
                     <div>
                        <Button
                           data-bs-toggle="modal"
                           data-bs-target="#staticBackdropOne"
                           type="button"
                           style={{
                              background: "linear-gradient(#3E94A6, #323F4B)",
                           }}
                           className="btn-rounded mb-2 me-2"
                           onClick={() => {
                              setOpenModel(true);
                              //   setLoading(false);
                              clear();
                           }}
                        >
                           <span>Add Coupon</span>
                        </Button>
                     </div>
                  </div>
               )}
               <Container className="px-0 overflow-auto">
                  {loading ? (
                     <>
                        <Table className="table table-responsive">
                           <thead>
                              <tr>
                                 <th scope="col">No.</th>
                                 <th scope="col">Title</th>
                                 <th scope="col">Code</th>
                                 <th scope="col">Price</th>
                                 <th scope="col">Date</th>
                                 <th scope="col">Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              {allCoupon?.length > 0 ? (
                                 <>
                                    {" "}
                                    {allCoupon?.map((item, outer) => {
                                       return (
                                          <>
                                             <tr key={outer}>
                                                {pageIDs?.map((item, inner) =>
                                                   outer == inner ? (
                                                      <td>{item.id}</td>
                                                   ) : null
                                                )}
                                                <td>{item?.title} </td>
                                                <td>{item?.code} </td>
                                                <td>
                                                   {item?.amount}
                                                   {item?.type ==
                                                   "Percentage discount"
                                                      ? "%"
                                                      : null}{" "}
                                                </td>

                                                <td>
                                                   {dateFormateHandler(
                                                      item?.expiry_date
                                                   ) ?? "No Date"}
                                                </td>
                                                <td>
                                                   <div className="d-flex">
                                                      <Link
                                                         to="#"
                                                         className="text-primary"
                                                      >
                                                         <i
                                                            className="mdi mdi-eye font-size-18 me-3"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdropOne"
                                                            onClick={() =>
                                                               myFunction(item)
                                                            }
                                                            title="View"
                                                         ></i>
                                                      </Link>
                                                      <Link
                                                         to="#"
                                                         className="text-danger"
                                                      >
                                                         <i
                                                            title="Delete"
                                                            className="mdi mdi-trash-can font-size-18 me-3"
                                                            onClick={() =>
                                                               removeCoupon(
                                                                  item.code
                                                               )
                                                            }
                                                         ></i>
                                                      </Link>
                                                   </div>
                                                </td>
                                             </tr>
                                          </>
                                       );
                                    })}
                                 </>
                              ) : (
                                 <></>
                              )}
                           </tbody>
                        </Table>
                        {allCoupon?.length < 1 && (
                           <p className="text-center pt-5 fs-4 fw-bold ">
                              Record Not Found.
                           </p>
                        )}
                     </>
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center "
                        style={{ height: "50vh" }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
                  {lastPage > 1 && (
                     <Pagination pageCount={lastPage} paginate={paginate} />
                  )}
               </Container>
            </Card>
         </div>

         {/* //////////////////////Side Model start/////////////// */}
         <div>
            {/* {modelControl ? ( */}
            <div
               className={`${style} shadow-sm p-5 pb-20`}
               style={{ height: "100vh", overflow: "scroll", zIndex: "999" }}
            >
               <div className="d-flex justify-content-between align-items-center ">
                  <div></div>
                  <button
                     type="button"
                     class="btn-close"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     onClick={() => {
                        setMenuStatus("open");
                        setStyle("sideMenu");
                     }}
                  ></button>
               </div>

               <div className="row mt-4  border-1 border-bottom">
                  <div className="col-5">
                     <h6 className="modal-title">Title</h6>
                  </div>
                  <div className="col-7">
                     <p className="mb-1 text-start">
                        {" "}
                        {showData?.title ?? "--"}
                     </p>
                  </div>
               </div>

               <div className="row mt-4  border-1 border-bottom">
                  <div className="col-5">
                     <h6 className="modal-title">Description</h6>
                  </div>
                  <div className="col-7">
                     <p className="mb-1 text-start">
                        {" "}
                        {showData?.description ?? "--"}
                     </p>
                  </div>
               </div>
               <div className="row mt-4  border-1 border-bottom">
                  <div className="col-5">
                     <h6 className="modal-title">Minimum Spend</h6>
                  </div>
                  <div className="col-7">
                     <p className="mb-1 text-start">
                        {" "}
                        {showData?.min_spend ?? "--"}
                     </p>
                  </div>
               </div>
               <div className="row mt-4  border-1 border-bottom">
                  <div className="col-5">
                     <h6 className="modal-title">Maximum Spend</h6>
                  </div>
                  <div className="col-7">
                     <p className="mb-1 text-start">
                        {" "}
                        {showData?.max_spend ?? "--"}
                     </p>
                  </div>
               </div>
               <div className="row mt-4  border-1 border-bottom">
                  <div className="col-5">
                     <h6 className="modal-title">Limit Per User</h6>
                  </div>
                  <div className="col-7">
                     <p className="mb-1 text-start">
                        {" "}
                        {showData?.limit_user ?? "--"}
                     </p>
                  </div>
               </div>
               <div className="row mt-4  border-1 border-bottom">
                  <div className="col-5">
                     <h6 className="modal-title">Limit Per Coupon</h6>
                  </div>
                  <div className="col-7">
                     <p className="mb-1 text-start">
                        {" "}
                        {showData?.limit_coupon ?? "--"}
                     </p>
                  </div>
               </div>
            </div>
         </div>

         <Modal size="lg" isOpen={openModel} centered={true} toggle={null}>
            <ModalHeader toggle={() => setOpenModel(!openModel)}>
               <h5 className="modal-title">Add Coupon</h5>
            </ModalHeader>

            <ModalBody>
               <div className="d-flex justify-content-center mb-3">
                  <div className="form-check">
                     <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="percentage"
                        onClick={(e) => WhichTypeOfDiscountChange(e)}
                        checked={typeOfListing == "Percentage discount"}
                     />
                     <label
                        className="form-check-label me-3"
                        htmlFor="percentage"
                     >
                        Percentage OFF
                     </label>
                  </div>

                  <div className="form-check">
                     <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="amount"
                        onClick={(e) => WhichTypeOfDiscountChange(e)}
                        checked={typeOfListing == "Fixed discount"}
                     />
                     <label className="form-check-label" htmlFor="amount">
                        Amount OFF
                     </label>
                  </div>
               </div>
               <div className="row ">
                  <div className="mb-3 col-md-6 ">
                     <label
                        htmlFor="exampleFormControlInput1"
                        className="required form-label"
                     >
                        Title
                     </label>
                     <input
                        type="text"
                        name="title"
                        className="form-control form-control-solid "
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => onKeyPressAdd(e)}
                        placeholder="Enter title"
                        required
                     />
                     {titleError && (
                        <div className="modelValidation ">Enter title </div>
                     )}
                  </div>
                  <div className="mb-3 col-md-6">
                     <label
                        htmlFor="exampleFormControlInput1"
                        className="required form-label"
                     >
                        Coupon Code
                     </label>
                     <input
                        type="text"
                        name="couponCode"
                        className="form-control form-control-solid "
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => onKeyPressAdd(e)}
                        placeholder="Enter coupon code"
                        required
                     />
                     {couponCodeError && (
                        <div className="modelValidation ">
                           {" "}
                           {couponCodeErrorValue}{" "}
                        </div>
                     )}
                  </div>
                  <div className="mb-3 col-md-6">
                     <label
                        htmlFor="exampleFormControlInput1"
                        className="required form-label"
                     >
                        Minimum Spend
                     </label>
                     <input
                        type="text"
                        name="minSpend"
                        className="form-control form-control-solid "
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => onKeyPressAdd(e)}
                        placeholder="Enter minimum spend"
                        required
                     />
                     {minSpendError && (
                        <div className="modelValidation ">
                           {" "}
                           {minSpendErrorValue}{" "}
                        </div>
                     )}
                  </div>
                  <div className="mb-3 col-md-6">
                     <label
                        htmlFor="exampleFormControlInput1"
                        className="required form-label"
                     >
                        Maximum Spend
                     </label>
                     <input
                        type="text"
                        name="maxSpend"
                        className="form-control form-control-solid "
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => onKeyPressAdd(e)}
                        placeholder="Enter maximum spend"
                        required
                     />
                     {maxSpendError && (
                        <div className="modelValidation ">
                           {" "}
                           {maxSpendErrorValue}{" "}
                        </div>
                     )}
                  </div>
                  <div className="mb-3 col-md-6">
                     <label
                        htmlFor="exampleFormControlInput1"
                        className="required form-label"
                     >
                        Usage Limit Per Coupon
                     </label>
                     <input
                        type="text"
                        name="usagePerCoupon"
                        className="form-control form-control-solid "
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => onKeyPressAdd(e)}
                        placeholder="Enter usage"
                        required
                     />
                     {usagePerCouponError && (
                        <div className="modelValidation ">
                           {" "}
                           {usagePerCouponValue}{" "}
                        </div>
                     )}
                  </div>
                  <div className="mb-3 col-md-6">
                     <label
                        htmlFor="exampleFormControlInput1"
                        className="required form-label"
                     >
                        Usage Limit Per User
                     </label>
                     <input
                        type="text"
                        name="usagePerUser"
                        className="form-control form-control-solid "
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => onKeyPressAdd(e)}
                        placeholder="Enter usage"
                        required
                     />
                     {usagePerUserError && (
                        <div className="modelValidation ">
                           {" "}
                           {usagePerUserValue}{" "}
                        </div>
                     )}
                  </div>
                  <div className="mb-3 col-md-12">
                     <label
                        htmlFor="exampleFormControlInput1"
                        className=" form-label"
                     >
                        Description
                     </label>
                     <textarea
                        type="text"
                        name="description"
                        className="form-control form-control-solid "
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => onKeyPressAdd(e)}
                        placeholder="Enter description"
                        required
                     />
                  </div>
                  <div className=" mb-3 col-md-6">
                     <label
                        htmlFor="exampleFormControlInput1"
                        className="required form-label"
                     >
                        Duration
                     </label>

                     <input
                        type="text"
                        className="form-control form-control-solid cursor-pointer"
                        placeholder="Enter duration"
                        name="duration"
                        onClick={() => setCalenderModel(true)}
                        value={duration}
                        // disabled={true}

                        // onChange={(e) => onInputChange(e)}
                        style={{ cursor: "pointer" }}
                     />
                     {durationError && (
                        <div className="modelValidation ">Enter duration</div>
                     )}
                  </div>
                  {typeOfListing === "Percentage discount" ? (
                     <>
                        <div className="mb-3 col-md-6 ">
                           <label
                              htmlFor="exampleFormControlInput1"
                              className="required form-label"
                           >
                              Percentage OFF %
                           </label>
                           <input
                              type="number"
                              name="per_off"
                              className="form-control form-control-solid "
                              onChange={(e) => handleChange(e)}
                              onKeyPress={(e) => onKeyPressAdd(e)}
                              placeholder="Enter percentage"
                              value={percentageRange}
                              required
                           />
                           {percentageError && (
                              <div className="modelValidation ">
                                 Enter percentage
                              </div>
                           )}

                           <div className="col-12 mt-2 ">
                              <input
                                 className=""
                                 type="range"
                                 name="percentage_range"
                                 value={percentageRange}
                                 style={{ width: "100%" }}
                                 id="range_weight"
                                 min={0}
                                 max={100}
                                 onChange={(e) => handleChange(e)}
                                 oninput="range_weight_disp.value = range_weight.value"
                              />
                           </div>
                        </div>
                     </>
                  ) : typeOfListing === "Fixed discount" ? (
                     <div className="mb-3 col-md-6 ">
                        <label
                           htmlFor="exampleFormControlInput1"
                           className="required form-label"
                        >
                           Amount OFF
                        </label>
                        <input
                           type="number"
                           name="amountOFF"
                           className="form-control form-control-solid "
                           onChange={(e) => handleChange(e)}
                           onKeyPress={(e) => onKeyPressAdd(e)}
                           placeholder="Enter amount"
                           required
                        />
                        {amountOFFError && (
                           <div className="modelValidation ">Enter amount</div>
                        )}
                     </div>
                  ) : null}
               </div>
            </ModalBody>

            <ModalFooter className="text-center py-5">
               <button
                  type="reset"
                  className="btn btn-secondary me-3"
                  onClick={() => {
                     setOpenModel(!openModel);
                     // setThrowEmailError(false)
                  }}
               >
                  Discard
               </button>

               <button
                  type="submit"
                  className="btn "
                  onClick={addCoupon}
                  style={{
                     background: "linear-gradient(#3E94A6, #323F4B)",
                     color: "white",
                  }}
               >
                  {!btnLoading && <span className="indicator-label">Save</span>}
                  {btnLoading && (
                     <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                     >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                     </span>
                  )}
               </button>
            </ModalFooter>
         </Modal>
         {/* 
         
         calculator
         
         */}
         <Modal size="md" isOpen={calenderModel} centered={true} toggle={null}>
            <ModalHeader toggle={() => setCalenderModel(!calenderModel)}>
               Select Date
            </ModalHeader>
            <ModalBody className="d-flex justify-content-center">
               <Calendar
                  onChange={onChangeDate}
                  minDate={new Date()}
                  value={date}
               />
            </ModalBody>
            <ModalFooter>
               <div className="text-end">
                  <Button
                     color="secondary"
                     onClick={() => setCalenderModel(!calenderModel)}
                  >
                     Cancel
                  </Button>
               </div>
               <div className="text-end">
                  <Button
                     type="button"
                     color="white"
                     onClick={() => setCalenderModel(!calenderModel)}
                     style={{
                        background: "linear-gradient(#3E94A6, #323F4B)",
                        color: "white",
                     }}
                  >
                     OK
                  </Button>
               </div>
            </ModalFooter>
         </Modal>
      </>
   );
}

export default ManageCouponCode;
