import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import "./style.css";
import "./parking.css";
import buss from "../../../assets/vehicleProfileImg/buss.svg";
import truckImg from "../../../assets/vehicleProfileImg/truck.svg";
import carImg from "../../../assets/vehicleProfileImg/car.svg";
import instructions from "../../../assets/PakingImges/instructions.svg";
import terms from "../../../assets/PakingImges/terms.svg";
import availability from "../../../assets/PakingImges/availability.svg";
import unCheckIcon from "../../../assets/PakingImges/unheckIcon.svg";
import checkIcon from "../../../assets/PakingImges/checkIcon.svg";
import RatingSection from "./RatingSection";
import moment from "moment";

import { getParkingProfileData } from "../../Services/VehicleServicesManageParking";
export default class ManageParkingProfileData extends Component {
   constructor(props) {
      super(props);

      this.getParkingProfile = this.getParkingProfile.bind(this);
      this.getTime = this.getTime.bind(this);
      this.state = {
         parking: "",
         loader: false,
      };
   }
   async componentDidMount() {
      this.getParkingProfile();
   }
   async getParkingProfile(page) {
      try {
         const { id } = this.props.match.params;
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const result = await getParkingProfileData(accessToken, id);
         if (result.status == true) {
            this.setState({ parking: result.parking, loader: true });
         }
         console.log(result);
      } catch (err) {
         console.log("error", err);
      }
   }
   async getTime(timeString) {
      if (
         timeString !== null &&
         timeString !== undefined &&
         timeString !== ""
      ) {
         return moment(timeString, ["h:mm A"]).format("HH:mm");
      } else {
         return "";
      }
   }

   render() {
      return (
         <>
            <div className="page-content ">
               <Container>
                  {this.state.loader ? (
                     <>
                        <div>
                           <Link to="/parkings">
                              <button
                                 className="mb-3"
                                 style={{
                                    background: "none",
                                    border: "none",
                                    color: "#3E94A6",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                 }}
                              >
                                 <i className="mdi mdi-chevron-left md-48"></i>
                                 <b>Back</b>
                              </button>
                           </Link>
                        </div>
                        <div className="main_slider_section p-3">
                           {/*  */}
                           {/*  */}

                           <div className="col-12  text-end">
                              <div
                                 className="accordion accordion-flush"
                                 id="accordionFlushExample"
                              >
                                 <div className="accordion-item border-bottom">
                                    <h6
                                       className="accordion-header"
                                       id="flush-headingOne"
                                    >
                                       <button
                                          className="accordion-button cara-btn-landing collapsed  fw-normal "
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#flush-collapseOne"
                                          aria-expanded="false"
                                          aria-controls="flush-collapseOne"
                                       >
                                          <h6
                                             className="wemblay-business-detail-heading mb-0 text-wrap"
                                             style={{
                                                color: "#3E94A6",
                                                fontWeight: "600",
                                                fontSize: "17px",
                                             }}
                                          >
                                             <i
                                                className="mdi mdi-format-list-bulleted-type me-3"
                                                style={{
                                                   color: "#3E94A6",
                                                   fontSize: "22px",
                                                }}
                                             ></i>
                                             {this.state.parking?.name}
                                          </h6>
                                       </button>
                                    </h6>
                                    <div
                                       id="flush-collapseOne"
                                       className="accordion-collapse collapse "
                                       aria-labelledby="flush-headingOne"
                                       data-bs-parent="#accordionFlushExample"
                                    >
                                       <div className="accordion-body text-start cara-heading">
                                          <div
                                             className="row border shadow rounded bg-white p-4 text-start mx-0 mb-3 position-relative"
                                             style={{ height: "34rem" }}
                                          >
                                             <div className=" px-0 mb-3 ">
                                                <div class="mx-0 wemblay-business-detail-container my-1">
                                                   <h3 className="wemblay-inner-bottom-heading my-2">
                                                      {this.state?.parking
                                                         ?.location
                                                         ?.formatted_address !==
                                                         "" &&
                                                      this.state?.parking
                                                         ?.location
                                                         ?.formatted_address !==
                                                         null ? (
                                                         <i className="mdi mdi-map-marker-multiple me-1 "></i>
                                                      ) : null}

                                                      {this.state?.parking
                                                         ?.location
                                                         ?.formatted_address ??
                                                         ""}
                                                   </h3>
                                                </div>
                                                <div
                                                   id="carouselExampleCaptions"
                                                   className="carousel slide "
                                                   data-bs-ride="carousel"
                                                >
                                                   <div className="carousel-indicators ">
                                                      <ol class="carousel-indicators wemblay-carousel-indicators">
                                                         {this.state.parking
                                                            ?.parking_image
                                                            ?.length > 0 &&
                                                         this.state.parking
                                                            ?.parking_image
                                                            ?.length < 15
                                                            ? this.state.parking?.parking_image?.map(
                                                                 (
                                                                    item,
                                                                    ind
                                                                 ) => (
                                                                    <li
                                                                       data-bs-target="#carouselExampleCaptions"
                                                                       data-bs-slide-to={
                                                                          ind
                                                                       }
                                                                       className={`${
                                                                          ind ==
                                                                          0
                                                                             ? "active"
                                                                             : ""
                                                                       } wemblay-carousel-indicators-btn`}
                                                                       aria-current={`${
                                                                          ind ==
                                                                          0
                                                                             ? "true"
                                                                             : "false"
                                                                       }`}
                                                                    ></li>
                                                                 )
                                                              )
                                                            : null}
                                                      </ol>
                                                   </div>
                                                   <div
                                                      className="carousel-inner"
                                                      style={{
                                                         height: "25rem",
                                                      }}
                                                   >
                                                      {this.state.parking
                                                         ?.parking_image
                                                         ?.length > 0 ? (
                                                         this.state.parking?.parking_image?.map(
                                                            (item, ind) => (
                                                               <div
                                                                  className={`carousel-item ${
                                                                     ind == 0
                                                                        ? "active"
                                                                        : ""
                                                                  } `}
                                                               >
                                                                  <img
                                                                     src={
                                                                        item?.folder_path +
                                                                        item?.file_name
                                                                     }
                                                                     className="d-block img-fluid m-auto"
                                                                     alt="..."
                                                                     style={{
                                                                        maxHeight:
                                                                           "25rem",
                                                                     }}
                                                                  />
                                                               </div>
                                                            )
                                                         )
                                                      ) : (
                                                         <>
                                                            <div className="carousel-item active">
                                                               <img
                                                                  src={
                                                                     "https://images.unsplash.com/photo-1459603677915-a62079ffd002?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y2FyJTIwaW1nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                                                                  }
                                                                  className="d-block w-100 wemblay-detail-carousel-img"
                                                                  alt="..."
                                                               />
                                                            </div>
                                                         </>
                                                      )}
                                                   </div>
                                                   {this.state.parking
                                                      ?.parking_image?.length >
                                                   1 ? (
                                                      <>
                                                         <button
                                                            className="carousel-control-prev wemblay-carousel-btn-images my-auto"
                                                            type="button"
                                                            data-bs-target="#carouselExampleCaptions"
                                                            data-bs-slide="prev"
                                                         >
                                                            <span
                                                               className="carousel-control-prev-icon  detail-page-carousel-btn wemblay-detail-carousel-left-btn"
                                                               aria-hidden="true"
                                                            />
                                                            <span className="visually-hidden">
                                                               Previous
                                                            </span>
                                                         </button>
                                                         <button
                                                            className="carousel-control-next wemblay-carousel-btn-images my-auto"
                                                            type="button"
                                                            data-bs-target="#carouselExampleCaptions"
                                                            data-bs-slide="next"
                                                         >
                                                            <span
                                                               className="carousel-control-next-icon  detail-page-carousel-btn"
                                                               aria-hidden="true"
                                                            />
                                                            <span className="visually-hidden">
                                                               Next
                                                            </span>
                                                         </button>
                                                      </>
                                                   ) : null}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 {/* <div className="accordion-item border-bottom">
                                    <h6
                                       className="accordion-header"
                                       id="flush-headingTwo"
                                    >
                                       <button
                                          className="accordion-button cara-btn-landing collapsed  fw-normal"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#flush-collapseTwo"
                                          aria-expanded="false"
                                          aria-controls="flush-collapseTwo"
                                       >
                                          <h6
                                             className="wemblay-business-detail-heading mb-0 text-wrap"
                                             style={{
                                                color: "#3E94A6",
                                                fontWeight: "600",
                                                fontSize: "17px",
                                             }}
                                          >
                                             <i
                                                className="mdi mdi-map-marker-radius me-3 "
                                                style={{
                                                   color: "#3E94A6",
                                                   fontSize: "22px",
                                                }}
                                             ></i>
                                             Spots Details
                                          </h6>
                                       </button>
                                    </h6>
                                    <div
                                       id="flush-collapseTwo"
                                       className="accordion-collapse collapse"
                                       aria-labelledby="flush-headingTwo"
                                       data-bs-parent="#accordionFlushExample"
                                    >
                                       <div className="accordion-body text-start cara-heading">
                                          <div className="row mt-1 wemblay-detail-container border shadow rounded bg-white p-4 text-start    ">
                                             <div className="col-md-12  mb-1  justify-content-between "></div>
                                             <div className="row">
                                                <div className="col-md-4  d-flex justify-content-around border-end border-secondary mt-2 mt-md-0 ">
                                                   <h5 className="my-auto">
                                                      Rented Spots
                                                   </h5>
                                                   <h3 className="wemblay-detail-price  pe-md-8  me-2">
                                                      67
                                                   </h3>
                                                </div>
                                                <div className="col-md-4  d-flex justify-content-around border-end border-secondary  mt-2 mt-md-0 ">
                                                   <h5 className="my-auto">
                                                      Reserved Spots{" "}
                                                   </h5>
                                                   <h3 className="wemblay-detail-price  pe-md-8  me-2">
                                                      16
                                                   </h3>
                                                </div>
                                                <div className="col-md-4  d-flex justify-content-around border-end border-secondary mt-2 mt-md-0 ">
                                                   <h5 className="my-auto">
                                                      Pending Spots
                                                   </h5>
                                                   <h3 className="wemblay-detail-price  pe-md-8  me-2">
                                                      14
                                                   </h3>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div> */}
                                 {this.state.parking?.parking_features?.length >
                                 0 ? (
                                    <div className="accordion-item border-bottom">
                                       <h6
                                          className="accordion-header"
                                          id="flush-headingThree"
                                       >
                                          <button
                                             className="accordion-button cara-btn-landing collapsed  fw-normal"
                                             type="button"
                                             data-bs-toggle="collapse"
                                             data-bs-target="#flush-collapseThree"
                                             aria-expanded="false"
                                             aria-controls="flush-collapseThree"
                                          >
                                             <h6
                                                className="wemblay-business-detail-heading mb-0 text-wrap"
                                                style={{
                                                   color: "#3E94A6",
                                                   fontWeight: "600",
                                                   fontSize: "17px",
                                                }}
                                             >
                                                <i
                                                   className="mdi mdi-multiplication-box me-3 tex"
                                                   style={{
                                                      color: "#3E94A6",
                                                      fontSize: "22px",
                                                   }}
                                                ></i>
                                                Features
                                             </h6>
                                          </button>
                                       </h6>
                                       <div
                                          id="flush-collapseThree"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="flush-headingThree"
                                          data-bs-parent="#accordionFlushExample"
                                       >
                                          <div className="accordion-body text-start cara-heading">
                                             <div className="row mt-3 wemblay-detail-container  border shadow rounded bg-white p-4 text-start  mb-3">
                                                <div className="col-md-12  mb-0">
                                                   {/* <h3 className="my-auto">
                              <i
                                 className="mdi mdi-multiplication-box me-1 tex"
                                 style={{
                                    color: "#3E94A6",
                                 }}
                              ></i>
                              Features
                           </h3> */}
                                                   <div className="row d-flex mt-1 px-1">
                                                      {this.state.parking?.parking_features?.map(
                                                         (item, index) => (
                                                            <>
                                                               <div className="col-3   mt-3">
                                                                  <div className=" px-2 py-3 mx-1 border rounded-2 shadow bg-white">
                                                                     <h5
                                                                        className="mx-auto pb-2"
                                                                        style={{
                                                                           color: "#3E94A6",
                                                                        }}
                                                                     >
                                                                        {item
                                                                           ?.feature_image
                                                                           ?.folder_path !==
                                                                           "" &&
                                                                        item
                                                                           ?.feature_image
                                                                           ?.folder_path !==
                                                                           null &&
                                                                        item
                                                                           ?.feature_image
                                                                           ?.folder_path !==
                                                                           undefined ? (
                                                                           <img
                                                                              src={
                                                                                 item
                                                                                    ?.feature_image
                                                                                    ?.folder_path +
                                                                                 item
                                                                                    ?.feature_image
                                                                                    ?.file_name
                                                                              }
                                                                              alt=""
                                                                              className="mx-auto d-block"
                                                                              width={
                                                                                 "90%"
                                                                              }
                                                                              style={{
                                                                                 maxHeight:
                                                                                    "80px",
                                                                              }}
                                                                           />
                                                                        ) : (
                                                                           <img
                                                                              src={
                                                                                 "https://images.unsplash.com/photo-1459603677915-a62079ffd002?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y2FyJTIwaW1nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                                                                              }
                                                                              alt=""
                                                                              className="mx-auto d-block"
                                                                              width={
                                                                                 "100%"
                                                                              }
                                                                              style={{
                                                                                 maxHeight:
                                                                                    "100px",
                                                                              }}
                                                                           />
                                                                        )}
                                                                     </h5>
                                                                     <p
                                                                        className="mx-auto text-center"
                                                                        style={{
                                                                           color: "#3E94A6",
                                                                           fontWeight:
                                                                              "bolder",
                                                                           fontSize:
                                                                              "15px",
                                                                        }}
                                                                     >
                                                                        {` ${item?.name}`}
                                                                     </p>
                                                                     {/* <ul>
                                                                  <li>
                                                                     A
                                                                     Class
                                                                  </li>
                                                                  <li>
                                                                     B
                                                                     Class
                                                                  </li>
                                                               </ul> */}
                                                                  </div>
                                                               </div>
                                                            </>
                                                         )
                                                      )}
                                                   </div>
                                                </div>

                                                {/* <hr /> */}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 ) : null}

                                 {this.state.parking?.categories !== null &&
                                    this.state.parking?.categories !== "null" &&
                                    this.state.parking?.categories !==
                                       undefined &&
                                    this.state.parking?.categories?.length >
                                       0 && (
                                       <div className="accordion-item border-bottom">
                                          <h6
                                             className="accordion-header"
                                             id="flush-headingFour"
                                          >
                                             <button
                                                className="accordion-button cara-btn-landing collapsed  fw-normal"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseFour"
                                             >
                                                <h6
                                                   className="wemblay-business-detail-heading mb-0 text-wrap"
                                                   style={{
                                                      color: "#3E94A6",
                                                      fontWeight: "600",
                                                      fontSize: "17px",
                                                   }}
                                                >
                                                   <i
                                                      className="mdi mdi-car-connected  me-3"
                                                      style={{
                                                         color: "#3E94A6",
                                                         fontSize: "22px",
                                                      }}
                                                   ></i>
                                                   Type Of Parking
                                                </h6>
                                             </button>
                                          </h6>
                                          <div
                                             id="flush-collapseFour"
                                             className="accordion-collapse collapse "
                                             aria-labelledby="flush-headingFour"
                                             data-bs-parent="#accordionFlushExample"
                                          >
                                             <div className="accordion-body text-start cara-heading">
                                                <div className="sliderScreen  shadow  bg-white p-4 text-start">
                                                   <hr />
                                                   <div className="container">
                                                      <div className="row   bg-white gx-3 ">
                                                         {this.state.parking?.categories?.map(
                                                            (item, index) => (
                                                               <>
                                                                  <div className="col-3   mt-3">
                                                                     <div className=" px-2 py-3 mx-1 border rounded-2 shadow bg-white">
                                                                        <h5
                                                                           className="mx-auto pb-2"
                                                                           style={{
                                                                              color: "#3E94A6",
                                                                           }}
                                                                        >
                                                                           {item
                                                                              ?.category_image
                                                                              ?.folder_path !==
                                                                              "" &&
                                                                           item
                                                                              ?.category_image
                                                                              ?.folder_path !==
                                                                              null &&
                                                                           item
                                                                              ?.category_image
                                                                              ?.folder_path !==
                                                                              undefined ? (
                                                                              <img
                                                                                 src={
                                                                                    item
                                                                                       ?.category_image
                                                                                       ?.folder_path +
                                                                                    item
                                                                                       ?.category_image
                                                                                       ?.file_name
                                                                                 }
                                                                                 alt=""
                                                                                 className="mx-auto d-block"
                                                                                 width={
                                                                                    "90%"
                                                                                 }
                                                                                 style={{
                                                                                    maxHeight:
                                                                                       "80px",
                                                                                 }}
                                                                              />
                                                                           ) : (
                                                                              <img
                                                                                 src={
                                                                                    "https://images.unsplash.com/photo-1459603677915-a62079ffd002?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y2FyJTIwaW1nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                                                                                 }
                                                                                 alt=""
                                                                                 className="mx-auto d-block"
                                                                                 width={
                                                                                    "100%"
                                                                                 }
                                                                                 style={{
                                                                                    maxHeight:
                                                                                       "100px",
                                                                                 }}
                                                                              />
                                                                           )}
                                                                        </h5>
                                                                        <p
                                                                           className="mx-auto text-center"
                                                                           style={{
                                                                              color: "#3E94A6",
                                                                              fontWeight:
                                                                                 "bolder",
                                                                              fontSize:
                                                                                 "15px",
                                                                           }}
                                                                        >
                                                                           {`${item?.name}`}
                                                                        </p>
                                                                        {/* <ul>
                                                                           <li>
                                                                              A
                                                                              Class
                                                                           </li>
                                                                           <li>
                                                                              B
                                                                              Class
                                                                           </li>
                                                                        </ul> */}
                                                                     </div>
                                                                  </div>
                                                               </>
                                                            )
                                                         )}
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    )}
                                 {this.state.parking?.description !== "" &&
                                 this.state.parking?.description !== "null" &&
                                 this.state.parking?.description !== null &&
                                 this.state.parking?.description !==
                                    undefined ? (
                                    <div className="accordion-item border-bottom">
                                       <h6
                                          className="accordion-header"
                                          id="flush-headingFive"
                                       >
                                          <button
                                             className="accordion-button cara-btn-landing collapsed  fw-normal"
                                             type="button"
                                             data-bs-toggle="collapse"
                                             data-bs-target="#flush-collapseFive"
                                             aria-expanded="false"
                                             aria-controls="flush-collapseFive"
                                          >
                                             <h6
                                                className="wemblay-business-detail-heading mb-0 text-wrap"
                                                style={{
                                                   color: "#3E94A6",
                                                   fontWeight: "600",
                                                   fontSize: "17px",
                                                }}
                                             >
                                                <i
                                                   className="mdi mdi-clipboard-text me-3"
                                                   style={{
                                                      color: "#3E94A6",
                                                      fontSize: "22px",
                                                   }}
                                                ></i>
                                                Description
                                             </h6>
                                          </button>
                                       </h6>
                                       <div
                                          id="flush-collapseFive"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="flush-headingFive"
                                          data-bs-parent="#accordionFlushExample"
                                       >
                                          <div className="accordion-body text-start cara-heading">
                                             <div className="col-12 mt-1">
                                                <div className=" sliderScreen border shadow rounded bg-white p-4 text-start ">
                                                   <p className="mb-0">
                                                      {this.state.parking
                                                         ?.description ?? ""}
                                                   </p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 ) : null}

                                 {this.state.parking?.access_instruction !==
                                    "" &&
                                 this.state.parking?.access_instruction !==
                                    "null" &&
                                 this.state.parking?.access_instruction !==
                                    null &&
                                 this.state.parking?.access_instruction !==
                                    undefined ? (
                                    <div className="accordion-item border-bottom">
                                       <h6
                                          className="accordion-header"
                                          id="flush-headingSix"
                                       >
                                          <button
                                             className="accordion-button cara-btn-landing collapsed  fw-normal"
                                             type="button"
                                             data-bs-toggle="collapse"
                                             data-bs-target="#flush-collapseSix"
                                             aria-expanded="false"
                                             aria-controls="flush-collapseSix"
                                          >
                                             <h6
                                                className="wemblay-business-detail-heading mb-0 text-wrap"
                                                style={{
                                                   color: "#3E94A6",
                                                   fontWeight: "600",
                                                   fontSize: "17px",
                                                }}
                                             >
                                                <img
                                                   src={instructions}
                                                   alt=""
                                                   className="me-3 ps-1"
                                                   width={21}
                                                />
                                                Access Instruction
                                             </h6>
                                          </button>
                                       </h6>
                                       <div
                                          id="flush-collapseSix"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="flush-headingSix"
                                          data-bs-parent="#accordionFlushExample"
                                       >
                                          <div className="accordion-body text-start cara-heading">
                                             <div className="col-12 mt-1">
                                                <div className=" sliderScreen border shadow rounded bg-white p-4 text-start ">
                                                   <p className="mb-0">
                                                      {this.state.parking
                                                         ?.access_instruction ??
                                                         ""}
                                                   </p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 ) : null}
                                 {this.state.parking?.terms_condition !== "" &&
                                 this.state.parking?.terms_condition !==
                                    "null" &&
                                 this.state.parking?.terms_condition !== null &&
                                 this.state.parking?.terms_condition !==
                                    undefined ? (
                                    <div className="accordion-item border-bottom">
                                       <h2
                                          className="accordion-header"
                                          id="flush-headingSeven"
                                       >
                                          <button
                                             className="accordion-button cara-btn-landing collapsed  fw-normal"
                                             type="button"
                                             data-bs-toggle="collapse"
                                             data-bs-target="#flush-collapseSeven"
                                             aria-expanded="false"
                                             aria-controls="flush-collapseSeven"
                                          >
                                             <h4
                                                className="wemblay-business-detail-heading mb-0 text-wrap"
                                                style={{
                                                   color: "#3E94A6",
                                                   fontWeight: "600",
                                                   fontSize: "17px",
                                                }}
                                             >
                                                <img
                                                   src={terms}
                                                   alt=""
                                                   className="me-2"
                                                   width={27}
                                                />
                                                Addition Terms / Condition
                                             </h4>
                                          </button>
                                       </h2>
                                       <div
                                          id="flush-collapseSeven"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="flush-headingSeven"
                                          data-bs-parent="#accordionFlushExample"
                                       >
                                          <div className="accordion-body text-start cara-heading">
                                             <div className="col-12 mt-1">
                                                <div className=" sliderScreen border shadow rounded bg-white p-4 text-start ">
                                                   <p className="mb-0">
                                                      {this.state.parking
                                                         ?.terms_condition ??
                                                         ""}
                                                   </p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 ) : null}
                                 {this.state.parking?.availability_type ==
                                 "hourly" ? (
                                    <>
                                       {this.state.parking?.availabilities
                                          ?.length > 0 ? (
                                          <>
                                             {" "}
                                             <div className="accordion-item border-bottom">
                                                <h6
                                                   className="accordion-header"
                                                   id="flush-headingEight"
                                                >
                                                   <button
                                                      className="accordion-button cara-btn-landing collapsed  fw-normal"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#flush-collapseEight"
                                                      aria-expanded="false"
                                                      aria-controls="flush-collapseEight"
                                                   >
                                                      <h6
                                                         className="wemblay-business-detail-heading mb-0 text-wrap"
                                                         style={{
                                                            color: "#3E94A6",
                                                            fontWeight: "600",
                                                            fontSize: "17px",
                                                         }}
                                                      >
                                                         <img
                                                            src={availability}
                                                            width={20}
                                                            className="me-3"
                                                            alt=""
                                                         />
                                                         Hourly Availability
                                                      </h6>
                                                   </button>
                                                </h6>
                                                <div
                                                   id="flush-collapseEight"
                                                   className="accordion-collapse collapse"
                                                   aria-labelledby="flush-headingEight"
                                                   data-bs-parent="#accordionFlushExample"
                                                >
                                                   <div className="accordion-body text-start cara-heading">
                                                      <div className="col-12 mt-1">
                                                         <div className=" sliderScreen border shadow rounded bg-white p-4 text-start ">
                                                            <table class="table">
                                                               <thead>
                                                                  <tr>
                                                                     <th scope="col">
                                                                        {/* <i
                                                                                       className="mdi mdi-calendar-clock me-2 "
                                                                                       style={{
                                                                                          color: "#3E94A6",
                                                                                          fontWeight:
                                                                                             "bold",
                                                                                       }}
                                                                                    ></i> */}
                                                                        Start
                                                                        Date
                                                                     </th>
                                                                     <th scope="col">
                                                                        {/* <i
                                                                                       className="mdi mdi-av-timer me-2 "
                                                                                       style={{
                                                                                          color: "#3E94A6",
                                                                                          fontWeight:
                                                                                             "bold",
                                                                                       }}
                                                                                    ></i> */}
                                                                        End Date
                                                                     </th>

                                                                     <th scope="col">
                                                                        Reserved
                                                                     </th>
                                                                  </tr>
                                                               </thead>
                                                               <tbody>
                                                                  {this.state
                                                                     .parking
                                                                     ?.availabilities
                                                                     ?.length >
                                                                  0 ? (
                                                                     <>
                                                                        {" "}
                                                                        {this.state.parking?.availabilities?.map(
                                                                           (
                                                                              item,
                                                                              index
                                                                           ) => (
                                                                              <tr
                                                                                 style={{
                                                                                    borderBottom:
                                                                                       ".5px solid #eff2f7",
                                                                                 }}
                                                                              >
                                                                                 <td>
                                                                                    {item?.start_date ??
                                                                                       ""}
                                                                                 </td>
                                                                                 <td>
                                                                                    {item?.end_date ??
                                                                                       ""}
                                                                                    {/* {item
                                                                                             ?.parking_availability_slots
                                                                                             ?.length >
                                                                                          0
                                                                                             ? item?.parking_availability_slots?.map(
                                                                                                  (
                                                                                                     time,
                                                                                                     index
                                                                                                  ) => (
                                                                                                     <>
                                                                                                        <div>
                                                                                                           {` ${time?.start_at} To ${time?.end_at} `}
                                                                                                        </div>
                                                                                                     </>
                                                                                                  )
                                                                                               )
                                                                                             : null} */}
                                                                                 </td>

                                                                                 <td>
                                                                                    {/* {item
                                                                                             ?.bookingtimings
                                                                                             ?.length >
                                                                                          0
                                                                                             ? item?.bookingtimings?.map(
                                                                                                  (
                                                                                                     bookingTimes,
                                                                                                     index
                                                                                                  ) => {
                                                                                                     <>
                                                                                                        const
                                                                                                        [_,
                                                                                                        ...rest]
                                                                                                        =
                                                                                                        string.split('
                                                                                                        ')
                                                                                                        return{" "}
                                                                                                        <div>
                                                                                                           {rest.join(
                                                                                                              " "
                                                                                                           )}
                                                                                                        </div>
                                                                                                        {
                                                                                                           bookingTimes?.start_time
                                                                                                        }{" "}
                                                                                                        To{" "}
                                                                                                        {
                                                                                                           bookingTimes?.start_time
                                                                                                        }
                                                                                                     </>;
                                                                                                  }
                                                                                               )
                                                                                             : null} */}
                                                                                    {item
                                                                                       ?.bookingtimings
                                                                                       ?.length >
                                                                                    0 ? (
                                                                                       item?.bookingtimings?.map(
                                                                                          (
                                                                                             bookingTimes,
                                                                                             index
                                                                                          ) => {
                                                                                             const [
                                                                                                _,
                                                                                                ...start
                                                                                             ] =
                                                                                                bookingTimes?.start_time?.split(
                                                                                                   " "
                                                                                                );
                                                                                             const [
                                                                                                notEnd,
                                                                                                ...end
                                                                                             ] =
                                                                                                bookingTimes?.start_time?.split(
                                                                                                   " "
                                                                                                );
                                                                                             return (
                                                                                                <div>
                                                                                                   <>
                                                                                                      {start?.join(
                                                                                                         " "
                                                                                                      )}
                                                                                                      <span className="px-2">
                                                                                                         To
                                                                                                      </span>
                                                                                                      {end?.join(
                                                                                                         " "
                                                                                                      )}
                                                                                                   </>
                                                                                                </div>
                                                                                             );
                                                                                          }
                                                                                       )
                                                                                    ) : (
                                                                                       <span className="badge bg-success">
                                                                                          Not
                                                                                          Reserved
                                                                                       </span>
                                                                                    )}
                                                                                 </td>
                                                                              </tr>
                                                                           )
                                                                        )}
                                                                     </>
                                                                  ) : null}
                                                               </tbody>
                                                            </table>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </>
                                       ) : null}
                                    </>
                                 ) : null}
                                 {this.state.parking?.availability_type ==
                                 "daily" ? (
                                    <>
                                       {this.state.parking?.availabilities
                                          ?.length > 0 ? (
                                          <>
                                             {" "}
                                             <div className="accordion-item border-bottom">
                                                <h6
                                                   className="accordion-header"
                                                   id="flush-headingEight"
                                                >
                                                   <button
                                                      className="accordion-button cara-btn-landing collapsed  fw-normal"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#flush-collapseEight"
                                                      aria-expanded="false"
                                                      aria-controls="flush-collapseEight"
                                                   >
                                                      <h6
                                                         className="wemblay-business-detail-heading mb-0 text-wrap"
                                                         style={{
                                                            color: "#3E94A6",
                                                            fontWeight: "600",
                                                            fontSize: "17px",
                                                         }}
                                                      >
                                                         <img
                                                            src={availability}
                                                            width={20}
                                                            className="me-3"
                                                            alt=""
                                                         />
                                                         Daily Availability
                                                      </h6>
                                                   </button>
                                                </h6>
                                                <div
                                                   id="flush-collapseEight"
                                                   className="accordion-collapse collapse"
                                                   aria-labelledby="flush-headingEight"
                                                   data-bs-parent="#accordionFlushExample"
                                                >
                                                   <div className="accordion-body text-start cara-heading">
                                                      <div className="col-12 mt-1">
                                                         <div className=" sliderScreen border shadow rounded bg-white p-4 text-start ">
                                                            <table class="table">
                                                               <thead>
                                                                  <tr>
                                                                     <th scope="col">
                                                                        {/* <i
                                                               className="mdi mdi-calendar-clock me-2 "
                                                               style={{
                                                                  color: "#3E94A6",
                                                                  fontWeight:
                                                                     "bold",
                                                               }}
                                                            ></i> */}
                                                                        Start
                                                                        Date
                                                                     </th>
                                                                     <th scope="col">
                                                                        {/* <i
                                                               className="mdi mdi-av-timer me-2 "
                                                               style={{
                                                                  color: "#3E94A6",
                                                                  fontWeight:
                                                                     "bold",
                                                               }}
                                                            ></i> */}
                                                                        End Date
                                                                     </th>
                                                                     <th scope="col">
                                                                        {/* <i
                                                               className="mdi mdi-av-timer me-2 "
                                                               style={{
                                                                  color: "#3E94A6",
                                                                  fontWeight:
                                                                     "bold",
                                                               }}
                                                            ></i> */}
                                                                        Reserved
                                                                     </th>
                                                                  </tr>
                                                               </thead>
                                                               <tbody>
                                                                  {this.state.parking?.availabilities?.map(
                                                                     (
                                                                        item,
                                                                        index
                                                                     ) => (
                                                                        <tr
                                                                           style={{
                                                                              borderBottom:
                                                                                 ".5px solid #eff2f7",
                                                                           }}
                                                                        >
                                                                           <td>
                                                                              {item?.start_date ??
                                                                                 ""}
                                                                           </td>
                                                                           <td>
                                                                              {item?.end_date ??
                                                                                 ""}
                                                                           </td>

                                                                           <td>
                                                                              {/* {this.getTime(
                                                                     item?.end_at
                                                                  )} */}
                                                                              {item
                                                                                 ?.bookings
                                                                                 ?.length >
                                                                                 0 &&
                                                                              item?.bookings !==
                                                                                 null &&
                                                                              item?.bookings !==
                                                                                 undefined ? (
                                                                                 <span className=" badge bg-danger">
                                                                                    Reserved
                                                                                 </span>
                                                                              ) : (
                                                                                 <span className="badge bg-success">
                                                                                    Not
                                                                                    Reserved
                                                                                 </span>
                                                                              )}
                                                                           </td>
                                                                        </tr>
                                                                     )
                                                                  )}
                                                               </tbody>
                                                            </table>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </>
                                       ) : null}
                                    </>
                                 ) : null}

                                 <div className="accordion-item border-bottom">
                                    <h6
                                       className="accordion-header"
                                       id="flush-headingTen"
                                    >
                                       <button
                                          className="accordion-button cara-btn-landing collapsed  fw-normal"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#flush-collapseTen"
                                          aria-expanded="false"
                                          aria-controls="flush-collapseTen"
                                       >
                                          <h6
                                             className="wemblay-business-detail-heading mb-0 text-wrap"
                                             style={{
                                                color: "#3E94A6",
                                                fontWeight: "600",
                                                fontSize: "17px",
                                             }}
                                          >
                                             <i
                                                className="mdi mdi-currency-usd me-3"
                                                style={{
                                                   color: "#3E94A6",
                                                   fontSize: "22px",
                                                }}
                                             ></i>
                                             Price
                                          </h6>
                                       </button>
                                    </h6>
                                    <div
                                       id="flush-collapseTen"
                                       className="accordion-collapse collapse"
                                       aria-labelledby="flush-headingTen"
                                       data-bs-parent="#accordionFlushExample"
                                    >
                                       <div className="accordion-body  text-start cara-heading">
                                          <div className="col-12 mt-1">
                                             <div className=" sliderScreen border shadow rounded bg-white p-4 text-start ">
                                                <table class="table">
                                                   <thead>
                                                      <tr>
                                                         <th scope="col">
                                                            <i
                                                               className="mdi mdi-pound me-1 "
                                                               style={{
                                                                  color: "#3E94A6",
                                                                  fontWeight:
                                                                     "bold",
                                                               }}
                                                            ></i>
                                                            Id
                                                         </th>
                                                         <th scope="col">
                                                            <i
                                                               className="mdi mdi-format-list-bulleted-type me-1 "
                                                               style={{
                                                                  color: "#3E94A6",
                                                                  fontWeight:
                                                                     "bold",
                                                               }}
                                                            ></i>
                                                            Type
                                                         </th>
                                                         <th scope="col">
                                                            <i
                                                               className="mdi mdi-currency-usd me-1 "
                                                               style={{
                                                                  color: "#3E94A6",
                                                                  fontWeight:
                                                                     "bold",
                                                               }}
                                                            ></i>
                                                            Price
                                                         </th>
                                                      </tr>
                                                   </thead>
                                                   {this.state.parking
                                                      ?.availability_type ==
                                                   "daily" ? (
                                                      <tbody>
                                                         {this.state.parking
                                                            ?.daily_price !==
                                                            undefined &&
                                                         this.state.parking
                                                            ?.daily_price !==
                                                            "undefined" &&
                                                         this.state.parking
                                                            ?.daily_price !==
                                                            null &&
                                                         this.state.parking
                                                            ?.daily_price !==
                                                            "" ? (
                                                            <tr>
                                                               <td className="ps-3">
                                                                  2
                                                               </td>
                                                               <td className="ps-3">
                                                                  Daily
                                                               </td>
                                                               <td className="ps-3">
                                                                  {` $${this.state.parking?.daily_price}` ??
                                                                     "No"}
                                                               </td>
                                                            </tr>
                                                         ) : (
                                                            <tr>
                                                               <td className="ps-3">
                                                                  2
                                                               </td>
                                                               <td className="ps-3">
                                                                  --
                                                               </td>
                                                               <td className="ps-3">
                                                                  --
                                                               </td>
                                                            </tr>
                                                         )}
                                                      </tbody>
                                                   ) : this.state.parking
                                                        ?.availability_type ==
                                                     "hourly" ? (
                                                      <tbody>
                                                         {this.state.parking
                                                            ?.hourly_price !==
                                                            null &&
                                                         this.state.parking
                                                            ?.hourly_price !==
                                                            undefined &&
                                                         this.state.parking
                                                            ?.hourly_price !==
                                                            "" ? (
                                                            <tr>
                                                               <td className="ps-3">
                                                                  1
                                                               </td>
                                                               <td className="ps-3">
                                                                  Hourly
                                                               </td>
                                                               <td className="ps-3">
                                                                  {`$${this.state.parking?.hourly_price} ` ??
                                                                     "No"}
                                                               </td>
                                                            </tr>
                                                         ) : (
                                                            <tr>
                                                               <td className="ps-3">
                                                                  1
                                                               </td>
                                                               <td className="ps-3">
                                                                  --
                                                               </td>
                                                               <td className="ps-3">
                                                                  --
                                                               </td>
                                                            </tr>
                                                         )}
                                                      </tbody>
                                                   ) : this.state.parking
                                                        ?.availability_type ==
                                                     "weekly" ? (
                                                      <tbody>
                                                         {this.state.parking
                                                            ?.weekly_price !==
                                                            null &&
                                                         this.state.parking
                                                            ?.weekly_price !==
                                                            undefined &&
                                                         this.state.parking
                                                            ?.weekly_price !==
                                                            "" ? (
                                                            <tr>
                                                               <td className="ps-3">
                                                                  3
                                                               </td>
                                                               <td className="ps-3">
                                                                  Weekly
                                                               </td>
                                                               <td className="ps-3">
                                                                  {`$${this.state.parking?.weekly_price} ` ??
                                                                     "No"}
                                                               </td>
                                                            </tr>
                                                         ) : (
                                                            <tr>
                                                               <td className="ps-3">
                                                                  3
                                                               </td>
                                                               <td className="ps-3">
                                                                  --
                                                               </td>
                                                               <td className="ps-3">
                                                                  --
                                                               </td>
                                                            </tr>
                                                         )}
                                                      </tbody>
                                                   ) : (
                                                      <tr>
                                                         <td className="ps-3">
                                                            1
                                                         </td>
                                                         <td className="ps-3">
                                                            --
                                                         </td>
                                                         <td className="ps-3">
                                                            --
                                                         </td>
                                                      </tr>
                                                   )}
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="accordion-item border-bottom">
                                    <h6
                                       className="accordion-header"
                                       id="flush-headingNine"
                                    >
                                       <button
                                          className="accordion-button cara-btn-landing collapsed  fw-normal"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#flush-collapseNine"
                                          aria-expanded="true"
                                          aria-controls="flush-collapseNine"
                                       >
                                          <h6
                                             className="wemblay-business-detail-heading mb-0 text-wrap"
                                             style={{
                                                color: "#3E94A6",
                                                fontWeight: "600",
                                                fontSize: "17px",
                                             }}
                                          >
                                             <i
                                                className="mdi mdi-map-marker-radius me-3 "
                                                style={{
                                                   color: "#3E94A6",
                                                   fontSize: "22px",
                                                }}
                                             ></i>
                                             Location
                                          </h6>
                                       </button>
                                    </h6>
                                    <div
                                       id="flush-collapseNine"
                                       className="accordion-collapse collapse"
                                       aria-labelledby="flush-headingNine"
                                       data-bs-parent="#accordionFlushExample"
                                    >
                                       <div className="accordion-body text-start cara-heading">
                                          <div className="col-12 mt-4">
                                             <div className=" sliderScreen border shadow rounded bg-white p-4 text-start ">
                                                <hr />
                                                <iframe
                                                   src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDcLGFU2zAn2rITMgtWVTkiGnuOPnqmtCU&q=${this.state.parking?.location?.lat},${this.state.parking?.location?.lng}`}
                                                   width="100%"
                                                   height="450"
                                                   allowFullscreen=""
                                                   loading="lazy"
                                                   referrerPolicy="no-referrer-when-downgrade"
                                                ></iframe>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="my-4"></div>
                        {/* {this.state.parking?.bookings?.length > 0 ? (
                           <div className="col-12 px-4 pt-1 pb-4 mt-5 card">
                              <RatingSection
                                 reviewData={this.state.parking?.bookings}
                                 price={
                                    this.state.parking !== "" &&
                                    this.state.parking !== null &&
                                    this.state.parking !== undefined
                                       ? this.state.parking?.daily_price
                                       : this.state.parking?.hourly_price
                                 }
                              />
                           </div>
                        ) : null} */}
                     </>
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center "
                        style={{ height: "50vh" }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </Container>
            </div>
         </>
      );
   }
}

// export default ManageParkingProfileData;
