import React, { Component } from "react";
import { Row, Col, Alert, Container, Label } from "reactstrap";
import Select from "react-select";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
   registerUser,
   registerUserFailed,
   apiError,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";

const businessOption = [
   {
      label: "Select your Business status ",
      options: [
         { label: "Limited Company", value: "Limited Company" },
         {
            label: "Limited Partnership",
            value: "Limited Partnership",
         },
         { label: "Individual", value: "Individual" },
      ],
   },
];
const businessNatureOption = [
   {
      label: "Countries",
      options: [
         { label: "Clothing", value: "Clothing" },
         { label: "Electronics & Computer", value: "Electronics & Computer" },
         { label: "Health & Beauty", value: "Health & Beauty" },
         {
            label: "Personal & Home Appliance",
            value: "Personal & Home Appliance",
         },
         {
            label: "Restaurants Foods & Beverages",
            value: "Restaurants Foods & Beverages",
         },
      ],
   },
];

class MerchantRegister extends Component {
   constructor(props) {
      super(props);
      this.state = {
         email: "",
         username: "",
         password: "",
         RepeatPassword: "",

         //   selectedGroup: null,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
   }

   handleSubmit(event, values) {
      this.props.registerUser(values);
   }

   componentDidMount() {
      // this.props.registerUserFailed('');
      // this.props.apiError('');
      document.body.classList.add("auth-body-bg");
   }

   render() {
      return (
         <React.Fragment>
            <div>
               <Container fluid className="p-0">
                  <Row className="g-0">
                     <Col lg={6}>
                        <div className="p-2 lg-p-5 d-flex align-items-center">
                           <div className="w-100">
                              <Row className="justify-content-center">
                                 <Col lg={9}>
                                    <div>
                                       {this.props.user && (
                                          <Alert color="success">
                                             Registration Done Successfully.
                                          </Alert>
                                       )}

                                       {this.props.registrationError && (
                                          <Alert color="danger">
                                             {this.props.registrationError}
                                          </Alert>
                                       )}

                                       <div className="p-2 mt-5">
                                          <AvForm
                                             onValidSubmit={this.handleSubmit}
                                             className="form-horizontal"
                                          >
                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-cake-3-line auti-custom-input-icon"></i>
                                                <Label htmlFor="username">
                                                   Please Enter Shop Name
                                                </Label>
                                                <AvField
                                                   name="username"
                                                   value={this.state.username}
                                                   type="text"
                                                   className="form-control"
                                                   id="username1"
                                                   placeholder="Enter Your Shop Name"
                                                />
                                             </div>
                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-briefcase-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="username">
                                                   Please Enter Business Name
                                                </Label>
                                                <AvField
                                                   name="username"
                                                   value={this.state.username}
                                                   type="text"
                                                   className="form-control"
                                                   id="username2"
                                                   placeholder="Enter Your Business Name"
                                                />
                                             </div>

                                             <div className="mb-3">
                                                <Label className="form-label">
                                                   Select Your Business Status
                                                </Label>
                                                <Select
                                                   //   value={selectedGroup}
                                                   //   onChange={this.handleSelectGroup}
                                                   options={businessOption}
                                                   classNamePrefix="select2-selection"
                                                />
                                             </div>
                                             <Row>
                                                <Col md="6">
                                                   <div className="mb-4">
                                                      <Label
                                                         className="form-label"
                                                         htmlFor="validationCustom03"
                                                      >
                                                         Please Enter Building
                                                         Name
                                                      </Label>
                                                      <AvField
                                                         name="city"
                                                         placeholder=" Shop Building Name"
                                                         type="text"
                                                         // errorMessage=" Please provide a valid city."
                                                         className="form-control"
                                                         validate={{
                                                            required: {
                                                               value: true,
                                                            },
                                                         }}
                                                         id="validationCustom03"
                                                      />
                                                   </div>
                                                </Col>
                                                <Col md="6">
                                                   <div className="mb-4">
                                                      <Label
                                                         className="form-label"
                                                         htmlFor="validationCustom04"
                                                      >
                                                         Please Enter Street /
                                                         Road{" "}
                                                      </Label>
                                                      <AvField
                                                         name="state"
                                                         placeholder=" Street / Road"
                                                         type="text"
                                                         // errorMessage="Please provide a valid state."
                                                         className="form-control"
                                                         validate={{
                                                            required: {
                                                               value: true,
                                                            },
                                                         }}
                                                         id="validationCustom04"
                                                      />
                                                   </div>
                                                </Col>
                                                <Col md="6">
                                                   <div className="mb-4 ">
                                                      <Label
                                                         className="form-label"
                                                         htmlFor="validationCustom05"
                                                      >
                                                         Please Enter Area
                                                         Estate Name
                                                      </Label>
                                                      <AvField
                                                         name="zip"
                                                         placeholder=" Area Estate Name"
                                                         type="text"
                                                         // errorMessage=' Please provide a valid zip.'
                                                         className="form-control"
                                                         validate={{
                                                            required: {
                                                               value: true,
                                                            },
                                                         }}
                                                         id="validationCustom05"
                                                      />
                                                   </div>
                                                </Col>
                                                <Col md="6">
                                                   <div className="mb-4">
                                                      <Label
                                                         className="form-label"
                                                         htmlFor="validationCustom06"
                                                      >
                                                         Please Enter]feature
                                                         for a visitor
                                                      </Label>
                                                      <AvField
                                                         name="zip"
                                                         placeholder="Feature for visitor"
                                                         type="text"
                                                         // errorMessage=' Please provide a valid zip.'
                                                         className="form-control"
                                                         validate={{
                                                            required: {
                                                               value: true,
                                                            },
                                                         }}
                                                         id="validationCustom06"
                                                      />
                                                   </div>
                                                </Col>
                                             </Row>
                                             <div className="mb-3">
                                                <Label className="form-label">
                                                   Please Select Nature of
                                                   Business
                                                </Label>
                                                <Select
                                                   //   value={selectedGroup}
                                                   //   onChange={this.handleSelectGroup}
                                                   options={
                                                      businessNatureOption
                                                   }
                                                   classNamePrefix="select2-selection"
                                                />
                                             </div>
                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="username">
                                                   Please Enter Contact Person
                                                   Name
                                                </Label>
                                                <AvField
                                                   name="username"
                                                   value={this.state.username}
                                                   type="text"
                                                   className="form-control"
                                                   id="username3"
                                                   placeholder="Enter Your Contact Person Name"
                                                />
                                             </div>
                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="usernumber">
                                                   Mobile number
                                                </Label>
                                                <AvField
                                                   name="email3"
                                                   value={this.state.username}
                                                   validate={{
                                                      username: true,
                                                      required: true,
                                                   }}
                                                   type="email"
                                                   className="form-control"
                                                   id="useremail3"
                                                   placeholder="Enter Mobile number"
                                                />
                                             </div>
                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="userpassword">
                                                   Password
                                                </Label>
                                                <AvField
                                                   name="password"
                                                   value={this.state.password}
                                                   type="password"
                                                   className="form-control"
                                                   id="userpassword"
                                                   placeholder="Enter password"
                                                />
                                             </div>

                                             <div className="auth-form-group-custom mb-4">
                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="userpassword">
                                                   Repeat Password
                                                </Label>
                                                <AvField
                                                   name="password"
                                                   value={
                                                      this.state.RepeatPassword
                                                   }
                                                   type="password"
                                                   className="form-control"
                                                   id="userpassword1"
                                                   placeholder="Enter Repeat Password"
                                                />
                                             </div>
                                          </AvForm>
                                       </div>
                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        </div>
                     </Col>
                     <Col lg={6}>
                        <div className="authentication-bg h-100">
                           <div className="bg-overlay"></div>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </div>
         </React.Fragment>
      );
   }
}

const mapStatetoProps = (state) => {
   const { user, registrationError, loading } = state.Account;
   return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
   registerUser,
   apiError,
   registerUserFailed,
})(MerchantRegister);
