// import React from "react";
import React, { Component } from "react";
import { Button, Card, Col, Container, Row, Table } from "reactstrap";
import {
   AddUser,
   getCountry,
   GetUser,
   EditUser,
   DeleteUser,
   searchUser,
   setActiveUser,
   setDeActiveUser,
} from "../Services/UserServices/ManageUsers/index";
import moment from "moment";

import image_userD from "../../assets/UserIcons/image_userD.svg";
import image_userA from "../../assets/UserIcons/image_userA.svg";
import profileImage from "../../assets/images/profile_image.jpg";

import { Link } from "react-router-dom";
import "./AllUsers.css";
import Swal from "sweetalert2";

// import React from "react";
import Select from "react-select";
import Pagination from "../VerticalLayout/pagination/Pagination";
// import PhoneInput from "react-phone-input-2";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
class AllUsers extends Component {
   constructor(props) {
      super(props);
      // this.props.ColumnsData = new ColumnsData()
      // this.columns = this.columns.bind(this);
      this.SaveUser = this.SaveUser.bind(this);
      this.EmailFunction = this.EmailFunction.bind(this);
      this.AllClear = this.AllClear.bind(this);
      // this.AllValueClear = this.AllValueClear.bind(this);
      this.updateUser = this.updateUser.bind(this);
      // this.DeActiveUserID = this.DeActiveUserID.bind(this);
      // this.setActiveUser = this.setActiveUser.bind(this);

      this.phoneValidation = this.phoneValidation.bind(this);
      this.state = {
         firstName: "",
         genderValue: "",
         countryNameValue: "",
         statusValue: "",
         firstNameVal: false,
         lastName: "",
         lastNameVal: false,
         gender: "",
         genderVal: false,
         email: "",
         emailVal: false,
         phone: "",
         phoneVal: false,
         status: "",
         statusVal: false,
         countryName: [],
         countryID: "",
         countryIDVal: false,
         userAPI: [],
         userDetail: [],
         editUserID: "",
         statusUpdate: false,
         emailValidation: true,
         searchItem: "",
         currentPage: "",
         lastPage: [],
         loading: false,
         countryFullName: "",
         focusButton: false,
         isTrue: false,
         searchData: "",
         selectedCountry: "",
         pageIDs: [],

         loadingModel: true,

         profilesImg: "",
         parkingCount: "",
         listingCount: "",
      };
   }

   gender = [
      {
         id: 1,
         value: "Male",
      },
      {
         id: 2,
         value: "Female",
      },
      {
         id: 3,
         value: "Other",
      },
   ];
   status = [
      {
         id: 1,
         value: "active",
      },
      {
         id: 2,
         value: "deactive",
      },
   ];

   pageCount;
   async componentDidMount() {
      //  Getting All Countries from API -------------
      // this.focus();
      this.pageCount = this.state.lastPage;
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const result = await getCountry(accessToken);

         let countryArray = [];
         if (result.status == true) {
            result.countries?.map((item, index) => {
               countryArray.push({ label: item.name, value: item.id });
            });
            this.setState({ countryName: countryArray });
         } else {
         }
      } catch (err) {
         console.log("error", err);
      }

      // Getting Users from API Call----------------------
      this.getAllUsers();
   }

   async getAllUsers(page) {
      let IDArray = [];

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const userData = await searchUser(
            accessToken,
            page,
            this.state.searchItem
         );

         if (userData.status === true) {
            if (page > 1) {
               this.setState({ currentPage: page });
            } else this.setState({ currentPage: userData.users.current_page });
            this.setState({ lastPage: userData.users.last_page });
            this.setState({ userAPI: userData.users.data });

            for (let i = userData.users.from; i <= userData.users.to; i++) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });

            this.setState({ loading: true });
            this.state.userAPI.map((user, index) => {
               let userArray = [];
               userArray.push({
                  id: user.id,
                  name: user.first_name + " " + user.last_name,
                  email: user.email,
                  phone: user.phone,
                  gender: user.gender,
                  status: user.status,
               });
               this.setState({
                  userDetail: this.state.userDetail.concat(userArray),
               });
            });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   async SaveUser() {
      if (this.state.firstName == "" || this.state.firstName == undefined) {
         this.setState({ firstNameVal: true });
      }
      if (this.state.lastName == "" || this.state.lastName == undefined) {
         this.setState({ lastNameVal: true });
      }
      if (
         this.state.emailValidation == false ||
         this.state.email == "" ||
         this.state.email == undefined
      ) {
         this.setState({ emailVal: true });
      }
      if (this.state.gender == "" || this.state.gender == undefined) {
         this.setState({ genderVal: true });
      }
      if (this.state.phone == "" || this.state.phone == undefined) {
         this.setState({ phoneVal: true });
      }
      if (this.state.status == "" || this.state.status == undefined) {
         this.setState({ statusVal: true });
      }
      if (this.state.countryID == "" || this.state.countryID == undefined) {
         this.setState({ countryIDVal: true });
      }

      if (
         this.state.firstName != "" &&
         this.state.firstName != undefined &&
         this.state.lastName != "" &&
         this.state.lastName != undefined &&
         this.state.emailValidation == true &&
         this.state.gender != "" &&
         this.state.gender != undefined &&
         this.state.phone != "" &&
         this.state.phone != undefined &&
         // this.state.phone.length > 10 &&
         this.state.status != "" &&
         this.state.status != undefined &&
         this.state.countryID != "" &&
         this.state.countryID != undefined
      ) {
         this.setState({ loadingModel: false });

         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await AddUser(
               accessToken,
               this.state.firstName,
               this.state.lastName,
               this.state.email,
               this.state.phone,
               this.state.gender,
               this.state.status,
               this.state.countryID
            );

            if (response.status == true) {
               this.setState({ isTrue: true });
               this.getAllUsers(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "User added successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  firstName: "",
                  firstNameVal: false,
                  last_name: "",
                  last_nameVal: false,
                  email: "",
                  emailVal: false,
                  phone: "",
                  phoneVal: false,
                  gender: "",
                  genderVal: false,
                  status: "",
                  statusVal: false,
                  editUserID: "",
                  editUserIDVal: false,
                  countryID: "",
                  selectedCountry: "",
                  countryIDVal: false,
               });
               this.setState({ statusUpdate: true });
            } else {
               this.setState({ loadingModel: false });

               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: response.message,
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   dataLength;
   async setInitialValue(
      data,
      profileImg,
      id,
      first_name,
      last_name,
      email,
      phone,
      gender,
      status,
      country,
      parking_count,
      plates_count
   ) {
      // this.dataLength = data?.plates?.length;
      this.setState({
         profilesImg: profileImg,
         firstName: first_name,
         lastName: last_name,
         email: email,
         genderValue: gender,
         phone: phone,
         gender: gender,
         status: status,
         statusValue: status,
         countryNameValue: country,
         editUserID: id,
         parkingCount: plates_count,
         listingCount: parking_count,
      });
   }
   async updateUser() {
      if (this.state.firstName == "" || this.state.firstName == undefined) {
         this.setState({ firstNameVal: true });
      }
      if (this.state.lastName == "" || this.state.lastName == undefined) {
         this.setState({ lastNameVal: true });
      }
      if (this.state.emailValidation == false) {
         this.setState({ emailVal: true });
      }
      if (this.state.email == "" || this.state.email == undefined) {
         this.setState({ emailVal: true });
      }

      if (this.state.genderValue == "" || this.state.genderValue == undefined) {
         this.setState({ genderVal: true });
      }
      if (this.state.phone == "" || this.state.phone == undefined) {
         this.setState({ phoneVal: true });
      }
      if (this.state.status == "" || this.state.status == undefined) {
         this.setState({ statusVal: true });
      }
      if (this.state.countryName == "" || this.state.countryName == undefined) {
         this.setState({ countryIDVal: true });
      }
      if (
         this.state.firstName != "" &&
         this.state.firstName != undefined &&
         this.state.firstName != null &&
         this.state.lastName != "" &&
         this.state.lastName != undefined &&
         this.state.lastName != null &&
         this.state.emailValidation == true &&
         this.state.genderValue != "" &&
         this.state.genderValue != undefined &&
         this.state.genderValue != null &&
         this.state.phone != "" &&
         this.state.phone != null &&
         this.state.phone != undefined &&
         this.state.email != "" &&
         this.state.email != undefined &&
         this.state.email != null &&
         // this.state.phone.length > 10 &&
         this.state.status != "" &&
         this.state.status != null &&
         this.state.status != undefined
      ) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await EditUser(
               accessToken,
               this.state.firstName,
               this.state.lastName,
               this.state.email,
               this.state.phone,
               this.state.gender,
               this.state.status,
               this.state.countryID,
               this.state.editUserID,
               this.state.countryFullName
            );
            if (response.status == true) {
               this.getAllUsers(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "User updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.AllClear();
            } else {
               this.setState({ loadingModel: false });

               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: response.message,
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   async ActiveUserID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to activate this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await setActiveUser(accessToken, id);

            if (result.status === true) {
               this.getAllUsers(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "User active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActiveUserID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to Deactive this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await setDeActiveUser(accessToken, id);
            this.getAllUsers(this.state.currentPage);

            if (result.status === true) {
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "User Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   async SearchChangeHandler(e) {
      this.setState({ searchItem: e.target.value });

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const userData = await searchUser(
            accessToken,
            this.state.currentPage,
            e.target.value
         );
         if (userData.status === true) {
            this.getAllUsers();
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };
   AllClear = () => {
      this.setState({
         firstNameVal: false,
         lastNameVal: false,
         genderVal: false,
         emailVal: false,
         phoneVal: false,
         statusVal: false,
         countryIDVal: false,
         // emailValidation: false,
      });
      this.setState({
         firstName: "",
         lastName: "",
         gender: "",
         genderValue: "",
         email: "",
         phone: "AU",
         status: "",
         countryID: "",
         countryNameValue: "",
      });
   };

   EmailFunction = (e) => {
      // const regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      const regex =
         /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)+@[a-z]{4,15}[\.][a-z]{2,5}/g;
      // const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{3,9}\.[0-9]{3,9}\.[0-9]{3,9}\.[0-9]{3,6}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
      if (regex.test(e.target.value) == true) {
         this.setState({ emailValidation: true });
         this.setState({ email: e.target.value, emailVal: false });
      } else {
         this.setState({ emailValidation: false });
         this.setState({ email: e.target.value, emailVal: false });
      }
   };

   phoneValidation = (e) => {
      this.setState({ phoneVal: false });

      this.setState({ phone: e });
   };

   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };
   onKeyPressAddGroupEdit = (e) => {
      if (e.which === 13) {
         this.btnOne.click();
      }
   };

   handleSelectedCountry = (e) => {
      this.setState({ selectedCountry: e });
      this.setState({ countryID: e.value, countryIDVal: false });
   };
   editHandleSelectedCountry = (e) => {
      this.setState({ selectedCountry: e });
      this.setState({ countryID: e.value, countryIDVal: false });
   };
   async DeleteUserList(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await DeleteUser(accessToken, id);

            if (result.status === true) {
               this.getAllUsers(this.state.currentPage);

               // let userArray = [...this.state.userAPI];
               // userArray.map((item, index) => {
               //   if (item.id === id) {
               //     userArray.splice(index, 1);
               //   }
               // });
               // this.setState({ userAPI: userArray });

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "User removed successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 4000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };
   onKeyPressAddGroupEdit = (e) => {
      if (e.which === 13) {
         this.btnOne.click();
      }
   };
   //

   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllUsers(page);
   };
   render() {
      // let paginationArray = [];
      // for (let i = 1; i <= this.state.lastPage; i++) {
      //   paginationArray.push(i);
      // }

      return (
         <div className="page-content ">
            <Container>
               <div>
                  {this.state.loading === true ? (
                     <div className="col">
                        <h5 className="fs-5 mb-3 fw-bold">Users List</h5>

                        <Card className="p-4 ">
                           <div className="d-flex justify-content-between">
                              <div>
                                 {/* <div className="search-box "> */}
                                 <input
                                    type="text"
                                    class="form-control rounded-pill"
                                    style={{ paddingLeft: 20 }}
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search-addon"
                                    onChange={(e) =>
                                       this.SearchChangeHandler(e)
                                    }
                                 />
                                 {/* </div> */}
                              </div>
                              <div>
                                 <Button
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropOne"
                                    onChange={this.AllClear}
                                    type="button"
                                    style={{
                                       background:
                                          "linear-gradient(#3E94A6, #323F4B)",
                                    }}
                                    className="btn-rounded mb-2 me-2 "
                                 >
                                    {/* {this.state.loadingModel === true ? ( */}
                                    <span>
                                       {/* <i className="mdi mdi-plus me-1" /> */}
                                       Add User
                                    </span>
                                    {/* // ) : (
                        //   <span>
                        //     <div class="spinner-border text-center" role="status">
                        //       <span class="visually-hidden"></span>
                        //     </div>
                        //   </span>
                        // )} */}
                                 </Button>
                              </div>
                           </div>
                           <div className="overflow-auto">
                              <table className=" table table-responsive ">
                                 <thead>
                                    <tr>
                                       <th scope="col">No.</th>
                                       <th scope="col">Full Name</th>
                                       <th scope="col">Email</th>
                                       <th scope="col">Phone</th>
                                       {/* <th scope="col">Gender</th> */}
                                       <th scope="col">Status</th>
                                       <th scope="col">Join Date</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>

                                 <tbody className="pt-3">
                                    {this.state.userAPI?.length > 0 ? (
                                       <>
                                          {this.state.userAPI.map(
                                             (item, outer) => {
                                                return (
                                                   <>
                                                      <tr key={outer}>
                                                         {this.state.pageIDs?.map(
                                                            (item, inner) =>
                                                               outer ==
                                                               inner ? (
                                                                  <td>
                                                                     {item.id}
                                                                  </td>
                                                               ) : null
                                                         )}
                                                         <td
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdropFiveth"
                                                            className="pointer"
                                                            onClick={() => {
                                                               console.log(
                                                                  item
                                                               );
                                                               this.setInitialValue(
                                                                  item,
                                                                  `${item?.profile_image?.folder_path}${item?.profile_image?.file_name}`,
                                                                  item.id,

                                                                  item.first_name,
                                                                  item.last_name,
                                                                  item.email,
                                                                  item.phone,
                                                                  item.gender,
                                                                  item.status,
                                                                  item?.country
                                                                     ?.name,
                                                                  item?.parkings_count,
                                                                  item?.plates_count
                                                               );
                                                            }}
                                                            title="Profile"
                                                         >
                                                            {item.first_name ??
                                                               "" +
                                                                  " " +
                                                                  item.last_name ??
                                                               ""}
                                                         </td>
                                                         <td>
                                                            {item.email ?? "--"}
                                                         </td>
                                                         <td>
                                                            {item.phone ?? "--"}
                                                         </td>
                                                         <td>
                                                            {item.status ==
                                                            "active" ? (
                                                               <span
                                                                  className="badge bg-success "
                                                                  style={{
                                                                     paddingLeft: 13,
                                                                     paddingRight: 13,
                                                                  }}
                                                               >
                                                                  Active
                                                               </span>
                                                            ) : item.status ==
                                                              "deactive" ? (
                                                               <span className="badge bg-danger px-2">
                                                                  Deactive
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            {this.getDate(
                                                               item.created_at
                                                            )}
                                                         </td>
                                                         <td>
                                                            <div className="d-flex">
                                                               <Link
                                                                  to="#"
                                                                  className="text-primary"
                                                               >
                                                                  <i
                                                                     className="mdi mdi-pencil font-size-18"
                                                                     data-bs-toggle="modal"
                                                                     data-bs-target="#staticBackdropTwo"
                                                                     onClick={() =>
                                                                        this.setInitialValue(
                                                                           item,
                                                                           `${item?.profile_image?.folder_path}${item?.profile_image?.file_name}`,

                                                                           item.id,
                                                                           item.first_name,
                                                                           item.last_name,
                                                                           item.email,
                                                                           item.phone,
                                                                           item.gender,
                                                                           item.status,
                                                                           item
                                                                              ?.country
                                                                              ?.name,
                                                                           item?.parkings_count,
                                                                           item?.plates_count
                                                                        )
                                                                     }
                                                                     title="Edit"
                                                                  ></i>
                                                               </Link>
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger mx-2 cursor-pointer"
                                                                  onClick={() =>
                                                                     this.DeleteUserList(
                                                                        item.id
                                                                     )
                                                                  }
                                                               >
                                                                  <i
                                                                     className="mdi mdi-trash-can font-size-18"
                                                                     title="Delete"
                                                                  ></i>
                                                               </Link>
                                                               {item?.status ==
                                                               "active" ? (
                                                                  <Link
                                                                     to="#"
                                                                     className="text-danger"
                                                                     title="Deactive"
                                                                  >
                                                                     <img
                                                                        src={
                                                                           image_userA
                                                                        }
                                                                        alt=""
                                                                        onClick={() =>
                                                                           this.DeActiveUserID(
                                                                              item.id
                                                                           )
                                                                        }
                                                                     />
                                                                  </Link>
                                                               ) : item?.status ==
                                                                 "deactive" ? (
                                                                  <Link
                                                                     to="#"
                                                                     className="text-danger"
                                                                     title="Active"
                                                                  >
                                                                     <img
                                                                        src={
                                                                           image_userD
                                                                        }
                                                                        alt=""
                                                                        onClick={() =>
                                                                           this.ActiveUserID(
                                                                              item.id
                                                                           )
                                                                        }
                                                                     />
                                                                  </Link>
                                                               ) : (
                                                                  ""
                                                               )}
                                                            </div>
                                                         </td>
                                                      </tr>
                                                   </>
                                                );
                                             }
                                          )}
                                       </>
                                    ) : (
                                       <></>
                                    )}
                                 </tbody>
                              </table>
                              {this.state.userAPI?.length < 1 && (
                                 <p className="text-center pt-5 fs-4 fw-bold ">
                                    Record Not Found.
                                 </p>
                              )}
                           </div>
                           {this.state.lastPage > 1 && (
                              <Pagination
                                 pageCount={this.state.lastPage}
                                 paginate={this.paginate}
                              />
                           )}
                        </Card>

                        {/* ////////////////////////////////add model */}

                        <div
                           className="modal fade"
                           id="staticBackdropOne"
                           data-bs-backdrop="static"
                           data-bs-keyboard="false"
                           tabIndex={-1}
                           aria-labelledby="staticBackdropLabel"
                           aria-hidden="true"
                        >
                           <div className="modal-dialog">
                              <div className="modal-content text-start">
                                 <div className="modal-header">
                                    <h5
                                       className="modal-title"
                                       id="staticBackdropLabel"
                                    >
                                       Add User
                                    </h5>
                                    <button
                                       type="button"
                                       onClick={this.AllClear}
                                       className="btn-close"
                                       data-bs-dismiss="modal"
                                       aria-label="Close"
                                    />
                                 </div>
                                 <div className="modal-body">
                                    <div className="d-flex justify-content-around text-dark">
                                       <div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1 "
                                             className="fw-bolder  form-label mb-0  required"
                                          >
                                             First Name
                                          </label>
                                          <input
                                             type="text"
                                             // ref={(input) => {
                                             //   this.textInput = input;
                                             // }}
                                             id="default"
                                             autoFocus={this.state.focusButton}
                                             className="form-control form-control-solid "
                                             placeholder="John"
                                             value={this.state.firstName}
                                             onChange={(e) =>
                                                this.setState({
                                                   firstName: e.target.value,
                                                   firstNameVal: false,
                                                })
                                             }
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroup(e)
                                             }
                                          />
                                          {this.state.firstNameVal ? (
                                             <div className="modelValidation">
                                                Enter first name
                                             </div>
                                          ) : null}
                                       </div>
                                       <div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1"
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             Last Name
                                          </label>
                                          <input
                                             type="text"
                                             className="form-control form-control-solid "
                                             placeholder="Doe"
                                             value={this.state.lastName}
                                             onChange={(e) =>
                                                this.setState({
                                                   lastName: e.target.value,
                                                   lastNameVal: false,
                                                })
                                             }
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroup(e)
                                             }
                                          />
                                          {this.state.lastNameVal ? (
                                             <div className="modelValidation">
                                                Enter last name
                                             </div>
                                          ) : null}
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-around mt-4 text-dark">
                                       <div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1"
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             Email
                                          </label>
                                          <input
                                             type="email"
                                             className="form-control form-control-solid "
                                             placeholder="johndoe@email.com"
                                             value={this.state.email}
                                             onChange={this.EmailFunction}
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroup(e)
                                             }
                                             // onChange={(e) => this.setState({ email: e.target.value, emailVal: false })}
                                          />

                                          {this.state.emailVal ? (
                                             <div className="modelValidation">
                                                Invalid email
                                             </div>
                                          ) : null}
                                       </div>
                                       <div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1"
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             Gender
                                          </label>
                                          <select
                                             class="form-select"
                                             aria-label="Default select example"
                                             value={this.state.gender}
                                             onChange={(e) =>
                                                this.setState({
                                                   gender: e.target.value,
                                                   genderVal: false,
                                                })
                                             }
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroup(e)
                                             }
                                          >
                                             {this.state.genderValue != "" &&
                                             this.state.genderValue !=
                                                undefined ? (
                                                <option hidden>
                                                   {this.state.genderValue}
                                                </option>
                                             ) : (
                                                <option hidden>
                                                   Select gender
                                                </option>
                                             )}

                                             {this.gender.map((item, i) => (
                                                <option
                                                   key={i}
                                                   value={item.value}
                                                >
                                                   {item.value}
                                                </option>
                                             ))}
                                             {/* <option value="Male">Male</option>
                              <option value="Other">Other</option> */}
                                          </select>
                                          {this.state.genderVal ? (
                                             <div className="modelValidation">
                                                Select gender
                                             </div>
                                          ) : null}
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-start mt-4 text-dark text-start">
                                       <div div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1"
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             Phone
                                          </label>

                                          <PhoneInput
                                             international
                                             placeholder="+1(453)123-4567"
                                             className="PhoneInputInput"
                                             initialValueFormat="international"
                                             countryCallingCodeEditable={true}
                                             onEnterKeyPress={(e) => {
                                                this.onKeyPressAddGroup(e);
                                             }}
                                             limitMaxLength={7}
                                             value={this.state.phone}
                                             onChange={(e) =>
                                                this.phoneValidation(e)
                                             }
                                          />
                                          {this.state.phoneVal ? (
                                             <div className="modelValidation">
                                                Invalid number
                                             </div>
                                          ) : null}
                                       </div>
                                       <div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1"
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             Status
                                          </label>
                                          <select
                                             class="form-select"
                                             aria-label="Default select example"
                                             value={this.state.status}
                                             onChange={(e) =>
                                                this.setState({
                                                   status: e.target.value,
                                                   statusVal: false,
                                                })
                                             }
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroup(e)
                                             }
                                          >
                                             <option hidden>
                                                Select status
                                             </option>
                                             <option value="active">
                                                Active
                                             </option>
                                             <option value="deactive">
                                                Deactive
                                             </option>
                                          </select>
                                          {this.state.statusVal ? (
                                             <div className="modelValidation">
                                                Select status
                                             </div>
                                          ) : null}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="mx-4">
                                    <label
                                       htmlFor="exampleFormControlInput1"
                                       className="fw-bolder required form-label mb-0"
                                    >
                                       Country
                                    </label>
                                    <Select
                                       class="form-select"
                                       value={this.state.selectedCountry}
                                       onChange={(e) =>
                                          this.handleSelectedCountry(e)
                                       }
                                       options={this.state.countryName}
                                       aria-label="Default select example"
                                       // onChange={(e) => this.setState({ countryID: e.value, countryIDVal: false })}
                                    />
                                    {this.state.countryIDVal ? (
                                       <div className="modelValidation">
                                          Select country
                                       </div>
                                    ) : null}
                                 </div>

                                 <div className="modal-footer">
                                    <button
                                       type="button"
                                       onClick={this.AllClear}
                                       className="btn btn-secondary"
                                       data-bs-dismiss="modal"
                                    >
                                       Close
                                    </button>

                                    {this.state.firstName != "" &&
                                    this.state.firstName != undefined &&
                                    this.state.lastName != "" &&
                                    this.state.lastName != undefined &&
                                    this.state.emailValidation == true &&
                                    this.state.email != "" &&
                                    this.state.email != undefined &&
                                    // this.state.email.endsWith(".com") &&
                                    // !this.state.email.startsWith("@") &&
                                    // this.state.email.includes("@") &&
                                    this.state.gender != "" &&
                                    this.state.gender != undefined &&
                                    this.state.phone != "" &&
                                    // this.state.phone.length > 10 &&
                                    this.state.phone != undefined &&
                                    this.state.status != "" &&
                                    this.state.status != undefined &&
                                    this.state.countryID != "" &&
                                    this.state.countryID != undefined ? (
                                       <button
                                          type="button"
                                          style={{
                                             background:
                                                "linear-gradient(#3E94A6, #323F4B)",
                                          }}
                                          className="btn btn-primary"
                                          onClick={this.SaveUser}
                                          data-bs-dismiss="modal"
                                          ref={(node) => (this.btn = node)}
                                       >
                                          Add User
                                       </button>
                                    ) : (
                                       <button
                                          type="button"
                                          style={{
                                             background:
                                                "linear-gradient(#3E94A6, #323F4B)",
                                          }}
                                          ref={(node) => (this.btn = node)}
                                          className="btn btn-primary"
                                          onClick={this.SaveUser}
                                       >
                                          Add User
                                       </button>
                                    )}
                                 </div>
                              </div>
                           </div>
                        </div>

                        {/* //////////////Profile model */}
                        <div
                           className="modal fade"
                           id="staticBackdropFiveth"
                           data-bs-backdrop="static"
                           data-bs-keyboard="false"
                           tabIndex={-1}
                           aria-labelledby="staticBackdropLabel"
                           aria-hidden="true"
                        >
                           <div className="modal-dialog">
                              <div className="modal-content text-start">
                                 <div className="modal-header">
                                    <h5
                                       className="modal-title"
                                       id="staticBackdropLabel"
                                    >
                                       User Profile
                                    </h5>
                                    <button
                                       type="button"
                                       onClick={this.AllClear}
                                       className="btn-close"
                                       data-bs-dismiss="modal"
                                       aria-label="Close"
                                    />
                                 </div>
                                 <div className="modal-body">
                                    {/* profilesImg */}
                                    <div className="image-input-wrapper  p-3">
                                       <center>
                                          {this.state.profilesImg ===
                                          "undefinedundefined" ? (
                                             <img
                                                className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                                src={profileImage}
                                                alt=""
                                             />
                                          ) : (
                                             <img
                                                className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                                src={this.state.profilesImg}
                                                alt=""
                                             />
                                          )}
                                       </center>
                                       {/* <img src={this.state.profilesImg} className="img-fluid modal_image w-50 h-50 " alt="" /> */}
                                    </div>

                                    {/* <div className="d-flex justify-content-around text-dark"> */}
                                    <div className="Detail border shadow rounded bg-light p-2">
                                       <div className="row ">
                                          <div className=" col-4">
                                             <label
                                                htmlFor="exampleFormControlInput1 "
                                                className="fw-bolder  form-label mb-0"
                                             >
                                                First Name
                                             </label>
                                          </div>
                                          <div className=" col-8">
                                             <p>
                                                {this.state.firstName ?? "--"}
                                             </p>
                                          </div>

                                          <div className="  col-4">
                                             <label
                                                htmlFor="exampleFormControlInput1 "
                                                className="fw-bolder  form-label mb-0"
                                             >
                                                Last Name
                                             </label>
                                          </div>
                                          <div className=" col-8">
                                             <p>
                                                {this.state.lastName ?? "--"}
                                             </p>
                                          </div>

                                          <div className=" col-4">
                                             <label
                                                htmlFor="exampleFormControlInput1 "
                                                className="fw-bolder  form-label mb-0"
                                             >
                                                Email
                                             </label>
                                          </div>
                                          <div className=" col-8">
                                             <p>{this.state.email ?? "--"}</p>
                                          </div>

                                          <div className=" col-4">
                                             <label
                                                htmlFor="exampleFormControlInput1 "
                                                className="fw-bolder  form-label mb-0"
                                             >
                                                Gender
                                             </label>
                                          </div>
                                          <div className=" col-8">
                                             <p>{this.state.gender ?? "--"}</p>
                                          </div>

                                          <div className=" col-4">
                                             <label
                                                htmlFor="exampleFormControlInput1 "
                                                className="fw-bolder  form-label mb-0"
                                             >
                                                Phone
                                             </label>
                                          </div>
                                          <div className=" col-8">
                                             <p>{this.state.phone ?? "--"}</p>
                                          </div>

                                          <div className=" col-4">
                                             <label
                                                htmlFor="exampleFormControlInput1 "
                                                className="fw-bolder  form-label mb-0"
                                             >
                                                Country
                                             </label>
                                          </div>
                                          <div className=" col-8">
                                             <p>
                                                {this.state.countryNameValue ??
                                                   "--"}
                                             </p>
                                          </div>
                                          <div className=" col-4">
                                             <label
                                                htmlFor="exampleFormControlInput1 "
                                                className="fw-bolder  form-label mb-0"
                                             >
                                                Number Of Plates
                                             </label>
                                          </div>

                                          <div className=" col-8">
                                             <p>
                                                {this.state?.parkingCount ??
                                                   "0"}
                                             </p>
                                          </div>
                                          <div className=" col-4 mt-1">
                                             <label
                                                htmlFor="exampleFormControlInput1 "
                                                className="fw-bolder  form-label mb-0"
                                             >
                                                Number Of Listings
                                             </label>
                                          </div>
                                          <div className=" col-8 mt-1">
                                             <p>
                                                {this.state?.listingCount ??
                                                   "0"}
                                             </p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="modal-footer">
                                    <button
                                       type="button"
                                       onClick={this.AllClear}
                                       className="btn btn-secondary"
                                       data-bs-dismiss="modal"
                                    >
                                       Close
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>

                        {/* //////////////edit mdel */}
                        <div
                           className="modal fade"
                           id="staticBackdropTwo"
                           data-bs-backdrop="static"
                           data-bs-keyboard="false"
                           tabIndex={-1}
                           aria-labelledby="staticBackdropLabel"
                           aria-hidden="true"
                        >
                           <div className="modal-dialog">
                              <div className="modal-content text-start">
                                 <div className="modal-header">
                                    <h5
                                       className="modal-title"
                                       id="staticBackdropLabel"
                                    >
                                       Edit User
                                    </h5>
                                    <button
                                       type="button"
                                       onClick={this.AllClear}
                                       className="btn-close"
                                       data-bs-dismiss="modal"
                                       aria-label="Close"
                                    />
                                 </div>
                                 <div className="modal-body">
                                    <div className="d-flex justify-content-around text-dark">
                                       <div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1 "
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             First Name
                                          </label>
                                          <input
                                             type="text"
                                             className="form-control form-control-solid "
                                             placeholder="John"
                                             value={this.state.firstName}
                                             onChange={(e) =>
                                                this.setState({
                                                   firstName: e.target.value,
                                                   firstNameVal: false,
                                                })
                                             }
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroupEdit(e)
                                             }
                                          />
                                          {this.state.firstNameVal ? (
                                             <div className="modelValidation">
                                                Enter first name
                                             </div>
                                          ) : null}
                                       </div>
                                       <div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1"
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             Last Name
                                          </label>
                                          <input
                                             type="text"
                                             className="form-control form-control-solid "
                                             placeholder="Doe"
                                             value={this.state.lastName}
                                             onChange={(e) =>
                                                this.setState({
                                                   lastName: e.target.value,
                                                   lastNameVal: false,
                                                })
                                             }
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroupEdit(e)
                                             }
                                          />
                                          {this.state.lastNameVal ? (
                                             <div className="modelValidation">
                                                Enter last name
                                             </div>
                                          ) : null}
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-around mt-4 text-dark">
                                       <div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1"
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             Email
                                          </label>
                                          <input
                                             type="email"
                                             className="form-control form-control-solid "
                                             placeholder="jhondoe@email.com"
                                             value={this.state.email}
                                             onChange={this.EmailFunction}
                                             // onChange={(e) => this.setState({ email: e.target.value, emailVal: false })}
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroupEdit(e)
                                             }
                                          />
                                          {this.state.emailVal ? (
                                             <div className="modelValidation">
                                                Invalid email
                                             </div>
                                          ) : null}
                                       </div>
                                       <div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1"
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             Gender
                                          </label>
                                          <select
                                             class="form-select"
                                             aria-label="Default select example"
                                             value={this.state.genderValue}
                                             onChange={(e) =>
                                                this.setState({
                                                   genderValue: e.target.value,
                                                   genderVal: false,
                                                })
                                             }
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroupEdit(e)
                                             }
                                          >
                                             {this.state.genderValue != "" &&
                                             this.state.genderValue !=
                                                undefined ? (
                                                <option hidden>
                                                   {this.state.genderValue}
                                                </option>
                                             ) : (
                                                <option hidden>
                                                   select Gender
                                                </option>
                                             )}

                                             {this.gender.map((item, i) => (
                                                <option
                                                   key={i}
                                                   value={item.value}
                                                >
                                                   {item.value}
                                                </option>
                                             ))}
                                          </select>
                                          {this.state.genderVal ? (
                                             <div className="modelValidation">
                                                Select gender
                                             </div>
                                          ) : null}
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-start mt-4 text-dark text-start">
                                       <div div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1"
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             Phone
                                          </label>
                                          <PhoneInput
                                             international
                                             className=" PhoneInputInput"
                                             initialValueFormat="international"
                                             countryCallingCodeEditable={true}
                                             // defaultCountry="AU"
                                             onEnterKeyPress={(e) => {
                                                this.onKeyPressAddGroupEdit(e);
                                             }}
                                             limitMaxLength={7}
                                             // country={"au"}
                                             value={this.state.phone}
                                             onChange={(e) =>
                                                this.phoneValidation(e)
                                             }
                                          />

                                          {this.state.phoneVal ? (
                                             <div className="modelValidation">
                                                Invalid number
                                             </div>
                                          ) : null}
                                       </div>
                                       <div className="w-50 mx-2">
                                          <label
                                             htmlFor="exampleFormControlInput1"
                                             className="fw-bolder required form-label mb-0"
                                          >
                                             Status
                                          </label>
                                          <select
                                             class="form-select"
                                             aria-label="Default select example"
                                             value={this.state.status}
                                             onChange={(e) =>
                                                this.setState({
                                                   status: e.target.value,
                                                   statusVal: false,
                                                })
                                             }
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroup(e)
                                             }
                                          >
                                             <option hidden>
                                                Select status
                                             </option>
                                             <option value="active">
                                                Active
                                             </option>
                                             <option value="deactive">
                                                Deactive
                                             </option>
                                          </select>
                                          {this.state.statusVal ? (
                                             <div className="modelValidation">
                                                Select status
                                             </div>
                                          ) : null}
                                       </div>
                                    </div>
                                 </div>

                                 <div className="mx-4">
                                    <label
                                       htmlFor="exampleFormControlInput1"
                                       className="fw-bolder required form-label mb-0"
                                    >
                                       Country
                                    </label>
                                    <Select
                                       class="form-select"
                                       placeholder={this.state.countryNameValue}
                                       value={this.state.selectedCountry}
                                       onChange={(e) =>
                                          this.editHandleSelectedCountry(e)
                                       }
                                       options={this.state.countryName}
                                       aria-label="Default select example"
                                       // onChange={(e) => this.setState({ countryID: e.value, countryIDVal: false })}
                                    />
                                    {this.state.countryIDVal ? (
                                       <div className="modelValidation">
                                          Select country
                                       </div>
                                    ) : null}
                                 </div>
                                 <div className="modal-footer">
                                    <button
                                       type="button"
                                       onClick={this.AllClear}
                                       className="btn btn-secondary"
                                       data-bs-dismiss="modal"
                                    >
                                       Close
                                    </button>

                                    {this.state.firstName != "" &&
                                    this.state.firstName != undefined &&
                                    this.state.firstName != null &&
                                    this.state.lastName != "" &&
                                    this.state.lastName != undefined &&
                                    this.state.lastName != null &&
                                    this.state.emailValidation == true &&
                                    this.state.email != "" &&
                                    this.state.email != undefined &&
                                    this.state.email != null &&
                                    // this.state.email.endsWith(".com") &&
                                    // this.state.email.includes("@") &&
                                    // this.state.genderValue != "" &&
                                    this.state.genderValue != undefined &&
                                    this.state.phone != "" &&
                                    this.state.phone != undefined &&
                                    this.state.phone != null &&
                                    // this.state.phone.length > 10 &&
                                    this.state.statusValue != "" &&
                                    this.state.statusValue != null &&
                                    this.state.statusValue != undefined ? (
                                       <button
                                          type="button"
                                          style={{
                                             background:
                                                "linear-gradient(#3E94A6, #323F4B)",
                                          }}
                                          className="btn btn-primary"
                                          data-bs-dismiss="modal"
                                          onClick={() => this.updateUser()}
                                          ref={(node) => (this.btnOne = node)}
                                       >
                                          Update
                                       </button>
                                    ) : (
                                       <button
                                          type="button"
                                          style={{
                                             background:
                                                "linear-gradient(#3E94A6, #323F4B)",
                                          }}
                                          className="btn btn-primary"
                                          onClick={() => this.updateUser()}
                                          ref={(node) => (this.btnOne = node)}
                                       >
                                          Update
                                       </button>
                                    )}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center text-bold"
                        style={{
                           height: "50vh",
                        }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </div>
            </Container>
         </div>
      );
   }
}

export default AllUsers;
