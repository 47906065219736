import React, { useEffect, useState } from "react";
// import React, { useEffect,  } from "react";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Row, Col, Button, Container, Label } from "reactstrap";

// Redux
// import { connect } from "react-redux";
// import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";

// actions
// import { checkLogin, apiError } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { useHistory } from "react-router-dom";
import {
   WemblayRegister,
   // GetCountrires,
} from "../../components/Services/AuthServices";
const Login = () => {
   let history = useHistory();
   const [phone, setPhone] = useState();
   const [btnLoader, setLoader] = useState(false);
   const [redirect, setRedirect] = useState(false);
   // this.state = { username: "admin@themesdesign.in", password: "123456" };

   const checkAuth = async () => {
      let token = localStorage.getItem("Token");
      if (token) {
         setRedirect(false);
         history.push({
            pathname: "/users-list",
         });
      } else {
         setRedirect(true);
      }
   };

   useEffect(() => {
      checkAuth();
   });

   // const [contries, setCountries] = useState([]);
   const onChangeHandler = (e) => {
      setPhone(e);
   };

   const LoginForm = async (e) => {
      setLoader(true);

      try {
         const response = await WemblayRegister(phone);

         if (response.status == true) {
            history.push({
               pathname: "/login-otp",
               state: { phone: phone },
            });
            localStorage.setItem("number", JSON.stringify(phone));
         } else {
            console.log("status", response);
            Swal.fire({
               position: "top",
               icon: "warning",
               title: response.message,
               showConfirmButton: false,
               timer: 3000,
            });
            setLoader(false);
         }
      } catch (error) {
         setLoader(false);
      }
   };
   const onKeyPressAddGroup = (e) => {
      console.log("asd");
      if (e.which === 13) {
         LoginForm();
      }
   };

   return (
      <React.Fragment>
         {redirect && (
            <div>
               <Container fluid className="p-0">
                  <Row className="g-0">
                     <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                           <div className="w-100">
                              <Row className="justify-content-center">
                                 <Col lg={9}>
                                    <div>
                                       <div className="text-center">
                                          <div>
                                             {/* <Link to="#" class=""> */}
                                             <img
                                                src={logodark}
                                                alt=""
                                                height="190"
                                                class="auth-logo logo-dark mx-auto"
                                             />

                                             {/* </Link> */}
                                          </div>
                                          {/* 
                                                        <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                                        <p className="text-muted">Sign in to continue to Nazox.</p> */}
                                       </div>

                                       {/* {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null} */}

                                       <div className="p-2 mt-5">
                                          <AvForm
                                             className="form-horizontal"
                                             //   onValidSubmit={this.handleSubmit}
                                          >
                                             {/* <div className="auth-form-group-custom"> */}
                                             {/* <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                                <AvField name="username" value={this.state.username} type="text" className="form-control" id="username" validate={{ email: true, required: true }} placeholder="Enter phone Number" /> */}
                                             <Label htmlFor="phone">
                                                Phone Number
                                             </Label>

                                             <PhoneInput
                                                international
                                                initialValueFormat="international"
                                                countryCallingCodeEditable={
                                                   true
                                                }
                                                defaultCountry="AU"
                                                onEnterKeyPress={(e) => {
                                                   onKeyPressAddGroup(e);
                                                }}
                                                limitMaxLength={7}
                                                // country={"au"}
                                                value={phone}
                                                onChange={(e) =>
                                                   onChangeHandler(e)
                                                }
                                             />
                                             {/* <PhoneInput
                              // ref={phoneInputRef}
                              // onEnterKeyPress={}
                              onEnterKeyPress={(e) => {
                                onKeyPressAddGroup(e);
                              }}
                              country={"au"}
                              value={phone}
                              onChange={(e) => onChangeHandler(e)}
                            /> */}

                                             {btnLoader == false && (
                                                <div className="mt-4 text-center">
                                                   <Button
                                                      style={{
                                                         background:
                                                            "linear-gradient(#3E94A6, #323F4B)",
                                                      }}
                                                      className="w-md btn btn-primary waves-effect waves-light"
                                                      type="submit"
                                                      onClick={(e) =>
                                                         LoginForm(e)
                                                      }
                                                   >
                                                      Submit
                                                   </Button>
                                                </div>
                                             )}
                                             {btnLoader == true && (
                                                <div className="mt-4 text-center">
                                                   <button
                                                      style={{
                                                         background:
                                                            "linear-gradient(#3E94A6, #323F4B)",
                                                      }}
                                                      className="w-md btn btn-primary waves-effect waves-light"
                                                      type="submit"
                                                      onClick={(e) =>
                                                         LoginForm(e)
                                                      }
                                                      disabled
                                                   >
                                                      <div
                                                         class="spinner-border"
                                                         role="status"
                                                      >
                                                         <span class="sr-only"></span>
                                                      </div>
                                                   </button>
                                                </div>
                                             )}

                                             {/* <div className="mt-4 text-center">
                                                                <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                                            </div> */}
                                          </AvForm>
                                       </div>

                                       {/* <div className="mt-5 text-center">
                                                        <p>Don't have an account ? <Link to="/register" className="fw-medium text-primary"> Register </Link> </p>
                                                    </div> */}
                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        </div>
                     </Col>
                     <Col lg={8}>
                        <div className="authentication-bg">
                           <div className="bg-overlay"></div>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </div>
         )}
      </React.Fragment>
   );
};
export default Login;
// const mapStatetoProps = (state) => {
//   const { loginError } = state.Login;
//   return { loginError };
// };

// export default withRouter(
//   connect(mapStatetoProps, { checkLogin, apiError })(Login),
// );
