import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

// import { getReasons, AddReports, DeleteReasons, EditReason, getReportType } from "../Services/UserServices/Index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import ProfileImg from "../../assets/images/profile/images.jpg";
import Img from "../../assets/images/small/img-2.jpg";
import { Player } from "video-react";
import "video-react/dist/video-react.css"; // import css
// import { getSocialGroups } from "../../Services/SocialGroups/index";
import { getPaymentDetail } from "../Services/PaymentDetail/index";
import "./PaymentDeatail.css";
import Pagination from "../VerticalLayout/pagination/Pagination";
class PaymentDeatail extends Component {
   constructor(props) {
      super(props);

      // this.setEditReason = this.setEditReason.bind(this);
      // this.setAddReasons = this.setAddReasons.bind(this);
      // this.setInitialValue = this.setInitialValue.bind(this);

      this.state = {
         firstName: "",
         allReports: [],
         allPaymentDetail: [],
         loading: false,
         slectetdReportType: "",
         slectetdReportID: "",
         slectetdReportValue: "",
         // reportVal:false,
         reportTitle: "",
         // reportTitleVal:false,
         slectetdReasonId: "",
         reasonTitle: "",
         editplateID: null,
         slectetdIDReason: "",
         slectetdIDReasons: "",
         dateChangerStart: [],
         dateChangerEnd: [],

         pageIDs: [],

         reasonTitleVal: false,
         slectetdReasonIdVal: false,
      };
   }

   async componentDidMount() {
      this.getAllInvoice();
   }

   async getAllInvoice(page) {
      let IDArray = [];
      try {
         this.setState({ loading: true });
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         const result = await getPaymentDetail(accessToken, page);
         console.log(result.payment.data, "getInvoicesgetInvoices");
         if (result.status === true) {
            this.setState({ loading: false });
            this.setState({ allPaymentDetail: result?.payment?.data });
            this.setState({ currentPage: result?.payment?.current_page });
            this.setState({ lastPage: result?.payment?.last_page });
            for (let i = result?.payment?.from; i <= result?.payment?.to; i++) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
         } else {
            console.log("sorry");
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllInvoice(page);
   };
   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };

   dateConveter = (date) => {
      let dte = new Date(date);
      return dte;
   };

   render() {
      return (
         <>
            <div className="page-content">
               <h5 className="fs-5 mb-3 fw-bold">User Payment Detail </h5>
               {this.state.loading == false ? (
                  <div>
                     <Card className="p-4">
                        <div className="overflow-auto">
                           <table class="table table-responsive">
                              <thead>
                                 <tr>
                                    <th scope="col">No.</th>
                                    <th scope="col">User </th>
                                    <th scope="col"> Card Holder Name</th>
                                    <th scope="col">Card Number</th>

                                    <th scope="col">Ex-Date</th>
                                    <th scope="col">Created-At</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.allPaymentDetail.map(
                                    (item, outer) => (
                                       <>
                                          {/* {console.log("iteeeem", item)} */}
                                          <tr key={outer}>
                                             {this.state.pageIDs?.map(
                                                (item, inner) =>
                                                   outer == inner ? (
                                                      <td>{item.id}</td>
                                                   ) : null
                                             )}

                                             <td>
                                                <span>
                                                   {" "}
                                                   {item?.user?.first_name ==
                                                   undefined
                                                      ? "Anonymous"
                                                      : item?.user?.first_name +
                                                        " " +
                                                        item?.user?.last_name}
                                                </span>
                                             </td>
                                             <td>
                                                <span>
                                                   {" "}
                                                   {item?.card_holder_name ??
                                                      "Anonymous"}
                                                </span>
                                                {/* <small>{item.customer_email}</small> */}
                                             </td>
                                             <td>
                                                <span class="">
                                                   {" "}
                                                   {item?.last_four_digit ??
                                                      "Anonymous"}
                                                </span>
                                             </td>
                                             <td>
                                                <span class="">
                                                   {" "}
                                                   {this.getDate(
                                                      item?.expire_at
                                                   )}
                                                </span>
                                             </td>
                                             <td>
                                                <span class="">
                                                   {" "}
                                                   {this.getDate(
                                                      item?.created_at
                                                   )}
                                                </span>
                                             </td>
                                             <td>
                                                <span class="">
                                                   {" "}
                                                   {item?.zip ??
                                                      "Anonymous"}{" "}
                                                </span>
                                             </td>

                                             {/* <td>{this.dateConveter(item.period_start)}</td> */}
                                             {/* <td>{this.dateConveter(item.period_end)}</td> */}
                                             <td className="">
                                                {item.status == "paid" ? (
                                                   <span class="badge bg-success text-white">
                                                      {item.status}
                                                   </span>
                                                ) : (
                                                   <span class="badge bg-light text-dark">
                                                      {item.status}
                                                   </span>
                                                )}
                                             </td>
                                          </tr>
                                       </>
                                    )
                                 )}
                              </tbody>
                           </table>
                        </div>
                        {this.state.lastPage > 1 && (
                           <Pagination
                              pageCount={this.state.lastPage}
                              paginate={this.paginate}
                           />
                        )}
                     </Card>
                  </div>
               ) : (
                  <div
                     className="text-center mt-5 d-flex align-items-center justify-content-center"
                     style={{
                        height: "50vh",
                     }}
                  >
                     <div class="spinner-border text-enter" role="status">
                        <span class="visually-hidden"></span>
                     </div>
                  </div>
               )}
            </div>
            {/* /////////////////////////////Edit model */}
            <div
               className="modal fade"
               id="staticBackdropTwo"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Chow Club
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <PerfectScrollbar style={{ maxHeight: "450px" }}>
                           <div className=" mb-3">
                              <li style={{ listStyle: "none" }}>
                                 <Link to="#">
                                    <div className="d-flex">
                                       <div>
                                          <img
                                             src={ProfileImg}
                                             className="rounded-circle avatar-xs"
                                          />
                                       </div>

                                       <div className="flex-1 overflow-hidden">
                                          <p className="text-truncate px-2">
                                             James
                                          </p>
                                       </div>

                                       <div className="font-size-11">
                                          01/21/2022
                                       </div>
                                    </div>
                                    <div>
                                       <p>
                                          In publishing and graphic design,
                                          Lorem ipsum is a placeholder text
                                          commonly used to demonstrate the
                                          visual form of a document or a
                                          typeface without relying on meaningful
                                          content. Lorem ipsum may be used as a
                                          placeholder before final copy is
                                          available.
                                       </p>
                                    </div>
                                 </Link>
                              </li>
                           </div>
                           <div className=" mb-3">
                              <li style={{ listStyle: "none" }}>
                                 <Link to="#">
                                    <div className="d-flex">
                                       <div>
                                          <img
                                             src={ProfileImg}
                                             className="rounded-circle avatar-xs"
                                          />
                                       </div>

                                       <div className="flex-1 overflow-hidden">
                                          <p className="text-truncate px-2">
                                             Charlotte
                                          </p>
                                       </div>

                                       <div className="font-size-11">
                                          01/21/2022
                                       </div>
                                    </div>
                                    <div>
                                       <Player
                                          playsInline
                                          src="https://www.youtube.com/watch?v=4VaqA-5aQTM"
                                          width="auto"
                                          height="200px"
                                       />
                                       {/* <ReactPlayer
                            width="auto"
                            height="200px"
                            // url={message?.conversation_media[0]?.path}
                            url="https://www.youtube.com/watch?v=4VaqA-5aQTM"
                            playing={false}
                            controls={true}
                            loop={false}
                            muted={false}
                            playsinline={true}
                            // onReady={onLoadedData}
                          /> */}
                                    </div>
                                 </Link>
                              </li>
                           </div>

                           <div className=" mb-3">
                              <li style={{ listStyle: "none" }}>
                                 <Link to="#">
                                    <div className="d-flex">
                                       <div>
                                          <img
                                             src={ProfileImg}
                                             className="rounded-circle avatar-xs"
                                          />
                                       </div>

                                       <div className="flex-1 overflow-hidden">
                                          <p className="text-truncate px-2">
                                             Amelia
                                          </p>
                                       </div>

                                       <div className="font-size-11">
                                          01/21/2022
                                       </div>
                                    </div>
                                    <div>
                                       <img
                                          src={Img}
                                          width="100%"
                                          height="200"
                                       />
                                    </div>
                                 </Link>
                              </li>
                           </div>
                        </PerfectScrollbar>
                     </div>

                     <div className="modal-footer ">
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default PaymentDeatail;
