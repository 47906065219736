import { BaseURL } from "../BaseURL";

export const getAllChats = async (accessToken) => {
  const response = await fetch(`${BaseURL}/admin/chats`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};

export const getAllConversation = async (accessToken, id) => {
  const response = await fetch(`${BaseURL}/admin/chat-view/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer" + "  " + accessToken,
      "Access-Control-Allow-Origin": "*",
    },
  });
  const result = await response.json();
  return result;
};
