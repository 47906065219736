import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import profile from "../../assets/images/profile_image.jpg";
// import { getAllAdminProfiles } from "../Services/UserServices/Index";
import {
   getAllAdminProfiles,
   EditAdminProfiles,
} from "../Services/AdminProfile/index";
import "./AdminProfile.css";
// import PhoneInput from "react-phone-input-2";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import pencil from "../../assets/icons/Pencil-01.svg";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import ProfileImg from "../../assets/images/profile/images.jpg";
import Img from "../../assets/images/small/img-2.jpg";

// import { getSocialGroups } from "../../Services/SocialGroups/index";
import { getAllInvoices } from "../Services/UserServices/Index";
import "../../components/Invoices/Invoices.css";

class AdminProfile extends Component {
   constructor(props) {
      super(props);

      this.getAllAdminProfile = this.getAllAdminProfile.bind(this);
      // this.setAddReasons = this.setAddReasons.bind(this);
      // this.setInitialValue = this.setInitialValue.bind(this);

      this.state = {
         allAdminProfile: [],
         loading: false,

         firstName: "",
         lastName: "",
         email: "",
         phone: "",
         gender: "",
         editId: "",

         emailVal: false,
         phoneVal: false,
         firstNameVal: false,
         lastNameVal: false,
         emailValidation: true,

         showEditProfileImage: "",
         profileImage: "",
         editProfileImage: "",
         showProfileImage: "",
         addProfile: "",
      };
   }

   handleChange = (e, inputField) => {
      console.log("e.target.files[0]", e.target.files[0]);

      if (inputField == "Elogo") {
         this.setState({ editProfileImage: e.target.files[0] });
         if (e.target.files.length !== 0) {
            this.setState({
               showEditProfileImage: URL.createObjectURL(e.target.files[0]),
            });
         } else if (e.target.files.length === 0) {
            this.setState({ showEditProfileImage: "" });
         }
      }
   };

   async componentDidMount() {
      this.getAllAdminProfile();
      // this.getAllRoports();
   }
   EmailFunction = (e) => {
      // const regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      const regex =
         /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)+@[a-z]{4,9}[\.][a-z]{2,5}/g;
      // const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{3,9}\.[0-9]{3,9}\.[0-9]{3,9}\.[0-9]{3,6}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
      if (regex.test(e.target.value) == true) {
         this.setState({ emailValidation: true });
         this.setState({ email: e.target.value, emailVal: false });
      } else {
         this.setState({ emailValidation: false });
         this.setState({ email: e.target.value, emailVal: false });
      }
   };
   phoneValidation = (e) => {
      this.setState({ phoneVal: false });

      // if (e.target.value.length > e.target.maxLength) {
      //   e.target.value = e.target.value.slice(0, e.target.maxLength);
      // }
      this.setState({ phone: e });

      // setZipCode(e.target.value);
      // setZipCodeForm(false)
   };

   async getAllAdminProfile() {
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         const result = await getAllAdminProfiles(accessToken, 1);
         // console.log(result.user?.profile_image, "getAllgetAllInvoiceeeeeeeeeee");
         if (result.status === true) {
            this.setState({ loading: true });
            this.setState({
               firstName: result.user?.first_name,
               lastName: result.user?.last_name,
               email: result.user?.email,
               phone: result.user?.phone,
               editId: result.user?.id,

               //   gender: result.user?.gender,
               showEditProfileImage: `${result.user?.profile_image?.folder_path}${result.user?.profile_image?.file_name}`,
            });
            // this.setState({ allAdminProfile: result.user });
         } else {
            console.log("sorry");
         }
         console.log(
            this.state.showEditProfileImage,
            "this.state.showEditProfileImage"
         );
      } catch (err) {
         console.log("error ", err);
      }
   }

   async updateProfile() {
      if (this.state.firstName == "" || this.state.firstName == undefined) {
         this.setState({ firstNameVal: true });
      }
      if (this.state.lastName == "" || this.state.lastName == undefined) {
         this.setState({ lastNameVal: true });
      }
      if (this.state.emailValidation == false) {
         this.setState({ emailVal: true });
      }

      if (
         this.state.phone == "" ||
         this.state.phone == undefined ||
         this.state.phone.length < 11
      ) {
         this.setState({ phoneVal: true });
      }

      if (
         this.state.firstName != "" &&
         this.state.firstName != undefined &&
         this.state.lastName != "" &&
         this.state.lastName != undefined &&
         this.state.emailValidation == true &&
         this.state.phone != "" &&
         this.state.phone != undefined &&
         this.state.phone.length > 10
      ) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await EditAdminProfiles(
               accessToken,
               this.state.firstName,
               this.state.lastName,
               this.state.email,
               this.state.phone,

               this.state.editProfileImage
            );
            // console.log("this.state.showEditProfileImage", this.state.showEditProfileImage);
            if (response.status == true) {
               //   console.log("responseeeeeeee", response);
               this.getAllAdminProfile();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "User updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  firstName: "",
                  lastName: "",
                  email: "",
                  phone: "",
                  gender: "",
                  status: "",
                  editUserID: "",
                  countryID: "",
               });
            }
            // console.log("response ", response);
         } catch (err) {
            console.log(err);
         }
      }
   }
   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };

   render() {
      return (
         <>
            <div className="page-content">
               {this.state.loading ? (
                  <div className="container-fluid">
                     <div className="profile_section section-border  p-5 rounded border ">
                        <div className="UserInfo pro-width m-auto rounded  bg-white border shadow text-center position-relative">
                           <center>
                              {this.state.showEditProfileImage ==
                              "undefinedundefined" ? (
                                 <img
                                    className="img-fluid AdminProfile   rounded rounded-circle position-absolute top-0 start-50 translate-middle  me-3"
                                    src={profile}
                                    alt=""
                                 />
                              ) : (
                                 <img
                                    className="img-fluid AdminProfile   rounded rounded-circle position-absolute top-0 start-50 translate-middle  me-3"
                                    src={this.state.showEditProfileImage}
                                    alt=""
                                 />
                              )}
                           </center>

                           <br />
                           <br />
                           <br />
                           <div className="UserDetail text-start p-2">
                              <div className="row ">
                                 <div className="col-2"></div>
                                 <div className="col-4">First Name</div>
                                 <div className="col-4">
                                    {" "}
                                    {this.state.firstName}
                                 </div>
                                 <div className="col-2"></div>

                                 <br />
                                 <br />

                                 <div className="col-2"></div>
                                 <div className="col-4">Last Name</div>
                                 <div className="col-4">
                                    {this.state.lastName}
                                 </div>
                                 <div className="col-2"></div>
                                 <br />
                                 <br />
                                 <div className="col-2"></div>
                                 <div className="col-4">Email</div>
                                 <div className="col-4">{this.state.email}</div>
                                 <div className="col-2"></div>
                                 <br />
                                 <br />
                                 <div className="col-2"></div>
                                 <div className="col-4">Phone Number</div>
                                 <div className="col-4">{this.state.phone}</div>
                                 <div className="col-2"></div>
                                 <div className="col-7 col-md-9 col-lg-9 mt-2"></div>
                                 <div className="col-3">
                                    <Link to="#" className="text-primary ms-4">
                                       <button
                                          className="btn text-white"
                                          style={{
                                             background:
                                                "linear-gradient(#3E94A6, #323F4B)",
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdropTwo "
                                          //   onClick={() => this.setInitialValue(item.id, item.first_name, item.last_name, item.email, item.phone, item.gender, item.status, item?.country?.name)}
                                       >
                                          Edit Profile
                                       </button>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               ) : (
                  <div
                     className="text-center d-flex align-items-center justify-content-center text-bold"
                     style={{ height: "50vh" }}
                  >
                     <div class="spinner-border text-enter" role="status">
                        <span class="visually-hidden"></span>
                     </div>
                  </div>
               )}
            </div>
            {/* /////////////////////////////Edit model */}
            <div
               className="modal fade"
               id="staticBackdropTwo"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Edit Profile
                        </h5>
                        <button
                           type="button"
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <div className=" d-flex flex-column justify-content-center">
                           <div className="mt-3  row">
                              <div className="m-auto pe-2 pt-3 col-4">
                                 <div
                                    className=" image-input-outline"
                                    data-kt-image-input="true"
                                 >
                                    <div
                                       className="image-input-wrapper  d-flex"
                                       style={{
                                          width: "125px",
                                          height: "125px",
                                       }}
                                    >
                                       {this.state.showEditProfileImage ==
                                       "undefinedundefined" ? (
                                          <img
                                             className="img-fluid modal_image  "
                                             src={profile}
                                             alt=""
                                          />
                                       ) : (
                                          <img
                                             src={
                                                this.state.showEditProfileImage
                                             }
                                             className="img-fluid modal_image w-100 h-100 "
                                             alt=""
                                             style={{
                                                maxHeight: "125px",
                                                maxWidth: "125px",
                                             }}
                                          />
                                       )}
                                    </div>
                                 </div>
                                 <label
                                    for="avatar"
                                    style={{
                                       overflow: "hidden",
                                       cursor: "pointer",
                                    }}
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    title="Change avatar"
                                 >
                                    <i
                                       title="Add"
                                       style={{
                                          position: "absolute",
                                          left: "128px",
                                          top: "-3px",
                                          zIndex: 111,
                                       }}
                                    >
                                       <img
                                          src={pencil}
                                          width={30}
                                          height={25}
                                          alt=""
                                          className="d-inline cursor-pointer"
                                       />
                                    </i>
                                    <input
                                       type="file"
                                       name="avatar"
                                       accept=".jpg, .jpeg, .png, .svg, .gif, .webp, .mp4, .mepg4, .avi, .wmv, .mkv, .webm, .flv"
                                       onChange={(e) =>
                                          this.handleChange(e, "Elogo")
                                       }
                                       style={{
                                          opacity: 0,
                                          left: "122px",
                                          top: "-28px",
                                          position: "absolute",
                                          zIndex: 111,
                                          cursor: "pointer",
                                          width: "30px",
                                          height: "60px",
                                       }}
                                    />
                                    <input type="hidden" />
                                 </label>
                              </div>
                           </div>

                           <div className="d-flex justify-content-around text-dark">
                              <div className="w-50 mx-2">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    First Name
                                 </label>

                                 <input
                                    type="text"
                                    className="form-control form-control-solid "
                                    placeholder="First Name"
                                    value={this.state.firstName}
                                    onChange={(e) =>
                                       this.setState({
                                          firstName: e.target.value,
                                          firstNameVal: false,
                                       })
                                    }
                                    //   onKeyPress={(e) => this.onKeyPressAddGroupEdit(e)}
                                 />
                                 {this.state.firstNameVal ? (
                                    <div className="modelValidation">
                                       Enter first name
                                    </div>
                                 ) : null}
                              </div>
                              <div className="w-50 mx-2">
                                 <label
                                    htmlFor="exampleFormControlInput1"
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Last Name
                                 </label>

                                 <input
                                    type="text"
                                    className="form-control form-control-solid "
                                    placeholder="Last Name"
                                    value={this.state.lastName}
                                    onChange={(e) =>
                                       this.setState({
                                          lastName: e.target.value,
                                          lastNameVal: false,
                                       })
                                    }
                                    //   onKeyPress={(e) => this.onKeyPressAddGroupEdit(e)}
                                 />
                                 {this.state.lastNameVal ? (
                                    <div className="modelValidation">
                                       Enter last name
                                    </div>
                                 ) : null}
                              </div>
                           </div>
                           <div className="d-flex justify-content-around mt-4 text-dark">
                              <div className="w-50 mx-2">
                                 <label
                                    htmlFor="exampleFormControlInput1"
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Email
                                 </label>

                                 <input
                                    type="email"
                                    className="form-control form-control-solid "
                                    placeholder="Email"
                                    value={this.state.email}
                                    onChange={this.EmailFunction}
                                    //   onChange={(e) => this.setState({ email: e.target.value, emailVal: false })}
                                    //   onKeyPress={(e) => this.onKeyPressAddGroupEdit(e)}
                                 />
                                 {this.state.emailVal ? (
                                    <div className="modelValidation">
                                       Invalid email
                                    </div>
                                 ) : null}
                              </div>

                              <div div className="w-50 mx-2">
                                 <label
                                    htmlFor="exampleFormControlInput1"
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Phone
                                 </label>

                                 <PhoneInput
                                    international
                                    className=" PhoneInputInput"
                                    initialValueFormat="international"
                                    countryCallingCodeEditable={true}
                                    defaultCountry="AU"
                                    onEnterKeyPress={(e) => {
                                       this.onKeyPressAddGroup(e);
                                    }}
                                    limitMaxLength={7}
                                    // country={"au"}
                                    value={this.state.phone}
                                    onChange={(e) => this.phoneValidation(e)}
                                 />
                                 {/* </div> */}
                                 {this.state.phoneVal ? (
                                    <div className="modelValidation">
                                       Invalid number
                                    </div>
                                 ) : null}
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="modal-footer">
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>

                        {this.state.firstName != "" &&
                        this.state.firstName != undefined &&
                        this.state.lastName != "" &&
                        this.state.lastName != undefined &&
                        this.state.emailValidation == true &&
                        this.state.phone != "" &&
                        this.state.phone != undefined &&
                        this.state.phone.length > 10 ? (
                           <button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                              onClick={() => this.updateProfile()}
                              ref={(node) => (this.btnOne = node)}
                           >
                              Save
                           </button>
                        ) : (
                           <button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              onClick={() => this.updateProfile()}
                              ref={(node) => (this.btnOne = node)}
                           >
                              Save
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default AdminProfile;
