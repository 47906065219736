import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
   getPaymentMethods,
   ActivePaymentMethod,
   DeActivePaymentMethod,
   AddPaymentMethod,
   EditLiveBoxFunction,
   EditSandBoxFunction,
} from "../../Services/PaymentMethods/index";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Swal from "sweetalert2";
import Pagination from "../../VerticalLayout/pagination/Pagination";
import moment from "moment";

import image_userD from "../../../assets/PaymentMethodIcon/payment_deactive.svg";
import image_userA from "../../../assets/PaymentMethodIcon/payment_active.svg";
class PaymentMethod extends Component {
   constructor(props) {
      super(props);

      this.getAllPaymentMethod = this.getAllPaymentMethod.bind(this);
      // this.ClearAllField = this.ClearAllField.bind(this);
      this.ActivePaymentMethodID = this.ActivePaymentMethodID.bind(this);
      this.DeActivePaymentMethodID = this.DeActivePaymentMethodID.bind(this);
      this.SandBoxFunction = this.SandBoxFunction.bind(this);
      this.LiveBoxFunction = this.LiveBoxFunction.bind(this);
      this.state = {
         modelName: "",
         status: "",
         slug: "",
         modelNameVal: false,
         paymentMethod: [],
         models: [],
         vehicleModel: "",
         editmodelID: "",
         currentPage: "",
         lastPage: "",
         loading: false,
         nameVal: false,
         name: "",
         status: "",

         loadingModel: true,

         typeOfListing: "sandBox",
         publicKey: "",
         secreteKey: "",
         publicKeyLive: "",
         secreteKeyLive: "",
         publicKeyVal: false,
         secreteKeyVal: false,
         publicKeyLiveVal: false,
         secreteKeyLiveVal: false,

         editID: "",
         liveKey: "",
         liveSecret: "",
         testKey: "",
         testSecret: "",
         testMode: "",
      };
   }

   async componentDidMount() {
      this.getAllPaymentMethod();
   }

   async getAllPaymentMethod() {
      // let modelArray = [];
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const allPaymentsMethod = await getPaymentMethods(accessToken);
         if (allPaymentsMethod.status === true) {
            this.setState({ loading: true });
            this.setState({
               currentPage: allPaymentsMethod.payment?.current_page,
            });
            this.setState({ lastPage: allPaymentsMethod.payment?.last_page });
            this.setState({ paymentMethod: allPaymentsMethod.payment.data });
            //   this.state.carModels.map((mod, index) => {
            //   modelArray.push({
            //     id: mod.id,
            //     name: mod.name,
            //     slug: mod.slug,
            //     status: mod.status,
            //   });
            //   this.setState({ models: modelArray });
            // });
         }
      } catch (err) {
         console.log("error", err);
      }
   }
   async ActivePaymentMethodID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to activate this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await ActivePaymentMethod(accessToken, id);
            if (result.status === true) {
               this.getAllPaymentMethod();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: " Active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActivePaymentMethodID(id) {
      // console.log("dactive", id);
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to Deactive this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await DeActivePaymentMethod(accessToken, id);

            if (result.status === true) {
               this.getAllPaymentMethod();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };
   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllPaymentMethod(page);
   };

   async SavePaymentMethod() {
      this.setState({ loadingModel: false });
      if (this.state.status == "" || this.state.status == undefined) {
         this.setState({ nameVal: true });
      }
      // if (this.state.lastName == "" || this.state.lastName == undefined) {
      //   this.setState({ lastNameVal: true });
      // }

      if (
         this.state.name != "" &&
         this.state.name != undefined &&
         this.state.status != "" &&
         this.state.status != undefined
      ) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await AddPaymentMethod(
               accessToken,
               this.state.name,
               this.state.status
            );
            if (response.status == true) {
               this.setState({ isTrue: true });
               this.getAllPaymentMethod();
               this.setState({ loadingModel: true });

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: " added successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  name: "",
                  status: "",
               });
               // this.setState({ statusUpdate: true });
            } else {
               console.log("error", response);
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   async setInitialValue(
      id,
      live_id,
      live_secret,
      first_name,
      status,
      test_id,
      test_secret,
      test_mode
   ) {
      console.log(test_mode);
      if (test_mode == 1) {
         this.setState({
            typeOfListing: "sandBox",
         });
      } else if (test_mode == 0) {
         this.setState({
            typeOfListing: "live",
         });
      }
      this.setState({
         name: first_name,
         status: status,
         editID: id,
         liveKey: live_id,
         liveSecret: live_secret,
         testKey: test_id,
         testSecret: test_secret,
         testMode: test_mode,
      });
   }

   WhichTypeOfListingChange = async (e) => {
      if (e.target.id == "sandBox") {
         this.setState({
            typeOfListing: "sandBox",
         });
      } else if (e.target.id == "live") {
         this.setState({
            typeOfListing: "live",
         });
      }
   };

   async LiveBoxFunction() {
      if (this.state.liveKey == "" || this.state.liveKey == undefined) {
         this.setState({ publicKeyLiveVal: true });
      }
      if (this.state.liveSecret == "" || this.state.liveSecret == undefined) {
         this.setState({ secreteKeyLiveVal: true });
      }

      if (
         this.state.liveKey != "" &&
         this.state.liveKey != undefined &&
         this.state.liveSecret != "" &&
         this.state.liveSecret != undefined
      ) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await EditLiveBoxFunction(
               accessToken,
               this.state.liveKey,
               this.state.liveSecret,
               this.state.editID
            );
            if (response.status == true) {
               this.getAllPaymentMethod();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Updated Successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  modelName: "",
                  status: "",
                  editmodelID: "",
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   async SandBoxFunction() {
      if (this.state.testKey == "" || this.state.testKey == undefined) {
         this.setState({ publicKeyVal: true });
      }
      if (this.state.testSecret == "" || this.state.testSecret == undefined) {
         this.setState({ secreteKeyVal: true });
      }

      if (
         this.state.testKey != "" &&
         this.state.testKey != undefined &&
         this.state.testSecret != "" &&
         this.state.testSecret != undefined
      ) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await EditSandBoxFunction(
               accessToken,
               this.state.testKey,
               this.state.testSecret,
               this.state.editID
            );
            if (response.status == true) {
               this.getAllPaymentMethod();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: " Updated Successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  modelName: "",
                  status: "",
                  editmodelID: "",
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   render() {
      return (
         <>
            <div className="d-flex justify-content-between mt-3">
               <div>
                  {/* <div className="search-box "> */}
                  {/* <input
							type="text"
							class="form-control rounded-pill"
							placeholder="Search"
							aria-label="Search"
							aria-describedby="search-addon"
							onChange={(e) => this.SearchChangeHandler(e)}
						/> */}
                  {/* </div> */}
               </div>
               <div>
                  {/* <Button
              type="button"
              onClick={this.ClearAllField}
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropOne"
              style={{
                background: "linear-gradient(#3E94A6, #323F4B)",
              }}
              className="btn-rounded mb-2 me-2"
            >
              <i className="mdi mdi-plus me-1" /> Add Payment Method
            </Button> */}
               </div>
            </div>
            <Container className="px-0 overflow-auto">
               {this.state.loading ? (
                  <>
                     <Table className="table table-responsive">
                        <thead>
                           <tr>
                              <th scope="col">No.</th>
                              <th scope="col">Name</th>
                              <th scope="col">Status</th>
                              <th scope="col">Date</th>
                              <th scope="col">Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           {this.state.paymentMethod?.map((item, payId) => {
                              return (
                                 <>
                                    <tr key={payId}>
                                       <td>{item?.id} </td>
                                       <td>{item?.name} </td>
                                       <td>
                                          {item.status == "active" ? (
                                             <span
                                                className="badge bg-success "
                                                style={{
                                                   paddingLeft: 13,
                                                   paddingRight: 13,
                                                }}
                                             >
                                                Active
                                             </span>
                                          ) : item.status == "deactive" ? (
                                             <span className="badge bg-danger px-2">
                                                Deactive
                                             </span>
                                          ) : null}
                                       </td>
                                       <td>{this.getDate(item?.created_at)}</td>
                                       <td>
                                          <div className="d-flex">
                                             <Link
                                                to="#"
                                                className="text-primary"
                                             >
                                                <i
                                                   className="mdi mdi-pencil font-size-18 me-3"
                                                   data-bs-toggle="modal"
                                                   data-bs-target="#staticBackdropOne"
                                                   onClick={() =>
                                                      this.setInitialValue(
                                                         item.id,
                                                         item.live_id,
                                                         item.live_secret,
                                                         item.name,
                                                         item.status,
                                                         item.test_id,
                                                         item.test_secret,
                                                         item.test_mode
                                                      )
                                                   }
                                                   title="Edit"
                                                ></i>
                                             </Link>

                                             {item?.status == "active" ? (
                                                <Link
                                                   to="#"
                                                   className="text-danger"
                                                   title="Deactive"
                                                >
                                                   <img
                                                      src={image_userD}
                                                      alt=""
                                                      onClick={() =>
                                                         this.DeActivePaymentMethodID(
                                                            item.id
                                                         )
                                                      }
                                                      style={{ width: "26px" }}
                                                   />
                                                </Link>
                                             ) : item?.status == "deactive" ? (
                                                <Link
                                                   to="#"
                                                   className="text-danger"
                                                   title="Active"
                                                >
                                                   <img
                                                      src={image_userA}
                                                      alt=""
                                                      onClick={() =>
                                                         this.ActivePaymentMethodID(
                                                            item.id
                                                         )
                                                      }
                                                      style={{ width: "26px" }}
                                                   />
                                                </Link>
                                             ) : (
                                                ""
                                             )}
                                          </div>
                                       </td>
                                    </tr>
                                 </>
                              );
                           })}
                        </tbody>
                        {this.state.lastPage > 1 && (
                           <Pagination
                              pageCount={this.state.lastPage}
                              paginate={this.paginate}
                           />
                        )}
                     </Table>
                     {this.state.paymentMethod?.length < 1 && (
                        <p className="text-center pt-5 fs-4 fw-bold ">
                           Record Not Found.
                        </p>
                     )}
                  </>
               ) : (
                  <div
                     className="text-center d-flex align-items-center justify-content-center "
                     style={{ height: "50vh" }}
                  >
                     <div class="spinner-border text-enter" role="status">
                        <span class="visually-hidden"></span>
                     </div>
                  </div>
               )}
            </Container>
            {/* /////////////////////// second Add model */}

            <div
               className="modal fade"
               id="staticBackdropOne"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Add{" "}
                           {this.state.typeOfListing == "sandBox"
                              ? "Sandbox"
                              : "Live"}
                        </h5>
                        <button
                           type="button"
                           onClick={this.ClearAllField}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body w-100">
                        <div className="d-flex justify-content-start text-dark">
                           <div className="100 mx-2">
                              <div className="d-flex justify-content-center">
                                 <div className="form-check">
                                    <input
                                       className="form-check-input"
                                       type="radio"
                                       name="flexRadioDefault"
                                       id="sandBox"
                                       onClick={(e) =>
                                          this.WhichTypeOfListingChange(e)
                                       }
                                       checked={
                                          this.state.typeOfListing == "sandBox"
                                       }
                                    />
                                    <label
                                       className="form-check-label me-3"
                                       htmlFor="sandBox"
                                    >
                                       Sand Box
                                    </label>
                                 </div>

                                 <div className="form-check">
                                    <input
                                       className="form-check-input"
                                       type="radio"
                                       name="flexRadioDefault"
                                       id="live"
                                       onClick={(e) =>
                                          this.WhichTypeOfListingChange(e)
                                       }
                                       checked={
                                          this.state.typeOfListing == "live"
                                       }
                                    />
                                    <label
                                       className="form-check-label"
                                       htmlFor="live"
                                    >
                                       Live
                                    </label>
                                 </div>
                              </div>

                              {this.state.typeOfListing == "sandBox" ? (
                                 <div className="mt-2 row">
                                    <label
                                       htmlFor="exampleFormControlInput1 "
                                       className="fw-bolder required form-label px-0 "
                                    >
                                       Public Key
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control form-control-solid col-12  "
                                       placeholder="Enter Public Key"
                                       name="testPublicKey"
                                       value={this.state.testKey}
                                       // onChange={(e) => this.handleTypeOfListing(e)}
                                       // onKeyPress={(e) => this.onKeyPressAddGroup(e)}

                                       onChange={(e) =>
                                          this.setState({
                                             testKey: e.target.value,
                                             publicKeyVal: false,
                                          })
                                       }
                                    />
                                    <div className="d-flex col-12 ">
                                       {this.state.publicKeyVal ? (
                                          <div className="col-12  d-flex text-start myError">
                                             <small className="fw-lighter text-start text-danger">
                                                Public key is required
                                             </small>
                                          </div>
                                       ) : null}
                                    </div>
                                    <label
                                       htmlFor="exampleFormControlInput1 "
                                       className="fw-bolder required form-label px-0  mt-2"
                                    >
                                       Secrete Key
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control form-control-solid  col-12 "
                                       placeholder="Enter Secrete Key"
                                       name="testSecreteKey"
                                       value={this.state.testSecret}
                                       // onChange={(e) => this.handleTypeOfListing(e)}
                                       // onKeyPress={(e) => this.onKeyPressAddGroup(e)}

                                       onChange={(e) =>
                                          this.setState({
                                             testSecret: e.target.value,
                                             secreteKeyVal: false,
                                          })
                                       }
                                    />
                                    <div className="d-flex col-12 ">
                                       {this.state.secreteKeyVal ? (
                                          <div className="col-12  d-flex text-start myError">
                                             <small className="fw-lighter text-start text-danger">
                                                Secrete key is required
                                             </small>
                                          </div>
                                       ) : null}
                                    </div>
                                 </div>
                              ) : (
                                 <div className="mt-2 row">
                                    <label
                                       htmlFor="exampleFormControlInput1 "
                                       className="fw-bolder required form-label px-0  "
                                    >
                                       Public Key
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control form-control-solid col-12  "
                                       placeholder="Enter Public Key"
                                       value={this.state.liveKey}
                                       name="publicKeyLive"
                                       // onChange={(e) => this.handleTypeOfListing(e)}
                                       // onKeyPress={(e) => this.onKeyPressAddGroup(e)}

                                       onChange={(e) =>
                                          this.setState({
                                             liveKey: e.target.value,
                                             publicKeyLiveVal: false,
                                          })
                                       }
                                    />
                                    <div className="d-flex col-12 ">
                                       {this.state.publicKeyLiveVal ? (
                                          <div className="col-12  d-flex text-start myError">
                                             <small className="fw-lighter text-start text-danger">
                                                Public key is required
                                             </small>
                                          </div>
                                       ) : null}
                                    </div>
                                    <label
                                       htmlFor="exampleFormControlInput1 "
                                       className="fw-bolder required form-label mt-2 px-0 "
                                    >
                                       Secrete Key
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control form-control-solid  col-12"
                                       placeholder="Enter Secrete Key"
                                       value={this.state.liveSecret}
                                       name="secreteKeyLive"
                                       // onChange={(e) => this.handleTypeOfListing(e)}
                                       // onKeyPress={(e) => this.onKeyPressAddGroup(e)}

                                       onChange={(e) =>
                                          this.setState({
                                             liveSecret: e.target.value,
                                             secreteKeyLiveVal: false,
                                          })
                                       }
                                    />
                                    <div className="d-flex col-12 ">
                                       {this.state.secreteKeyLiveVal ? (
                                          <div className="col-12  d-flex text-start myError">
                                             <small className="fw-lighter text-start text-danger">
                                                Secrete key is required
                                             </small>
                                          </div>
                                       ) : null}
                                    </div>
                                 </div>
                              )}
                           </div>
                        </div>
                     </div>
                     {this.state.typeOfListing == "sandBox" ? (
                        <div className="modal-footer">
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {/* ///////////////// */}

                           {this.state.testKey != "" &&
                           this.state.testKey != undefined &&
                           this.state.testSecret != "" &&
                           this.state.testSecret != undefined ? (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 // onClick={(e) => this.SaveUser(e)}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SandBoxFunction}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Save
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.SandBoxFunction}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Save
                              </button>
                           )}
                        </div>
                     ) : (
                        <div className="modal-footer">
                           <button
                              type="button"
                              onClick={this.ClearAllField}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {/* ///////////////// */}

                           {this.state.liveKey != "" &&
                           this.state.liveKey != undefined &&
                           this.state.liveSecret != "" &&
                           this.state.liveSecret != undefined ? (
                              <button
                                 // data-bs-dismiss="modal"
                                 type="button"
                                 className="btn btn-primary"
                                 // onClick={(e) => this.SaveUser(e)}
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.LiveBoxFunction}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Save
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.LiveBoxFunction}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Save
                              </button>
                           )}
                        </div>
                     )}
                  </div>
               </div>
            </div>

            {/* /////////////////edit model */}
            <div
               className="modal fade"
               id="staticBackdropTwo"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Edit Method
                        </h5>
                        <button
                           type="button"
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body w-100">
                        <div className="d-flex text-dark">
                           <div className=" mx-2 row">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-2 px-0 "
                              >
                                 Name
                              </label>
                              <p>{this.state.name} </p>
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-2 px-0 "
                              >
                                 Status
                              </label>
                              <p>{this.state.status} </p>

                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mt-2 px-0 "
                              >
                                 Status
                              </label>
                              <select
                                 className="form-select form-select-solid shadow-none mb-2 col-12"
                                 aria-label=".form-select-lg example"
                                 name="status"
                                 onChange={(e) =>
                                    this.handleChangePaymentMethod(e)
                                 }
                                 value={this.state.status}
                              >
                                 <option selected hidden>
                                    Select Status
                                 </option>
                                 <option>Active</option>
                                 <option>deActive</option>
                              </select>
                           </div>
                        </div>
                     </div>

                     <div className="modal-footer">
                        <button
                           type="button"
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>
                        {/* {this.state.vehicleModel != "" && this.state.vehicleModel != undefined ? ()} */}

                        {this.state.modelName != "" &&
                        this.state.modelName != undefined ? (
                           <button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                              onClick={() => this.updateModel()}
                              ref={(node) => (this.btnOne = node)}
                           >
                              Update
                           </button>
                        ) : (
                           <button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              onClick={() => this.updateModel()}
                              ref={(node) => (this.btnOne = node)}
                           >
                              Update
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </div>
            {/* ///////////////////////add model */}
            {/* <div
          className="modal fade"
          id="staticBackdropOne"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content text-start">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Add Payment Method
                </h5>
                <button type="button" onClick={this.ClearAllField} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body w-100">
                <div className="d-flex  text-dark">
                  <div className="100 mx-2 row">
                    <label htmlFor="exampleFormControlInput1 " className="fw-bolder required form-label mb-2 px-0 ">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid col-12"
                      placeholder="Enter Name"
                      name="name"
                      value={this.state.name}
                     
                      onChange={(e) => this.setState({ name: e.target.value, nameVal: false })}
                    />
                    <div className="d-flex col-12 ">
                      {this.state.nameVal ? (
                        <div className="col-11 ms-0 d-flex text-start myError">
                          <br />
                          <small className="fw-lighter text-start text-danger">Name is required</small>
                        </div>
                      ) : null}
                    </div>
                    <label htmlFor="exampleFormControlInput1 " className="fw-bolder required form-label mt-2 px-0 ">
                      Status
                    </label>
                    <select
                      className="form-select form-select-solid shadow-none mb-2 col-12"
                      aria-label=".form-select-lg example"
                      name="status"
                      onChange={(e) => this.handleChangePaymentMethod(e)}
                      value={this.state.status}
                    >
                      <option selected hidden>
                        Select Status
                      </option>
                      <option>Active</option>
                      <option>deActive</option>
                    </select>
                  </div>
                </div>
              </div>
              {console.log(this.state.status)}
              <div className="modal-footer">
                <button type="button" onClick={this.ClearAllField} className="btn btn-secondary" data-bs-dismiss="modal">
                  Close
                </button>
               
                {this.state.name != "" && this.state.name != undefined ? (
                  <button
                   
                    type="button"
                    className="btn btn-primary"
                   
                    style={{
                      background: "linear-gradient(#3E94A6, #323F4B)",
                    }}
                    onClick={this.SavePaymentMethod}
                    data-bs-dismiss="modal"
                    ref={(node) => (this.btn = node)}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      background: "linear-gradient(#3E94A6, #323F4B)",
                    }}
                    onClick={this.SavePaymentMethod}
                    ref={(node) => (this.btn = node)}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div> */}
         </>
      );
   }
}

export default PaymentMethod;
