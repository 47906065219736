import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

// import { getReasons, AddReports, DeleteReasons, EditReason, getReportType } from "../Services/UserServices/Index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import ProfileImg from "../../assets/images/profile/images.jpg";
import Img from "../../assets/images/small/img-2.jpg";
// import { getSocialGroups } from "../../Services/SocialGroups/index";
import { getInvoices } from "../Services/Invocies/index";
import "./Invoices.css";
import Pagination from "../VerticalLayout/pagination/Pagination";
import { Player } from "video-react";
import "video-react/dist/video-react.css"; // import css
class invoices extends Component {
   constructor(props) {
      super(props);

      // this.setEditReason = this.setEditReason.bind(this);
      // this.setAddReasons = this.setAddReasons.bind(this);
      // this.setInitialValue = this.setInitialValue.bind(this);

      this.state = {
         firstName: "",
         allReports: [],
         allInvoice: [],
         loading: false,
         slectetdReportType: "",
         slectetdReportID: "",
         slectetdReportValue: "",
         // reportVal:false,
         reportTitle: "",
         pageNoStart: 0,
         // reportTitleVal:false,
         slectetdReasonId: "",
         reasonTitle: "",
         editplateID: null,
         slectetdIDReason: "",
         slectetdIDReasons: "",
         dateChangerStart: [],
         dateChangerEnd: [],

         reasonTitleVal: false,
         isShowNextBtn: false,
         slectetdReasonIdVal: false,
         btnLoader: true,
      };
   }

   async componentDidMount() {
      this.setState({ loading: true });

      this.getAllInvoice();
      // this.getAllRoports();
   }

   async getAllInvoice(page) {
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         const result = await getInvoices(accessToken, page);
         if (result.status === true) {
            this.setState({ loading: false });
            console.log("result", result);
            this.setState({ btnLoader: true });
            this.setState((prevState) => ({
               isShowNextBtn: result.invoice?.has_more,

               allInvoice: prevState.allInvoice.concat(result.invoice.data),
            }));
            // this.setState({
            //    allInvoice: result.invoice.data,
            // });
            this.state.allInvoice.map((datezChanger, index) => {
               this.setState({
                  dateChangerStart: new Date(datezChanger.period_start),
               });
               this.setState({
                  dateChangerEnd: new Date(datezChanger.period_end),
               });
            });
         } else {
            console.log("sorry");
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   paginate = async (data) => {
      // console.log("data", data);
      // let page = data.selected + 1;
      // window.scrollTo({
      //    top: 0,
      //    behavior: "smooth",
      // });
      // this.setState((prevState) => ({
      //    pageNoStart: prevState.pageNoStart + 20,
      // }));
      this.setState({ btnLoader: false });
      await this.getAllInvoice(data);
   };

   dateConveter = (date) => {
      let dte = new Date(date);
      return dte;
   };

   render() {
      return (
         <>
            <div className="page-content">
               <h5 className="fs-5 mb-3 fw-bold">Invoices </h5>
               {this.state.loading == false ? (
                  <div>
                     <Card className="p-4">
                        <div className="overflow-auto pb-5">
                           <table class="table table-responsive">
                              <thead>
                                 <tr>
                                    <th scope="col">No.</th>
                                    <th scope="col">Users </th>
                                    <th scope="col"> Country Account</th>
                                    <th scope="col">Currency</th>

                                    <th scope="col">Amount</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Duration</th>

                                    {/* <th scope="col">Date</th> */}

                                    <th scope="col">Status</th>
                                 </tr>
                              </thead>
                              {console.log(
                                 "this.state.allInvoice",
                                 this.state.allInvoice
                              )}
                              <tbody
                                 style={{
                                    paddingBottom: "5rem",
                                    minHeight: "90vh",
                                 }}
                              >
                                 {this.state.allInvoice?.length > 0 ? (
                                    <>
                                       {
                                          ((this.num = this.state?.pageNoStart),
                                          this.state.allInvoice.map(
                                             (item, index) => (
                                                <>
                                                   <tr>
                                                      <td>
                                                         {
                                                            (this.num =
                                                               this.num + 1)
                                                         }
                                                      </td>

                                                      <td>
                                                         <h6 className="mb-0 ">
                                                            {item.customer_name ??
                                                               "--"}
                                                         </h6>
                                                         {/* <small>{item.customer_email}</small> */}
                                                         <p className="mb-0 ">
                                                            {item.customer_email ??
                                                               "--"}
                                                         </p>
                                                         <small>
                                                            {item.customer_phone ??
                                                               "--"}
                                                         </small>
                                                      </td>
                                                      <td>
                                                         <span>
                                                            {" "}
                                                            {item.account_country ??
                                                               "--"}
                                                         </span>
                                                      </td>
                                                      <td>
                                                         <span>
                                                            {item.currency
                                                               ? item.currency.toUpperCase()
                                                               : "--"}
                                                         </span>
                                                      </td>
                                                      <td>
                                                         <span>
                                                            {item.amount_paid
                                                               ? "$" +
                                                                 item.amount_paid /
                                                                    100
                                                               : "$0"}
                                                         </span>
                                                      </td>
                                                      <td>
                                                         {item?.lines?.data[0]
                                                            ?.metadata
                                                            ?.package_for ? (
                                                            <span>
                                                               {item?.lines?.data[0]?.metadata?.package_for
                                                                  ?.charAt(0)
                                                                  ?.toUpperCase() +
                                                                  item?.lines?.data[0]?.metadata?.package_for?.slice(
                                                                     1
                                                                  ) ?? "--"}
                                                            </span>
                                                         ) : (
                                                            <span>--</span>
                                                         )}
                                                      </td>
                                                      <td>
                                                         <span>
                                                            {item?.lines?.data[0]?.plan?.interval
                                                               ?.charAt(0)
                                                               ?.toUpperCase() +
                                                               item?.lines?.data[0]?.plan?.interval?.slice(
                                                                  1
                                                               ) ?? "--"}
                                                         </span>
                                                      </td>

                                                      {/* <td>{this.dateConveter(item.period_start)}</td> */}
                                                      {/* <td>{this.dateConveter(item.period_end)}</td> */}
                                                      <td className="">
                                                         {item.status ==
                                                         "paid" ? (
                                                            <span className="badge bg-success text-white">
                                                               {item?.status
                                                                  ?.charAt(0)
                                                                  ?.toUpperCase() +
                                                                  item.status.slice(
                                                                     1
                                                                  )}
                                                            </span>
                                                         ) : (
                                                            <span className="badge bg-light text-dark">
                                                               {item?.status
                                                                  ?.charAt(0)
                                                                  ?.toUpperCase() +
                                                                  item.status.slice(
                                                                     1
                                                                  )}
                                                            </span>
                                                         )}
                                                      </td>
                                                   </tr>
                                                   {index ===
                                                      this.state.allInvoice
                                                         ?.length -
                                                         1 &&
                                                   this.state?.isShowNextBtn ? (
                                                      <>
                                                         <div
                                                            className="ms-5"
                                                            style={{
                                                               position:
                                                                  "absolute",
                                                               left: "35%",
                                                            }}
                                                         >
                                                            <button
                                                               className="btn text-white text-end  "
                                                               onClick={() =>
                                                                  this.paginate(
                                                                     this.state
                                                                        .allInvoice
                                                                        ?.length -
                                                                        1
                                                                        ? item?.id
                                                                        : ""
                                                                  )
                                                               }
                                                               style={{
                                                                  backgroundColor:
                                                                     "#3e94a6",
                                                                  marginLeft:
                                                                     "4rem",
                                                                  marginBottom:
                                                                     "5rem",
                                                                  position:
                                                                     "relative",
                                                                  top: "-12px",
                                                               }}
                                                            >
                                                               {this.state
                                                                  ?.btnLoader ? (
                                                                  <span>
                                                                     Load
                                                                     more...
                                                                  </span>
                                                               ) : (
                                                                  <>
                                                                     <div>
                                                                        <span className="px-1">
                                                                           Loading...
                                                                        </span>
                                                                        <span
                                                                           className="spinner-border spinner-border-sm"
                                                                           role="status"
                                                                           aria-hidden="true"
                                                                        />
                                                                     </div>
                                                                  </>
                                                               )}
                                                            </button>
                                                         </div>
                                                      </>
                                                   ) : null}
                                                </>
                                             )
                                          ))
                                       }
                                    </>
                                 ) : (
                                    <></>
                                 )}
                              </tbody>
                           </table>
                           {this.state.allInvoice?.length < 1 && (
                              <p className="text-center pt-5 fs-4 fw-bold ">
                                 Record Not Found.
                              </p>
                           )}
                        </div>
                     </Card>
                  </div>
               ) : (
                  <div
                     className="text-center mt-5 d-flex align-items-center justify-content-center"
                     style={{
                        height: "50vh",
                     }}
                  >
                     <div class="spinner-border text-enter" role="status">
                        <span class="visually-hidden"></span>
                     </div>
                  </div>
               )}
            </div>
            {/* /////////////////////////////Edit model */}
            <div
               className="modal fade"
               id="staticBackdropTwo"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Chow Club
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        <PerfectScrollbar style={{ maxHeight: "450px" }}>
                           <div className=" mb-3">
                              <li style={{ listStyle: "none" }}>
                                 <Link to="#">
                                    <div className="d-flex">
                                       <div>
                                          <img
                                             src={ProfileImg}
                                             className="rounded-circle avatar-xs"
                                          />
                                       </div>

                                       <div className="flex-1 overflow-hidden">
                                          <p className="text-truncate px-2">
                                             James
                                          </p>
                                       </div>

                                       <div className="font-size-11">
                                          01/21/2022
                                       </div>
                                    </div>
                                    <div>
                                       <p>
                                          In publishing and graphic design,
                                          Lorem ipsum is a placeholder text
                                          commonly used to demonstrate the
                                          visual form of a document or a
                                          typeface without relying on meaningful
                                          content. Lorem ipsum may be used as a
                                          placeholder before final copy is
                                          available.
                                       </p>
                                    </div>
                                 </Link>
                              </li>
                           </div>
                           <div className=" mb-3">
                              <li style={{ listStyle: "none" }}>
                                 <Link to="#">
                                    <div className="d-flex">
                                       <div>
                                          <img
                                             src={ProfileImg}
                                             className="rounded-circle avatar-xs"
                                          />
                                       </div>

                                       <div className="flex-1 overflow-hidden">
                                          <p className="text-truncate px-2">
                                             Charlotte
                                          </p>
                                       </div>

                                       <div className="font-size-11">
                                          01/21/2022
                                       </div>
                                    </div>
                                    <div>
                                       <Player
                                          playsInline
                                          src="https://www.youtube.com/watch?v=4VaqA-5aQTM"
                                          width="auto"
                                          height="200px"
                                       />

                                       {/* <ReactPlayer
                                          width="auto"
                                          height="200px"
                                          // url={message?.conversation_media[0]?.path}
                                          url="https://www.youtube.com/watch?v=4VaqA-5aQTM"
                                          playing={false}
                                          controls={true}
                                          loop={false}
                                          muted={false}
                                          playsinline={true}
                                          // onReady={onLoadedData}
                                       /> */}
                                    </div>
                                 </Link>
                              </li>
                           </div>

                           <div className=" mb-3">
                              <li style={{ listStyle: "none" }}>
                                 <Link to="#">
                                    <div className="d-flex">
                                       <div>
                                          <img
                                             src={ProfileImg}
                                             className="rounded-circle avatar-xs"
                                          />
                                       </div>

                                       <div className="flex-1 overflow-hidden">
                                          <p className="text-truncate px-2">
                                             Amelia
                                          </p>
                                       </div>

                                       <div className="font-size-11">
                                          01/21/2022
                                       </div>
                                    </div>
                                    <div>
                                       <img
                                          src={Img}
                                          width="100%"
                                          height="200"
                                       />
                                    </div>
                                 </Link>
                              </li>
                           </div>
                        </PerfectScrollbar>
                     </div>

                     <div className="modal-footer ">
                        <button
                           type="button"
                           onClick={this.AllClearValueValidation}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default invoices;
