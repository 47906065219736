// import React from "react";
import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
   setDeActivePlate,
   setActivePlate,
   searchUser,
} from "../Services/UserServices/ManageUsers/index";
import { getReportedPlates } from "../Services/ManagePlates/index";
import {
   setAcceptUser,
   setRejectUser,
} from "../Services/UserServices/ManageUsers/index";
import Swal from "sweetalert2";
import "./plates.css";
// import image_plateD from "../EcommerceOrders/image_plateD.svg";
import image_plateD from "../../assets/PlatesIcon/image_plateD.svg";
import image_plateA from "../../assets/PlatesIcon/image_plateA.svg";

import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../VerticalLayout/pagination/Pagination";
import profileImage from "../../assets/images/profile_image.jpg";
// const ReportedPlates = () => {
class ReportedPlates extends Component {
   constructor(props) {
      super(props);
      this.ActivePlateID = this.ActivePlateID.bind(this);
      this.DeActivePlateID = this.DeActivePlateID.bind(this);
      this.SearchChangeHandler = this.SearchChangeHandler.bind(this);

      this.state = {
         allReportedPlates: [],
         loading: false,
         modalData: [],
         iID: 0,
         plateStatus: 0,
         modalStatus: "",
         pageIDs: [],
         currentPage: "",
         lastPage: "",
         profilesImg: "",
         reportedUserReason: "",
         carImages: "",
         plateNumber: "",
         ownerName: "",
         ownerPhone: "",
         ownerEmail: "",
         parkingCount: "",
      };
   }

   async componentDidMount() {
      // this.getAllReasons();
      this.getAllReportedPlate();
   }

   async getAllReportedPlate(page) {
      try {
         let IDArray = [];

         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);

         const result = await getReportedPlates(accessToken, page);
         if (result.status === true) {
            // console.log("reported", result);
            for (
               let i = result.reported_platess.from;
               i <= result.reported_platess.to;
               i++
            ) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });

            this.setState({
               currentPage: result.reported_platess.current_page,
            });
            this.setState({ lastPage: result.reported_platess.last_page });

            this.setState({ loading: true });
            this.setState({ allReportedPlates: result.reported_platess.data });
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   async ActivePlateID(id) {
      console.log("active", id);
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to activate this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await setAcceptUser(accessToken, id);
            if (result.status === true) {
               this.getAllReportedPlate();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: " Active plate successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActivePlateID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to be Deactive?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await setRejectUser(accessToken, id);

            if (result.status === true) {
               this.getAllReportedPlate();
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Plate Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   async SearchChangeHandler(e) {
      let search = e.target.value;
      this.setState({ searchItem: e.target.value });

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const userData = await searchUser(accessToken, e.target.value);
         if (userData.status === true) {
            this.setState({
               allReportedPlates: userData.reported_platess.data,
            });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };
   dataLength;

   setDataHandler = (
      data,
      id,
      status,
      ProfileImg,
      carImage,
      plateNumber,
      ownerName,
      ownerEmail,
      ownerPhone,
      reason,
      parkingCounts
   ) => {
      this.dataLength = data;
      console.log("imglllllllllll", carImage);
      this.setState({ modalData: data });
      this.setState({
         modalStatus: status,
         //
         parkingCount: parkingCounts,
         profilesImg: ProfileImg,
         carImages: carImage,
         plateNumber: plateNumber,
         ownerName: ownerName,
         ownerPhone: ownerPhone,
         ownerEmail: ownerEmail,
         reportedUserReason: reason,
      });
      this.setState({ iID: id });
   };

   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllReportedPlate(page);
   };
   render() {
      return (
         <div className="page-content">
            <div className="container">
               <h5 className="fs-5 mb-3 fw-bold">Reported Plates </h5>
               {this.state.loading === true ? (
                  <Card className="p-4">
                     <div className="overflow-auto">
                        <table class="table table-responsive">
                           <thead>
                              <tr className="text-Dark ">
                                 <th scope="col">No.</th>
                                 <th scope="col">Plate Number</th>
                                 <th scope="col">Plate Date</th>
                                 <th scope="col">Report By</th>
                                 <th scope="col">Status</th>
                                 {/* <th scope="col">Report</th> */}

                                 <th scope="col">Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              {this.state.allReportedPlates.length > 0 ? (
                                 this.state.allReportedPlates.map(
                                    (item, outer) => {
                                       return (
                                          <>
                                             <tr key={outer}>
                                                {this.state.pageIDs?.map(
                                                   (item, inner) =>
                                                      outer == inner ? (
                                                         <h6 className="mb-0">
                                                            {item.id}
                                                         </h6>
                                                      ) : null
                                                )}

                                                <td
                                                   data-bs-toggle="modal"
                                                   data-bs-target="#staticBackdropSix"
                                                   className="pointer"
                                                   onClick={() =>
                                                      this.setDataHandler(
                                                         item?.reportable?.user
                                                            ?.total_plates,
                                                         item?.id,
                                                         item?.status,
                                                         // item.reportable.user.profile_image
                                                         `${item?.reportable?.user?.profile_image?.folder_path}${item?.reportable?.user?.profile_image?.file_name}`,
                                                         `${item?.reportable?.car_image?.folder_path}${item?.reportable?.car_image?.file_name}`,
                                                         item.reportable?.plate,
                                                         item?.reportable?.user
                                                            ?.name,
                                                         item?.reportable?.user
                                                            ?.email,
                                                         item?.reportable?.user
                                                            ?.phone,
                                                         item?.report_reason
                                                            ?.title,
                                                         item?.user
                                                            ?.parkings_count
                                                      )
                                                   }
                                                   title="profile"
                                                >
                                                   <h6 className="mb-0">
                                                      {" "}
                                                      {item.reportable?.plate ??
                                                         "--"}
                                                   </h6>
                                                </td>

                                                <td>
                                                   <h6 className="mb-0">
                                                      {this.getDate(
                                                         item.reportable
                                                            ?.created_at
                                                      )}
                                                   </h6>
                                                </td>

                                                <td>
                                                   <h6 className="mb-0">
                                                      {" "}
                                                      {item.user?.first_name ==
                                                      undefined
                                                         ? "--"
                                                         : item.user
                                                              ?.first_name +
                                                           " " +
                                                           item.user?.last_name}
                                                   </h6>
                                                   <small className="mt-0">
                                                      {item.user?.email ?? "--"}
                                                   </small>
                                                   <br />
                                                   <small className="mt-0">
                                                      {item.user?.phone ?? "--"}
                                                   </small>
                                                </td>

                                                <td>
                                                   {item?.status ==
                                                   "accepted" ? (
                                                      <span
                                                         className="badge bg-success "
                                                         style={{
                                                            paddingLeft: 13,
                                                            paddingRight: 13,
                                                         }}
                                                      >
                                                         Active
                                                      </span>
                                                   ) : item?.status ==
                                                     "rejected" ? (
                                                      <span
                                                         className="badge bg-danger"
                                                         style={{
                                                            paddingLeft: 13,
                                                            paddingRight: 13,
                                                         }}
                                                      >
                                                         Deactive
                                                      </span>
                                                   ) : item?.status ==
                                                     "pending" ? (
                                                      <span
                                                         className="badge bg-warning"
                                                         style={{
                                                            paddingLeft: 7,
                                                            paddingRight: 7,
                                                         }}
                                                      >
                                                         Pending
                                                      </span>
                                                   ) : item?.status ==
                                                     "deactive" ? (
                                                      <span
                                                         className="badge bg-danger"
                                                         style={{
                                                            paddingLeft: 7,
                                                            paddingRight: 7,
                                                         }}
                                                      >
                                                         Deactive
                                                      </span>
                                                   ) : null}
                                                   {/* <h6 className="mb-0"></h6> */}
                                                </td>
                                                {/* <td>
                                                     <h6 className="mb-0">
                                                        {item.discription !==
                                                        null
                                                           ? item.discription
                                                           : item?.report_reason
                                                                ?.title}{" "}
                                                     </h6>
                                                  </td> */}
                                                <td>
                                                   <Link
                                                      to="#"
                                                      className="me-3 text-primary"
                                                   >
                                                      <i
                                                         title="View"
                                                         className="mdi mdi-eye font-size-18"
                                                         data-bs-toggle="modal"
                                                         data-bs-target="#staticBackdropOne"
                                                         onClick={() =>
                                                            this.setDataHandler(
                                                               item?.reportable
                                                                  ?.user
                                                                  ?.total_plates,
                                                               item?.id,
                                                               item?.status,
                                                               // item.reportable.user.profile_image
                                                               `${item?.reportable?.user?.profile_image?.folder_path}${item?.reportable?.user?.profile_image?.file_name}`,
                                                               `${item?.reportable?.car_image?.folder_path}${item?.reportable?.car_image?.file_name}`,
                                                               item.reportable
                                                                  ?.plate,
                                                               item.user
                                                                  ?.first_name,
                                                               item.user?.email,
                                                               item.user?.phone,
                                                               item
                                                                  ?.report_reason
                                                                  ?.title,
                                                               item?.user
                                                                  ?.parkings_count
                                                            )
                                                         }
                                                      ></i>
                                                   </Link>
                                                </td>
                                             </tr>
                                          </>
                                       );
                                    }
                                 )
                              ) : (
                                 <></>
                              )}
                           </tbody>
                        </table>
                        {this.state.allReportedPlates?.length < 1 && (
                           <p className="text-center pt-5 fs-4 fw-bold ">
                              Record Not Found.
                           </p>
                        )}
                     </div>
                     {this.state.lastPage > 1 && (
                        <Pagination
                           pageCount={this.state.lastPage}
                           paginate={this.paginate}
                        />
                     )}
                  </Card>
               ) : (
                  <div
                     className="text-center d-flex align-items-center justify-content-center text-bold"
                     style={{
                        height: "50vh",
                     }}
                  >
                     <div class="spinner-border text-enter" role="status">
                        <span class="visually-hidden"></span>
                     </div>
                  </div>
               )}
            </div>
            {/* ///////////////////////add model */}
            {/* <div
          className="modal fade"
          id="staticBackdropOne"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content text-start">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Reported Plate Owner Detail
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body w-100">
                <div className="row ">
                  <div className="col-5 fw-bold"> Plate Number</div>
                  <div className="col-7 ">{this.state.modalData?.plate ?? "--"}</div>
                  <div className="col-5 fw-bold"> Name</div>
                  <div className="col-7 ">{`${this.state.modalData?.user?.first_name ?? "--"} ${this.state.modalData?.user?.last_name ?? ""} `}</div>

                  <div className="col-5 fw-bold"> Phone Number</div>
                  <div className="col-7 ">{this.state.modalData?.user?.phone ?? "--"}</div>
                  <div className="col-5 fw-bold"> Email</div>
                  <div className="col-7 ">{this.state.modalData?.user?.email ?? "--"}</div>
                  <div className="col-5 fw-bold"> Gender</div>
                  <div className="col-7 ">{this.state.modalData?.user?.gender ?? "--"}</div>
                  <div className="col-5 fw-bold"> Plate Status</div>
                  <div className="col-7 ">{this.state.modalData?.status ?? "--"}</div>
                  <div className="col-5 fw-bold"> Number of Plates</div>
                  <div className="col-7 ">{this.dataLength ?? "--"}</div>
                  <div className="col-5 fw-bold"></div>
                  {this.state.modalStatus == "pending" && (
                    <div className="col-5 fw-bold">
                      <label htmlFor="" className="mt-3">
                        Take Action
                      </label>
                      <select
                        className="form-select shadow-none"
                        aria-label="Default select example"
                        value={this.state.plateStatus}
                        onChange={(e) => this.setState({ plateStatus: e.target.value })}
                      >
                        <option selected hidden>
                          Select Status
                        </option>
                        <option>Active</option>
                        <option>deActive</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
              {this.state.modalStatus == "pending" && (
                <div className="modal-footer ">
                  <button type="button" onClick={this.AllClearField} className="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>

                  {this.state.plateStatus == "Active" ? (
                    <button
                      data-bs-dismiss="modal"
                      type="button"
                      style={{
                        background: "linear-gradient(#3E94A6, #323F4B)",
                      }}
                      className="btn btn-primary"
                      onClick={() => this.ActivePlateID(this.state.iID)}
                    >
                      Ok
                    </button>
                  ) : (
                    <button
                      data-bs-dismiss="modal"
                      type="button"
                      style={{
                        background: "linear-gradient(#3E94A6, #323F4B)",
                      }}
                      className="btn btn-primary"
                      onClick={() => this.DeActivePlateID(this.state.iID)}
                    >
                      Ok
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div> */}

            {/* //////////////Profile model */}
            <div
               className="modal fade"
               id="staticBackdropOne"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Reported Person
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        {/* profilesImg */}
                        <div className="image-input-wrapper  p-3">
                           <center>
                              {this.state.profilesImg ===
                              "undefinedundefined" ? (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src={profileImage}
                                    alt=""
                                 />
                              ) : (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src={this.state.profilesImg}
                                    alt=""
                                 />
                              )}
                           </center>
                           {/* <img src={this.state.profilesImg} className="img-fluid modal_image w-50 h-50 " alt="" /> */}
                        </div>

                        {/* <div className="d-flex justify-content-around text-dark"> */}
                        <div className="Detail border shadow rounded bg-light p-2">
                           <div className="row ">
                              <div className="offset-md-1 col-5">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Plate Number
                                 </label>
                              </div>
                              <div className="offset-md-1 col-5">
                                 <p>{this.state.plateNumber ?? "--"}</p>
                              </div>

                              <div className=" offset-md-1 col-5">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Name
                                 </label>
                              </div>
                              <div className="offset-md-1 col-5">
                                 <p>{this.state.ownerName}</p>
                              </div>

                              <div className="offset-md-1 col-5">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Phone
                                 </label>
                              </div>
                              <div className="offset-md-1 col-5">
                                 <p>{this.state.ownerPhone ?? "--"}</p>
                              </div>

                              <div className="offset-md-1 col-5">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Email
                                 </label>
                              </div>

                              <div className="offset-md-1 col-5">
                                 <p>{this.state.ownerEmail ?? "--"}</p>
                              </div>
                              <div className="offset-md-1 col-5">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Status
                                 </label>
                              </div>

                              <div className="offset-md-1 col-5">
                                 <p>{this.state.modalStatus ?? "--"}</p>
                              </div>

                              <div className="offset-md-1 col-5">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Number Of Plates
                                 </label>
                              </div>
                              <div className="offset-md-1 col-5">
                                 <p>{this.dataLength ?? "0"}</p>
                              </div>
                              <div className="offset-md-1 col-5">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Number Of Listings
                                 </label>
                              </div>
                              <div className="offset-md-1 col-5">
                                 <p>{this.state?.parkingCount ?? "0"}</p>
                              </div>

                              <div className="offset-md-1 col-11">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Report Reason
                                 </label>
                              </div>
                              <div className="offset-md-1 col-11">
                                 <p>{this.state.reportedUserReason ?? "--"}</p>
                              </div>
                              {this.state.modalStatus == "pending" && (
                                 <div className="offset-md-1 col-11 fw-bold">
                                    <label htmlFor="" className="mt-3">
                                       Take Action
                                    </label>
                                    <select
                                       className="form-select shadow-none"
                                       aria-label="Default select example"
                                       value={this.state.plateStatus}
                                       onChange={(e) =>
                                          this.setState({
                                             plateStatus: e.target.value,
                                          })
                                       }
                                    >
                                       <option selected hidden>
                                          Select Status
                                       </option>
                                       <option>Active</option>
                                       <option>Deactive</option>
                                    </select>
                                 </div>
                              )}
                           </div>
                        </div>
                     </div>

                     {this.state.modalStatus == "pending" ? (
                        <div className="modal-footer ">
                           <button
                              type="button"
                              onClick={this.AllClearField}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           {this.state.plateStatus == "Active" ? (
                              <button
                                 data-bs-dismiss="modal"
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 onClick={() =>
                                    this.ActivePlateID(this.state.iID)
                                 }
                              >
                                 Ok
                              </button>
                           ) : (
                              <button
                                 data-bs-dismiss="modal"
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 onClick={() =>
                                    this.DeActivePlateID(this.state.iID)
                                 }
                              >
                                 Ok
                              </button>
                           )}
                        </div>
                     ) : (
                        <>
                           <div className="modal-footer">
                              <button
                                 type="button"
                                 onClick={this.AllClearField}
                                 className="btn btn-secondary"
                                 data-bs-dismiss="modal"
                              >
                                 Close
                              </button>
                           </div>
                        </>
                     )}
                  </div>
               </div>
            </div>

            {/* //////////////Plate model */}
            <div
               className="modal fade"
               id="staticBackdropSix"
               data-bs-backdrop="static"
               data-bs-keyboard="false"
               tabIndex={-1}
               aria-labelledby="staticBackdropLabel"
               aria-hidden="true"
            >
               <div className="modal-dialog">
                  <div className="modal-content text-start">
                     <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                           Plate Profile
                        </h5>
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                        />
                     </div>
                     <div className="modal-body">
                        {/* profilesImg */}
                        <div className="image-input-wrapper  p-3">
                           <center>
                              {this.state.carImages === "undefinedundefined" ? (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle   me-3"
                                    src="https://img.lovepik.com/free-png/20210926/lovepik-car-icon-png-image_401452256_wh1200.png"
                                    alt=""
                                 />
                              ) : (
                                 <img
                                    className="img-fluid userProfile  mb-2  rounded rounded-circle me-3"
                                    src={this.state.carImages}
                                    alt=""
                                 />
                              )}
                           </center>
                           {/* <img src={this.state.profilesImg} className="img-fluid modal_image w-50 h-50 " alt="" /> */}
                        </div>

                        {/* <div className="d-flex justify-content-around text-dark"> */}
                        <div className="Detail border shadow rounded bg-light p-2">
                           <div className="row ">
                              <div className="col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Plate Number:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.state.plateNumber ?? "--"}</p>
                              </div>

                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Owner Name:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.state.ownerName ?? "--"}</p>
                              </div>
                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Owner Phone:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.state.ownerPhone ?? "--"}</p>
                              </div>
                              <div className=" col-4">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0"
                                 >
                                    Owner Email:
                                 </label>
                              </div>
                              <div className="col-8">
                                 <p>{this.state.ownerEmail ?? "--"}</p>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="modal-footer">
                        <button
                           type="button"
                           onClick={this.AllClear}
                           className="btn btn-secondary"
                           data-bs-dismiss="modal"
                        >
                           Close
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default ReportedPlates;
