import React, { Component, useState } from "react";
import { PhotoshopPicker, SketchPicker } from "react-color";
import { Link } from "react-router-dom";
import {
   EditColor,
   AddColor,
   SearchColor,
   deleteColor,
   getVehicleColors,
   ActiveVehicleColorsID,
   DeActiveVehicleColorsID,
} from "../Services/VehicleColors/index";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
// import ".././VerticalLayout/AllUsers/AllUsers.css";
import "./Color.css";
import image_userA from "../../assets/vehicleColor/VehicleColorA.svg";
import image_userD from "../../assets/vehicleColor/VehicleColorDA.svg";
import Pagination from "../VerticalLayout/pagination/Pagination";
class VehicleColors extends Component {
   constructor(props) {
      super(props);

      this.SaveColor = this.SaveColor.bind(this);
      this.updateColor = this.updateColor.bind(this);

      // this.ClearAllField = this.ClearAllField.bind(this);
      this.AllClearFields = this.AllClearFields.bind(this);

      this.state = {
         color: "#191414",
         colorVal: false,
         showColorModal: false,

         colorCode: "",
         colorName: "",
         colorNameVal: false,
         colorsArray: [],
         bgColor: "",
         currentPage: "",
         lastPage: "",
         loading: false,
         editcolorID: "",
         status: "",
         pageIDs: [],

         loadingModel: true,

         searchItem: "",
      };
   }
   async componentDidMount() {
      // Getting Models from API Call----------------------

      this.getAllColors();
   }
   async getAllColors(page) {
      try {
         let IDArray = [];

         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const colors = await SearchColor(
            accessToken,
            page,
            this.state.searchItem
         );

         if (colors.status === true) {
            for (
               let i = colors.vehicle_colors.from;
               i <= colors.vehicle_colors.to;
               i++
            ) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
            this.setState({ loading: true });
            if (page > 1) {
               this.setState({ currentPage: page });
            } else
               this.setState({
                  currentPage: colors.vehicle_colors.current_page,
               });
            this.setState({ lastPage: colors.vehicle_colors.last_page });
            this.setState({
               colorsArray: colors.vehicle_colors.data,
               bgColor: colors.vehicle_colors.data.color,
            });
         }
      } catch (err) {
         console.log("error", err);
      }
   }
   async SaveColor() {
      this.setState({ loadingModel: false });
      this.setState({ colorNameVal: false });
      if (this.state.color == "" || this.state.color == undefined) {
         this.setState({ colorVal: true });
      }
      if (this.state.colorName == "" || this.state.colorName == undefined) {
         this.setState({ colorNameVal: true });
      }
      if (
         this.state.color != "" &&
         this.state.color != undefined &&
         this.state.colorName != "" &&
         this.state.colorName != undefined
      ) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await AddColor(
               accessToken,
               this.state.colorName,
               this.state.color
            );
            if (response.status == true) {
               // this.getAllColors();

               this.getAllColors(this.state.currentPage);

               // let usersArray = [...this.state.colorsArray];
               // usersArray.unshift(response.color);
               // this.setState({ colorsArray: usersArray });

               // this.setState({ loadingModel: true });

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle color added successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  colorName: "",
                  status: "",
                  colorCode: "",
                  editcolorID: "",
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: "Color has already been taken.",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   async setInitialValue(id, color, name, status) {
      this.setState({
         editcolorID: id,
         colorName: name,
         colorCode: color,
         status: status,
      });
   }
   async updateColor() {
      this.setState({ colorNameVal: false });
      if (this.state.colorName == "" || this.state.colorName == undefined) {
         this.setState({ colorNameVal: true });
      }
      if (this.state.colorName != "" && this.state.colorName != undefined) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await EditColor(
               accessToken,
               this.state.colorName,
               this.state.colorCode,
               this.state.editcolorID
            );
            if (response.status == true) {
               this.getAllColors(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle color updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  colorName: "",
                  status: "",
                  editcolorID: "",
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   async DeleteColorID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await deleteColor(accessToken, id);
            if (result.status === true) {
               this.getAllColors(this.state.currentPage);

               // let userArray = [...this.state.colorsArray];
               // userArray.map((item, index) => {
               //   if (item.id === id) {
               //     userArray.splice(index, 1);
               //   }
               // });
               // this.setState({ colorsArray: userArray });

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle color removed successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 4000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   /**
    * Alert if clicked on outside of element
    */

   async SearchChangeHandler(e) {
      this.setState({ searchItem: e.target.value });

      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const colors = await SearchColor(
            accessToken,
            this.state.currentPage,
            e.target.value
         );

         if (colors.status === true) {
            this.setState({ colorsArray: colors.vehicle_colors.data });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   AllClearFields = () => {
      this.setState({ vehicleName: "", color: "" });
      this.setState({ colorNameVal: false });
      this.setState({ colorVal: false });
   };
   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };
   onKeyPressAddGroupEdit = (e) => {
      if (e.which === 13) {
         this.btnOne.click();
      }
   };

   async ActiveVehicleColorID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to activate this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });

         if (resultRemove.isConfirmed) {
            const result = await ActiveVehicleColorsID(accessToken, id);
            if (result.status === true) {
               this.getAllColors(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle color active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActiveVehicleColorID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to Deactive this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
            reverseButtons: true,
         });
         if (resultRemove.isConfirmed) {
            const result = await DeActiveVehicleColorsID(accessToken, id);

            if (result.status === true) {
               this.getAllColors(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle color Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      await this.getAllColors(page);
   };

   render() {
      return (
         <>
            <div className=" page-content">
               <Container>
                  {/* ///////////////////////////// */}
                  <h5 className="fs-5 mb-3 fw-bold">Vehicle Colors</h5>
                  {this.state.loading ? (
                     <>
                        <Card className="p-4 ">
                           <div className="d-flex justify-content-between">
                              <div>
                                 {/* <div className="search-box "> */}
                                 {/* <input
                        type="search"
                        class="form-control rounded-pill"
                        placeholder="Search Color Code"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        onChange={(e) => this.SearchChangeHandler(e)}
                      /> */}
                                 {/* </div> */}
                              </div>
                              <div>
                                 <Button
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropOne"
                                    type="button"
                                    style={{
                                       background:
                                          "linear-gradient(#3E94A6, #323F4B)",
                                    }}
                                    onClick={() => this.AllClearFields()}
                                    className="btn-rounded mb-2 me-2"
                                 >
                                    <span>Add Color</span>
                                 </Button>
                              </div>
                           </div>

                           <div className="overflow-auto">
                              <table class="table bg-white  table-responsive">
                                 <thead>
                                    <tr>
                                       <th scope="col">No.</th>
                                       <th scope="col">Name</th>
                                       <th scope="col">Code</th>
                                       <th scope="col">Status</th>

                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {this.state.colorsArray?.length > 0 ? (
                                       <>
                                          {" "}
                                          {this.state.colorsArray.map(
                                             (item, outer) => {
                                                return (
                                                   <>
                                                      <tr key={outer}>
                                                         {this.state.pageIDs?.map(
                                                            (item, inner) =>
                                                               outer ==
                                                               inner ? (
                                                                  <td>
                                                                     {item.id}
                                                                  </td>
                                                               ) : null
                                                         )}

                                                         <td>{item.name}</td>
                                                         <td>
                                                            <div
                                                               className="text-center py-2 "
                                                               style={{
                                                                  backgroundColor:
                                                                     item.color,
                                                                  width: "90px",
                                                                  // padding: "9px",
                                                                  border:
                                                                     ".1px solid black",
                                                                  color: "white",
                                                               }}
                                                            >
                                                               <span className="badge bg-light text-dark">
                                                                  {item.name ==
                                                                  "White" ? (
                                                                     <span className="text-dark">
                                                                        {
                                                                           item.color
                                                                        }
                                                                     </span>
                                                                  ) : (
                                                                     item.color
                                                                  )}
                                                               </span>
                                                            </div>
                                                         </td>
                                                         {/* <td>{item.code}</td> */}

                                                         <td>
                                                            {item.status ==
                                                            "active" ? (
                                                               <span
                                                                  className="badge bg-success "
                                                                  style={{
                                                                     paddingLeft: 13,
                                                                     paddingRight: 13,
                                                                  }}
                                                               >
                                                                  Active
                                                               </span>
                                                            ) : item.status ==
                                                              "deactive" ? (
                                                               <span className="badge bg-danger px-2">
                                                                  Deactive
                                                               </span>
                                                            ) : null}
                                                         </td>
                                                         <td>
                                                            <Link
                                                               to="#"
                                                               className="me-3 text-primary"
                                                            >
                                                               <i
                                                                  title="Edit"
                                                                  className="mdi mdi-pencil font-size-18"
                                                                  data-bs-toggle="modal"
                                                                  data-bs-target="#staticBackdropTwo"
                                                                  onClick={() =>
                                                                     this.setInitialValue(
                                                                        item.id,
                                                                        item.color,
                                                                        item.name,
                                                                        item.status
                                                                     )
                                                                  }
                                                               ></i>
                                                            </Link>
                                                            <Link
                                                               to="#"
                                                               className="text-danger"
                                                            >
                                                               <i
                                                                  title="Delete"
                                                                  className="mdi mdi-trash-can font-size-18 me-3"
                                                                  onClick={() =>
                                                                     this.DeleteColorID(
                                                                        item.id
                                                                     )
                                                                  }
                                                               ></i>
                                                            </Link>
                                                            {item?.status ==
                                                            "active" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Deactive"
                                                               >
                                                                  <img
                                                                     src={
                                                                        image_userD
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     height={
                                                                        "20px"
                                                                     }
                                                                     className="mb-1"
                                                                     onClick={() =>
                                                                        this.DeActiveVehicleColorID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : item?.status ==
                                                              "deactive" ? (
                                                               <Link
                                                                  to="#"
                                                                  className="text-danger"
                                                                  title="Active"
                                                               >
                                                                  <img
                                                                     src={
                                                                        image_userA
                                                                     }
                                                                     alt=""
                                                                     width={
                                                                        "20px"
                                                                     }
                                                                     height={
                                                                        "20px"
                                                                     }
                                                                     className="mb-1"
                                                                     onClick={() =>
                                                                        this.ActiveVehicleColorID(
                                                                           item.id
                                                                        )
                                                                     }
                                                                  />
                                                               </Link>
                                                            ) : (
                                                               ""
                                                            )}
                                                         </td>
                                                      </tr>
                                                   </>
                                                );
                                             }
                                          )}
                                       </>
                                    ) : (
                                       <>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>
                                          <tr>
                                             {" "}
                                             <td></td>{" "}
                                          </tr>

                                          <tr>
                                             <td className="pt-5">
                                                <div class="record-not-found pt-5">
                                                   Record Not Found
                                                </div>
                                             </td>
                                          </tr>
                                       </>
                                    )}
                                 </tbody>
                              </table>
                           </div>
                           {this.state.lastPage > 1 && (
                              <Pagination
                                 pageCount={this.state.lastPage}
                                 paginate={this.paginate}
                              />
                           )}
                        </Card>
                     </>
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center text-bold"
                        style={{ height: "50vh" }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </Container>
               {/* /////////////////////////add model */}
               <div
                  className="modal fade"
                  id="staticBackdropOne"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Add Color
                           </h5>
                           <button
                              type="button"
                              onClick={this.AllClearFields}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>

                        <div className="modal-body w-100">
                           <div className=" d-flex flex-column justify-content-center">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-0"
                              >
                                 Color Code
                              </label>
                              <div className="d-flex">
                                 <input
                                    type="text"
                                    style={{
                                       width: "100%",
                                       borderRight: "none",
                                       borderBottomRightRadius: "0%",
                                       borderTopRightRadius: "0%",
                                    }}
                                    placeholder="Choose color"
                                    class=" form-control"
                                    value={this.state.color}
                                    onChange={(e) =>
                                       this.setState({
                                          color: e.target.value,
                                          colorNameVal: false,
                                       })
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }
                                 />

                                 <Dropdown>
                                    <Dropdown.Toggle
                                       style={{
                                          padding: "18px",
                                          backgroundColor: `${this.state.color}`,
                                       }}
                                       id="dropdown-basic"
                                    ></Dropdown.Toggle>

                                    <Dropdown.Menu className="p-0">
                                       <div className="wemblay-color-modal ">
                                          <SketchPicker
                                             color={this.state.color}
                                             value={this.state.color}
                                             disableAlpha={true}
                                             onChangeComplete={(e) =>
                                                this.setState({
                                                   color: e.hex,
                                                   colorVal: false,
                                                })
                                             }
                                          />
                                          <input
                                             type="text"
                                             style={{
                                                width: "100%",
                                                borderBottomRightRadius: "0%",
                                                borderTopRightRadius: "0%",
                                             }}
                                             placeholder="HEX"
                                             class=" form-control"
                                             value={this.state.color}
                                             onChange={(e) =>
                                                this.setState({
                                                   color: e.target.value,
                                                   colorNameVal: false,
                                                })
                                             }
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroup(e)
                                             }
                                          />
                                       </div>
                                    </Dropdown.Menu>
                                 </Dropdown>
                              </div>
                              {this.state.colorVal ? (
                                 <div className="modelValidation d-block">
                                    Enter color code
                                 </div>
                              ) : null}
                           </div>

                           <div className="mt-3 pb-3">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-0"
                              >
                                 Color Name
                              </label>
                              <input
                                 style={{ width: "100%" }}
                                 type="text"
                                 className="form-control form-control-solid"
                                 placeholder="Color name"
                                 value={this.state.colorName}
                                 onChange={(e) =>
                                    this.setState({
                                       colorName: e.target.value,
                                       colorNameVal: false,
                                    })
                                 }
                                 onKeyPress={(e) => this.onKeyPressAddGroup(e)}
                              />
                              {this.state.colorNameVal ? (
                                 <div className="modelValidation d-block">
                                    Enter color name
                                 </div>
                              ) : null}
                           </div>
                        </div>
                        <div className="modal-footer text-nowrap">
                           <button
                              type="button"
                              onClick={this.AllClearFields}
                              className="btn btn-secondary "
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {this.state.color != "" &&
                           this.state.color !== undefined &&
                           this.state.colorName != "" &&
                           this.state.colorName != undefined ? (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary "
                                 onClick={this.SaveColor}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Color
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary "
                                 onClick={this.SaveColor}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Color
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               {/* /////////////////edit model */}
               <div
                  className="modal fade"
                  id="staticBackdropTwo"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content text-start ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Edit Color
                           </h5>
                           <button
                              type="button"
                              onClick={this.AllClearFields}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body w-100">
                           <div className=" d-flex flex-column justify-content-center">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-0"
                              >
                                 Color Code
                              </label>
                              <div className="d-flex">
                                 <input
                                    type="text"
                                    style={{
                                       width: "100%",
                                       borderRight: "none",
                                       borderBottomRightRadius: "0%",
                                       borderTopRightRadius: "0%",
                                    }}
                                    placeholder="Choose color"
                                    class=" form-control"
                                    value={this.state.colorCode}
                                    onChange={(e) =>
                                       this.setState({
                                          colorCode: e.target.value,
                                          colorNameVal: false,
                                       })
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroupEdit(e)
                                    }
                                 />

                                 {/* <input
                        style={{ borderLeft: "none", borderBottomLeftRadius: "0%", borderTopLeftRadius: "0%" }}
                        id="example-color-input"
                        type="color"
                        class="form-control form-control-color"
                        value={this.state.color}
                        onChange={(e) => this.setState({ color: e.target.value, colorVal: false })}
                        onKeyPress={(e) => this.onKeyPressAddGroupEdit(e)}
                      /> */}
                                 <Dropdown>
                                    <Dropdown.Toggle
                                       style={{
                                          padding: "18px",
                                          backgroundColor: `${this.state.colorCode}`,
                                       }}
                                       id="dropdown-basic"
                                    >
                                       {""}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="p-0">
                                       <div className="wemblay-color-modal ">
                                          <SketchPicker
                                             color={this.state.colorCode}
                                             value={this.state.colorCode}
                                             disableAlpha={true}
                                             onChangeComplete={(e) =>
                                                this.setState({
                                                   colorCode: e.hex,
                                                   colorVal: false,
                                                })
                                             }
                                          />
                                          <input
                                             type="text"
                                             style={{
                                                width: "100%",
                                                borderBottomRightRadius: "0%",
                                                borderTopRightRadius: "0%",
                                             }}
                                             placeholder="HEX"
                                             class=" form-control"
                                             value={this.state.colorCode}
                                             onChange={(e) =>
                                                this.setState({
                                                   colorCode: e.target.value,
                                                   colorNameVal: false,
                                                })
                                             }
                                             onKeyPress={(e) =>
                                                this.onKeyPressAddGroup(e)
                                             }
                                          />
                                       </div>
                                    </Dropdown.Menu>
                                 </Dropdown>
                              </div>
                              {this.state.colorVal ? (
                                 <div className="modelValidation d-block">
                                    Enter color code
                                 </div>
                              ) : null}
                           </div>

                           <div className="mt-3 pb-3">
                              <label
                                 htmlFor="exampleFormControlInput1 "
                                 className="fw-bolder required form-label mb-0"
                              >
                                 Color Name
                              </label>
                              <input
                                 style={{ width: "100%" }}
                                 type="text"
                                 className="form-control form-control-solid"
                                 placeholder="Color name"
                                 value={this.state.colorName}
                                 onChange={(e) =>
                                    this.setState({
                                       colorName: e.target.value,
                                       colorNameVal: false,
                                    })
                                 }
                                 onKeyPress={(e) =>
                                    this.onKeyPressAddGroupEdit(e)
                                 }
                              />
                              {this.state.colorNameVal ? (
                                 <div className="modelValidation d-block">
                                    Enter color name
                                 </div>
                              ) : null}
                           </div>
                        </div>

                        <div className="modal-footer  text-nowrap">
                           <button
                              type="button"
                              onClick={this.AllClearFields}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           {this.state.colorName != "" &&
                           this.state.colorName != undefined ? (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary "
                                 onClick={() => this.updateColor()}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Save
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary "
                                 onClick={() => this.updateColor()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Save
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default VehicleColors;
