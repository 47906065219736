import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { getVehicleModel, AddModel, deleteVehicleModel, EditModel, SearchModel, ActiveVehicleModelsID, DeActiveVehicleModelsID } from "../Services/UserServices/Index";
import {
   getVehicleSpotsType,
   getVehicleSpots,
   AddSpots,
   EditVehicleSpots,
   deleteVehicleSpots,
   setActiveSpots,
   setDeActiveSpots,
} from "../../Services/VehicleServicesSpots/index";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import ".././VerticalLayout/AllUsers/AllUsers.css";
import dummyCar from "../../../assets/vehicleIcon/dummyCar.jpg";

import Swal from "sweetalert2";
import moment from "moment";
import Select from "react-select";
import pencil from "../../../assets/icons/Pencil-01.svg";

// import image_userA from "../VerticalLayout/AllUsers/VehicleModelA.svg";
// import image_userD from "../VerticalLayout/AllUsers/VehicleModelDA.svg";
import spots_active from "../../../assets/PakingImges/spotsA.svg";
import spots_deactive from "../../../assets/PakingImges/spotsDA.svg";
import Pagination from "../../VerticalLayout/pagination/Pagination";
class MangeVehicleSpots extends Component {
   constructor(props) {
      super(props);

      this.getAllVehicleSpots = this.getAllVehicleSpots.bind(this);
      this.handleSelectedIcon = this.handleSelectedIcon.bind(this);
      this.getUrlExtension = this.getUrlExtension.bind(this);
      this.getAllVehicleSpotsType = this.getAllVehicleSpotsType.bind(this);
      this.addVehicleSpots = this.addVehicleSpots.bind(this);
      this.updateVehicleSpots = this.updateVehicleSpots.bind(this);
      this.DeleteSpotsID = this.DeleteSpotsID.bind(this);
      this.handleChange = this.handleChange.bind(this);

      this.state = {
         pageIDs: [],
         allVehicleSpots: [],
         getSpots: [],
         imagesIcons: [
            {
               birthday: require("../../../assets/icons/all-icons/2doorminiTruck.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/4doorminitruck.svg"),
            },
            // {
            //    birthday: require("../../../assets/icons/all-icons/CompactSedan.svg"),
            // },
            {
               birthday: require("../../../assets/icons/all-icons/Coupe.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/crossover.svg"),
            },
            // {
            //    birthday: require("../../../assets/icons/all-icons/FullsizeSedan.svg"),
            // },
            {
               birthday: require("../../../assets/icons/all-icons/Hatchback.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/LargeSUV.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/MidsizeSedan.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/MiniVan.svg"),
            },
            // {
            //    birthday: require("../../../assets/icons/all-icons/SUV.svg"),
            // },
            //////
            {
               birthday: require("../../../assets/icons/all-icons/band-car.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/beach-car.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/bicycle.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/huk-crain.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/motor-bike.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/racer-car.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/salahSingleDoor.svg"),
            },
            {
               birthday: require("../../../assets/icons/all-icons/trulah.svg"),
            },
         ],
         id: "",
         name: "",
         description: "",
         status: "",
         weight: "",
         width: "",
         currentPage: "",
         lastPage: "",
         selectedVehicleType: "",
         selectedVehicleTypeVal: "",

         vehicleSpotsName: "",
         vehicleSpotsNameVal: false,
         vehicleSpotsSize: "",
         vehicleSpotsSizeVal: false,
         vehicleSpotsWeight: "",
         vehicleSpotsWeightVal: false,
         vehicleSpotsWidth: "",
         vehicleSpotsWidthVal: false,
         vehicleSpotsType: "",
         vehicleSpotsTypeID: "",
         vehicleSpotsTypeVal: false,
         vehicleSpotsStatus: "",
         vehicleSpotsStatusVal: false,
         showEditProfileImage: "",
         editProfileImage: "",
         showProfileImage: "",
         editSpotsID: "",
         allVehicleSpotsType: [],

         loading: false,
         vehicleModelVal: false,

         loadingModel: true,
      };
   }
   async componentDidMount() {
      // Getting Models from API Call----------------------
      this.getAllVehicleSpots();
      this.getAllVehicleSpotsType();
   }
   async getAllVehicleSpotsType() {
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const getAllSpotsType = await getVehicleSpotsType(accessToken);
         let allVehicleType = [];
         if (getAllSpotsType.status === true) {
            getAllSpotsType.vehicle_categories?.map((types, typeId) => {
               allVehicleType.push({ label: types.name, value: types.id });
            });
            this.setState({ allVehicleSpotsType: allVehicleType });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   async getAllVehicleSpots(page) {
      let SpotsArray = [];
      let IDArray = [];
      try {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         const getAllSpots = await getVehicleSpots(accessToken, page);

         if (getAllSpots.status === true) {
            console.log(".,.,", getAllSpots);
            this.setState({ loading: true });
            if (page > 1) {
               this.setState({ currentPage: page });
            } else
               this.setState({
                  currentPage: getAllSpots.parking_vehicle.current_page,
               });
            this.setState({ lastPage: getAllSpots.parking_vehicle.last_page });
            this.setState({
               allVehicleSpots: getAllSpots.parking_vehicle.data,
            });

            for (
               let i = getAllSpots.parking_vehicle.from;
               i <= getAllSpots.parking_vehicle.to;
               i++
            ) {
               IDArray.push({ id: i });
            }
            this.setState({ pageIDs: IDArray });
            this.state.allVehicleSpots.map((mod, index) => {
               SpotsArray.push({
                  id: mod.id,
                  name: mod.name,
                  description: mod.description,

                  width: mod.width,
                  status: mod.status,
                  weight: mod.weight,
               });
               this.setState({ getSpots: SpotsArray });
            });
         }
      } catch (err) {
         console.log("error", err);
      }
   }

   async addVehicleSpots() {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);
      if (
         this.state.vehicleSpotsName == "" ||
         this.state.vehicleSpotsName == undefined
      ) {
         this.setState({ vehicleSpotsNameVal: true });
      }
      if (
         this.state.vehicleSpotsSize == "" ||
         this.state.vehicleSpotsSize == undefined
      ) {
         this.setState({ vehicleSpotsSizeVal: true });
      }
      if (this.state.addProfile == "" || this.state.addProfile == undefined) {
         this.setState({ showProfileImageVal: true });
      }
      if (
         this.state.vehicleSpotsWeight == "" ||
         this.state.vehicleSpotsWeight == undefined
      ) {
         this.setState({ vehicleSpotsWeightVal: true });
      }

      if (
         this.state.selectedVehicleType == "" ||
         this.state.selectedVehicleType == undefined
      ) {
         this.setState({ vehicleSpotsTypeVal: true });
      }
      if (
         this.state.vehicleSpotsWidth == "" ||
         this.state.vehicleSpotsWidth == undefined
      ) {
         this.setState({ vehicleSpotsWidthVal: true });
      }
      if (
         this.state.vehicleSpotsName != "" &&
         this.state.vehicleSpotsName != undefined &&
         this.state.vehicleSpotsSize != "" &&
         this.state.vehicleSpotsSize != undefined &&
         // this.state.vehicleSpotsWeight != "" &&
         // this.state.vehicleSpotsWeight != undefined &&
         this.state.vehicleSpotsWidth != "" &&
         this.state.vehicleSpotsWidth != undefined &&
         this.state.selectedVehicleType != "" &&
         this.state.addProfile != undefined &&
         this.state.addProfile != "" &&
         this.state.selectedVehicleType != undefined
      ) {
         try {
            const response = await AddSpots(
               accessToken,
               this.state.vehicleSpotsName,
               this.state.vehicleSpotsTypeID,
               this.state.vehicleSpotsSize,
               this.state.vehicleSpotsWeight,
               this.state.vehicleSpotsWidth,
               this.state.addProfile
            );
            console.log("response", response);

            if (response.status == true) {
               this.getAllVehicleSpots(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle spot added successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  vehicleCatagoriesName: "",
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: response.message,
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }
   async setInitialValue(
      id,
      name,
      weight,
      length,
      width,
      type,
      status,
      imageBG
   ) {
      if (imageBG == "undefinedundefined") {
         this.setState({
            showEditProfileImage: dummyCar,

            // status: status,
         });
      } else {
         this.setState({
            showEditProfileImage: imageBG,

            // status: status,
         });
      }

      this.setState({
         editSpotsID: id,
         vehicleSpotsName: name,
         vehicleSpotsSize: length,
         vehicleSpotsWidth: width,
         vehicleSpotsWeight: weight,
         vehicleSpotsType: type,
         selectedVehicleType: type,
         vehicleSpotsStatus: status,
      });
   }

   async updateVehicleSpots() {
      if (
         this.state.vehicleSpotsName == "" ||
         this.state.vehicleSpotsName == undefined
      ) {
         this.setState({ vehicleSpotsNameVal: true });
      }
      if (
         this.state.vehicleSpotsSize == "" ||
         this.state.vehicleSpotsSize == undefined
      ) {
         this.setState({ vehicleSpotsSizeVal: true });
      }
      // if (
      //    this.state.vehicleSpotsWeight == "" ||
      //    this.state.vehicleSpotsWeight == undefined
      // ) {
      //    this.setState({ vehicleSpotsWeightVal: true });
      // }
      if (
         this.state.showEditProfileImage == "" ||
         this.state.showEditProfileImage == undefined
      ) {
         this.setState({ showProfileImageVal: true });
      }

      if (
         this.state.vehicleSpotsWidth == "" ||
         this.state.vehicleSpotsWidth == undefined
      ) {
         this.setState({ vehicleSpotsWidthVal: true });
      }
      if (
         this.state.selectedVehicleType == "" ||
         this.state.selectedVehicleType == undefined
      ) {
         this.setState({ vehicleSpotsTypeVal: true });
      }

      if (
         this.state.vehicleSpotsName != "" &&
         this.state.vehicleSpotsName != undefined &&
         this.state.vehicleSpotsSize != "" &&
         this.state.vehicleSpotsSize != undefined &&
         this.state.showEditProfileImage != "" &&
         this.state.showEditProfileImage != undefined &&
         this.state.vehicleSpotsWidth != "" &&
         this.state.vehicleSpotsWidth != undefined &&
         this.state.selectedVehicleType != "" &&
         this.state.selectedVehicleType != undefined
      ) {
         let tokenAccess = localStorage.getItem("Token");
         let accessToken = JSON.parse(tokenAccess);
         try {
            const response = await EditVehicleSpots(
               accessToken,
               this.state.vehicleSpotsName,
               this.state.vehicleSpotsTypeID,
               this.state.vehicleSpotsSize,
               this.state.vehicleSpotsWeight,
               this.state.vehicleSpotsWidth,
               this.state.editSpotsID,
               this.state.editProfileImage
            );
            if (response.status == true) {
               this.getAllVehicleSpots(this.state.currentPage);
               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle spot updated successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
               this.setState({
                  modelName: "",
                  status: "",
                  editmodelID: "",
               });
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   async DeleteSpotsID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
            cancelButtonColor: "#808080",
            confirmButtonText: "Yes",
         });
         if (resultRemove.isConfirmed) {
            const result = await deleteVehicleSpots(accessToken, id);
            if (result.status === true) {
               this.getAllVehicleSpots(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Vehicle spot removed successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            } else {
               Swal.fire({
                  position: "top",
                  icon: "warning",
                  title: result.message,
                  showConfirmButton: false,
                  timer: 4000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   vehicleSpotsNameFunction = (e) => {
      this.setState({
         vehicleSpotsName: e.target.value,
         vehicleSpotsNameVal: false,
      });
   };
   vehicleSpotsSizeFunction = (e) => {
      this.setState({
         vehicleSpotsSize: e.target.value,
         vehicleSpotsSizeVal: false,
      });
   };
   vehicleSpotsWeightFunction = (e) => {
      this.setState({
         vehicleSpotsWeight: e.target.value,
         vehicleSpotsWeightVal: false,
      });
   };
   vehicleSpotsWidthFunction = (e) => {
      this.setState({
         vehicleSpotsWidth: e.target.value,
         vehicleSpotsWidthVal: false,
      });
   };

   editHandleSelectedType = (e) => {
      this.setState({ selectedVehicleType: e });
      this.setState({
         vehicleSpotsTypeID: e.value,
         vehicleSpotsTypeVal: false,
      });
   };

   async ActiveVehicleSpotsID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to activate this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            reverseButtons: true,
            confirmButtonText: "Yes",
         });
         if (resultRemove.isConfirmed) {
            const result = await setActiveSpots(accessToken, id);

            if (result.status === true) {
               this.getAllVehicleSpots(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Spots active successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }
   async DeActiveVehicleSpotsID(id) {
      let tokenAccess = localStorage.getItem("Token");
      let accessToken = JSON.parse(tokenAccess);

      try {
         const resultRemove = await Swal.fire({
            title: "Are you sure you want to Deactive this?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3a7786",
            cancelButtonColor: "#808080",
            reverseButtons: true,
            confirmButtonText: "Yes",
         });
         if (resultRemove.isConfirmed) {
            const result = await setDeActiveSpots(accessToken, id);

            if (result.status === true) {
               this.getAllVehicleSpots(this.state.currentPage);

               Swal.fire({
                  position: "top",
                  icon: "success",
                  title: "Spots Deactive successfully",
                  showConfirmButton: false,
                  timer: 1000,
               });
            }
         }
      } catch (err) {
         console.log("error ", err);
      }
   }

   AllClear = () => {
      this.setState({
         selectedVehicleType: "",
         vehicleSpotsName: "",
         vehicleSpotsType: "",
         vehicleSpotsSize: "",
         vehicleSpotsWeight: "",
         vehicleSpotsWidth: "",
         vehicleSpotsNameVal: false,
         vehicleSpotsTypeVal: false,
         vehicleSpotsSizeVal: false,
         vehicleSpotsWeightVal: false,
         vehicleSpotsWidthVal: false,
         showProfileImageVal: false,
      });
   };

   onKeyPressAddGroup = (e) => {
      if (e.which === 13) {
         this.btn.click();
      }
   };

   onKeyPressAddGroupEdit = (e) => {
      if (e.which === 13) {
         this.btnOne.click();
      }
   };
   paginate = async (data) => {
      let page = data.selected + 1;
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      // await this.getAllModels(page);
   };
   getDate = (date) => {
      return moment(date).format("DD/MM/YYYY HH:mm");
   };
   handleChange = (e, inputField) => {
      // Add Logo Error
      console.log("e.target.file[0].type", e.target.files[0].type);
      if (
         e.target?.files[0]?.type == "image/svg+xml" ||
         e.target?.files[0]?.type == "image/svg"
      ) {
         if (inputField == "logo") {
            if (e.target.files.length !== 0) {
               this.setState({
                  addProfile: e.target.files[0],
                  showProfileImageVal: false,
               });
               this.setState({
                  showProfileImage: URL.createObjectURL(e.target.files[0]),
               });
            } else if (e.target.files.length === 0) {
               // this.setState({ showProfileImage: "" });
            }
            //   if (e.target.value.length < 1) {
            //     setFNameThrowError(true);
            //   } else setFNameThrowError(false);
         }
         if (inputField == "Elogo") {
            if (e.target.files.length !== 0) {
               console.log("e.target.files[0]", e.target.files[0]);
               this.setState({
                  editProfileImage: e.target.files[0],
                  showProfileImageVal: false,
               });
               this.setState({
                  showEditProfileImage: URL.createObjectURL(e.target.files[0]),
               });
            } else if (e.target.files.length === 0) {
               // this.setState({ showEditProfileImage: "" });
            }
            //   if (e.target.value.length < 1) {
            //     setFNameThrowError(true);
            //   } else setFNameThrowError(false);
         }
      } else {
         Swal.fire({
            position: "top",
            icon: "warning",
            title: "Please upload svg file",
            showConfirmButton: true,
            confirmButtonColor: "#3a7786",
            reverseButtons: true,
         });
      }
   };
   getUrlExtension = (url) => {
      return url.split(/[#?]/)[0].split(".").pop().trim();
   };

   handleSelectedIcon = async (e, img, type) => {
      var imgExt = this.getUrlExtension(img);

      const response = await fetch(img);
      const blob = await response.blob();
      const file = new File([blob], "profileImage." + imgExt, {
         type: blob.type,
      });

      if (type == "add") {
         this.setState({
            addProfile: file,
            showProfileImage: img,
            showProfileImageVal: false,
            // showProfileImageVal: false,
         });
      } else if (type == "edit") {
         this.setState({
            editProfileImage: file,
            showProfileImageVal: false,
         });
         this.setState({
            showEditProfileImage: img,
         });
      }
   };
   render() {
      return (
         <>
            <div className="page-content">
               <Container>
                  {/* ///////////////////////////// */}
                  <h5 className="fs-5 mb-3 fw-bold">Vehicle Spots </h5>
                  {this.state.loading ? (
                     <>
                        <Card className="p-4 ">
                           <div className="d-flex justify-content-between">
                              <div></div>
                              <div>
                                 <Button
                                    type="button"
                                    onClick={() => {
                                       this.AllClear();
                                       this.setState({
                                          showProfileImage: dummyCar,
                                          addProfile: "",
                                       });
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropOne"
                                    style={{
                                       background:
                                          "linear-gradient(#3E94A6, #323F4B)",
                                    }}
                                    className="btn-rounded my-2 me-2"
                                 >
                                    <span>Add Spot</span>
                                 </Button>
                              </div>
                           </div>
                           <div className="overflow-auto">
                              <table class="table bg-white table-responsive">
                                 <thead>
                                    <tr>
                                       <th scope="col">No.</th>
                                       <th scope="col">Icon</th>
                                       <th scope="col">Name</th>
                                       <th scope="col">Height</th>
                                       <th scope="col">Length</th>
                                       <th scope="col">Width</th>
                                       <th scope="col">Type</th>
                                       <th scope="col">Date</th>
                                       <th scope="col">Status</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <>
                                       {" "}
                                       {this.state.allVehicleSpots.map(
                                          (item, outer) => {
                                             return (
                                                <>
                                                   <tr key={outer}>
                                                      {this.state.pageIDs?.map(
                                                         (item, inner) =>
                                                            outer == inner ? (
                                                               <td>
                                                                  {item.id}
                                                               </td>
                                                            ) : null
                                                      )}
                                                      <td>
                                                         {item?.spot_image[0]
                                                            ?.folder_path +
                                                         item?.spot_image[0]
                                                            ?.file_name ? (
                                                            <img
                                                               className="img-fluid iconImage me-3"
                                                               style={{
                                                                  maxWidth:
                                                                     "50px",

                                                                  // height: "20px",
                                                                  // background:
                                                                  //    "linear-gradient(#3E94A6, #323F4B)",
                                                               }}
                                                               src={
                                                                  item
                                                                     ?.spot_image[0]
                                                                     ?.folder_path +
                                                                  item
                                                                     ?.spot_image[0]
                                                                     ?.file_name
                                                               }
                                                               alt=""
                                                            />
                                                         ) : (
                                                            <img
                                                               className="img-fluid iconImage me-3"
                                                               style={{
                                                                  background:
                                                                     "linear-gradient(#3E94A6, #323F4B)",
                                                               }}
                                                               src={dummyCar}
                                                               alt=""
                                                               width={"20px"}
                                                               height={"20px"}
                                                            />
                                                         )}
                                                      </td>
                                                      <td>
                                                         {item.name ?? "--"}
                                                      </td>
                                                      <td>
                                                         {item.height !==
                                                            null &&
                                                         item.height !==
                                                            undefined &&
                                                         item.height !==
                                                            "null" &&
                                                         item.height != ""
                                                            ? item.height
                                                            : "--"}
                                                      </td>
                                                      <td>
                                                         {item.length ?? "--"}
                                                      </td>
                                                      <td>
                                                         {item.width ?? "--"}
                                                      </td>
                                                      <td>
                                                         {item.category.name}
                                                      </td>
                                                      <td>
                                                         {this.getDate(
                                                            item.created_at
                                                         )}
                                                      </td>

                                                      <td>
                                                         {item.status ==
                                                         "active" ? (
                                                            <span
                                                               className="badge bg-success "
                                                               style={{
                                                                  paddingLeft: 13,
                                                                  paddingRight: 13,
                                                               }}
                                                            >
                                                               Active
                                                            </span>
                                                         ) : item.status ==
                                                           "deactive" ? (
                                                            <span className="badge bg-danger px-2">
                                                               Deactive
                                                            </span>
                                                         ) : null}
                                                      </td>
                                                      <td>
                                                         <Link
                                                            to="#"
                                                            className="me-3 text-primary"
                                                         >
                                                            <i
                                                               title="Edit"
                                                               className="mdi mdi-pencil font-size-18"
                                                               data-bs-toggle="modal"
                                                               data-bs-target="#staticBackdropTwo"
                                                               onClick={() =>
                                                                  this.setInitialValue(
                                                                     item.id,
                                                                     item.name,
                                                                     item.height,
                                                                     item.length,
                                                                     item.width,
                                                                     item
                                                                        .category
                                                                        .name,
                                                                     item.status,
                                                                     `${item?.spot_image[0]?.folder_path}${item?.spot_image[0]?.file_name}`
                                                                  )
                                                               }
                                                            ></i>
                                                         </Link>
                                                         <Link
                                                            to="#"
                                                            className="text-danger"
                                                         >
                                                            <i
                                                               title="Delete"
                                                               className="mdi mdi-trash-can font-size-18 me-3"
                                                               onClick={() =>
                                                                  this.DeleteSpotsID(
                                                                     item.id
                                                                  )
                                                               }
                                                            ></i>
                                                         </Link>
                                                         {item?.status ==
                                                         "active" ? (
                                                            <Link
                                                               to="#"
                                                               className="text-danger"
                                                               title="Deactive"
                                                            >
                                                               <img
                                                                  src={
                                                                     spots_deactive
                                                                  }
                                                                  alt=""
                                                                  width={"20px"}
                                                                  className="mb-1"
                                                                  onClick={() =>
                                                                     this.DeActiveVehicleSpotsID(
                                                                        item.id
                                                                     )
                                                                  }
                                                               />
                                                            </Link>
                                                         ) : item?.status ==
                                                           "deactive" ? (
                                                            <Link
                                                               to="#"
                                                               className="text-danger"
                                                               title="Active"
                                                            >
                                                               <img
                                                                  src={
                                                                     spots_active
                                                                  }
                                                                  alt=""
                                                                  width={"20px"}
                                                                  className="mb-1"
                                                                  onClick={() =>
                                                                     this.ActiveVehicleSpotsID(
                                                                        item.id
                                                                     )
                                                                  }
                                                               />
                                                            </Link>
                                                         ) : (
                                                            ""
                                                         )}
                                                      </td>
                                                   </tr>
                                                </>
                                             );
                                          }
                                       )}{" "}
                                    </>
                                 </tbody>
                              </table>
                              {this.state.allVehicleSpots?.length < 1 && (
                                 <p className="text-center pt-5 fs-4 fw-bold ">
                                    Record Not Found.
                                 </p>
                              )}
                           </div>
                           {this.state.lastPage > 1 && (
                              <Pagination
                                 pageCount={this.state.lastPage}
                                 paginate={this.paginate}
                              />
                           )}
                        </Card>
                     </>
                  ) : (
                     <div
                        className="text-center d-flex align-items-center justify-content-center "
                        style={{ height: "50vh" }}
                     >
                        <div class="spinner-border text-enter" role="status">
                           <span class="visually-hidden"></span>
                        </div>
                     </div>
                  )}
               </Container>
               {/* ///////////////////////add model */}
               <div
                  className="modal fade "
                  id="staticBackdropOne"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Add Vehicle Spot
                           </h5>
                           <button
                              type="button"
                              onClick={this.AllClear}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body ">
                           <div className=" d-flex flex-column justify-content-center">
                              <div className="mt-3  row">
                                 <div className="m-auto pe-2 pt-3 col-4">
                                    <div
                                       className=" image-input-outline"
                                       data-kt-image-input="true"
                                    >
                                       <div
                                          className="image-input-wrapper  d-flex"
                                          style={{
                                             width: "128px",
                                             height: "125px",
                                             border: "1.5px solid #3e94a6",
                                          }}
                                       >
                                          <img
                                             src={this.state.showProfileImage}
                                             className="modal_image p-2 "
                                             alt=""
                                             style={{
                                                maxHeight: "125px",
                                                maxWidth: "125px",
                                                // background:
                                                //    "linear-gradient(#3E94A6, #323F4B)",
                                             }}
                                          />
                                       </div>
                                       {this.state.showProfileImageVal ? (
                                          <div className="modelValidation">
                                             Icon is required
                                          </div>
                                       ) : null}
                                    </div>
                                    <label
                                       for="avatar"
                                       style={{
                                          overflow: "hidden",
                                          cursor: "pointer",
                                       }}
                                       data-kt-image-input-action="change"
                                       data-bs-toggle="tooltip"
                                       title="Change avatar"
                                    >
                                       <i
                                          title="Add"
                                          data-bs-toggle="modal"
                                          data-bs-target="#addIconModal"
                                          style={{
                                             position: "absolute",
                                             left: "129px",
                                             top: "-5px",
                                             zIndex: 111,
                                          }}
                                       >
                                          <img
                                             src={pencil}
                                             width={30}
                                             height={25}
                                             alt=""
                                             data-bs-toggle="modal"
                                             data-bs-target="#addIconModal"
                                             className="d-inline cursor-pointer"
                                          />
                                       </i>
                                       {/* <input
                                          type="file"
                                          name="avatar"
                                          ref="upload"
                                          accept=".svg"
                                          onChange={(e) =>
                                             this.handleChange(e, "logo")
                                          }
                                          onClick={(e) => {
                                             e.target.value = null;
                                          }}
                                          style={{
                                             opacity: 0,
                                             left: "122px",
                                             top: "-28px",
                                             position: "absolute",
                                             zIndex: 111,
                                             cursor: "pointer",
                                             width: "30px",
                                             height: "60px",
                                          }}
                                       />
                                       <input type="hidden" /> */}
                                    </label>
                                 </div>
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Spot Name
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid "
                                    placeholder="Enter spot name"
                                    value={this.state.vehicleSpotsName}
                                    onChange={(e) =>
                                       this.vehicleSpotsNameFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }
                                 />

                                 {this.state.vehicleSpotsNameVal ? (
                                    <div className="modelValidation">
                                       Enter spot name
                                    </div>
                                 ) : null}
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0 mt-3"
                                 >
                                    Vehicle Length {"(feet)"}
                                 </label>
                                 <input
                                    type="number"
                                    className="form-control form-control-solid "
                                    placeholder="Enter vehicle length "
                                    value={this.state.vehicleSpotsSize}
                                    onChange={(e) =>
                                       this.vehicleSpotsSizeFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }
                                 />

                                 {this.state.vehicleSpotsSizeVal ? (
                                    <div className="modelValidation">
                                       Enter vehicle length
                                    </div>
                                 ) : null}
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0 mt-3"
                                 >
                                    Vehicle Height {"(feet)"}
                                 </label>
                                 <input
                                    type="number"
                                    className="form-control form-control-solid "
                                    placeholder="Enter vehicle height "
                                    value={this.state.vehicleSpotsWeight}
                                    onChange={(e) =>
                                       this.vehicleSpotsWeightFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }
                                 />
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0 mt-3"
                                 >
                                    Vehicle Width {"(feet)"}
                                 </label>
                                 <input
                                    type="number"
                                    className="form-control form-control-solid "
                                    placeholder="Enter vehicle width "
                                    value={this.state.vehicleSpotsWidth}
                                    onChange={(e) =>
                                       this.vehicleSpotsWidthFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroup(e)
                                    }
                                 />

                                 {this.state.vehicleSpotsWidthVal ? (
                                    <div className="modelValidation">
                                       Enter vehicle width
                                    </div>
                                 ) : null}
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0 mt-3"
                                 >
                                    Vehicle Type
                                 </label>
                                 <Select
                                    class="form-select"
                                    placeholder="Vehicle type"
                                    value={this.state.selectedVehicleType}
                                    onChange={(e) =>
                                       this.editHandleSelectedType(e)
                                    }
                                    options={this.state.allVehicleSpotsType}
                                    aria-label="Default select example"
                                    // onChange={(e) => this.setState({ countryID: e.value, countryIDVal: false })}
                                 />

                                 {this.state.vehicleSpotsTypeVal ? (
                                    <div className="modelValidation">
                                       Select vehicle type
                                    </div>
                                 ) : null}
                              </div>
                           </div>
                        </div>

                        <div className="modal-footer">
                           <button
                              type="button"
                              onClick={this.AllClear}
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           {this.state.vehicleSpotsName != "" &&
                           this.state.vehicleSpotsName != undefined &&
                           this.state.vehicleSpotsSize != "" &&
                           this.state.vehicleSpotsSize != undefined &&
                           this.state.addProfile != "" &&
                           this.state.addProfile != undefined &&
                           this.state.vehicleSpotsWidth != undefined &&
                           this.state.vehicleSpotsWidth != "" &&
                           // this.state.vehicleSpotsWeight != undefined &&
                           this.state.selectedVehicleType != "" &&
                           this.state.selectedVehicleType != undefined ? (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.addVehicleSpots}
                                 data-bs-dismiss="modal"
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Spot
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 className="btn btn-primary"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 onClick={this.addVehicleSpots}
                                 ref={(node) => (this.btn = node)}
                              >
                                 Add Spot
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               {/* /////////////////edit model */}
               <div
                  className="modal fade"
                  id="staticBackdropTwo"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog">
                     <div className="modal-content ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Edit Spot
                           </h5>
                           <button
                              onClick={this.AllClear}
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body">
                           <div className=" d-flex flex-column justify-content-center">
                              <div className="mt-3  row">
                                 <div className="m-auto col-4">
                                    <div
                                       className=" image-input-outline"
                                       data-kt-image-input="true"
                                    >
                                       <div
                                          className="image-input-wrapper d-flex "
                                          style={{
                                             height: "125px",
                                             width: "125px",
                                             border: "1.5px solid #3e94a6",

                                             // background:
                                             //    "linear-gradient(#3E94A6, #323F4B)",
                                          }}
                                       >
                                          <img
                                             src={
                                                this.state.showEditProfileImage
                                             }
                                             className="img-fluid modal_image w-100 h-100 px-2"
                                             alt=""
                                             style={{
                                                maxHeight: "125px",
                                                maxWidth: "125px",
                                             }}
                                          />
                                       </div>
                                       {this.state.showProfileImageVal ? (
                                          <div className="modelValidation">
                                             Image is required
                                          </div>
                                       ) : null}
                                    </div>
                                    <label
                                       for="avatar"
                                       style={{
                                          overflow: "hidden",
                                          cursor: "pointer",
                                       }}
                                       data-kt-image-input-action="change"
                                       data-bs-toggle="tooltip"
                                       title="Edit"
                                    >
                                       <i
                                          title="Edit"
                                          data-bs-toggle="modal"
                                          data-bs-target="#editIconModal"
                                          style={{
                                             position: "absolute",
                                             left: "128px",
                                             top: "-20px",
                                             zIndex: 111,
                                          }}
                                       >
                                          <img
                                             src={pencil}
                                             width={30}
                                             height={25}
                                             data-bs-toggle="modal"
                                             data-bs-target="#editIconModal"
                                             alt=""
                                             className="d-inline cursor-pointer"
                                          />
                                       </i>
                                       {/* <input
                                          type="file"
                                          name="avatar"
                                          ref="upload"
                                          accept=".svg"
                                          onChange={(e) =>
                                             this.handleChange(e, "Elogo")
                                          }
                                          style={{
                                             opacity: 0,
                                             left: "122px",
                                             top: "-45px",
                                             position: "absolute",
                                             zIndex: 111,
                                             cursor: "pointer",
                                             width: "30px",
                                             height: "60px",
                                          }}
                                       />
                                       <input type="hidden" /> */}
                                    </label>
                                 </div>
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0"
                                 >
                                    Spot Name
                                 </label>
                                 <input
                                    type="text"
                                    className="form-control form-control-solid "
                                    placeholder="Enter spot name"
                                    value={this.state.vehicleSpotsName}
                                    onChange={(e) =>
                                       this.vehicleSpotsNameFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroupEdit(e)
                                    }
                                 />
                                 {this.state.vehicleSpotsNameVal ? (
                                    <div className="modelValidation">
                                       Enter spot name
                                    </div>
                                 ) : null}
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0 mt-3"
                                 >
                                    Vehicle Length {"(feet)"}
                                 </label>
                                 <input
                                    type="number"
                                    className="form-control form-control-solid "
                                    placeholder="Enter vehicle length "
                                    value={this.state.vehicleSpotsSize}
                                    onChange={(e) =>
                                       this.vehicleSpotsSizeFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroupEdit(e)
                                    }
                                 />
                                 {this.state.vehicleSpotsSizeVal ? (
                                    <div className="modelValidation">
                                       Enter vehicle length
                                    </div>
                                 ) : null}
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder  form-label mb-0 mt-3"
                                 >
                                    Vehicle Height {"(feet)"}
                                 </label>
                                 <input
                                    type="number"
                                    className="form-control form-control-solid "
                                    placeholder="Enter vehicle height "
                                    value={this.state.vehicleSpotsWeight}
                                    onChange={(e) =>
                                       this.vehicleSpotsWeightFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroupEdit(e)
                                    }
                                 />
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0 mt-3"
                                 >
                                    Vehicle Width {"(feet)"}
                                 </label>
                                 <input
                                    type="number"
                                    className="form-control form-control-solid "
                                    placeholder="Enter vehicle width "
                                    value={this.state.vehicleSpotsWidth}
                                    onChange={(e) =>
                                       this.vehicleSpotsWidthFunction(e)
                                    }
                                    onKeyPress={(e) =>
                                       this.onKeyPressAddGroupEdit(e)
                                    }
                                 />
                                 {this.state.vehicleSpotsWidthVal ? (
                                    <div className="modelValidation">
                                       Enter vehicle width
                                    </div>
                                 ) : null}
                              </div>
                              <div className="">
                                 <label
                                    htmlFor="exampleFormControlInput1 "
                                    className="fw-bolder required form-label mb-0 mt-3"
                                 >
                                    Vehicle Type
                                 </label>
                                 <Select
                                    class="form-select"
                                    placeholder={this.state.selectedVehicleType}
                                    value={this.state.selectedVehicleType}
                                    onChange={(e) =>
                                       this.editHandleSelectedType(e)
                                    }
                                    options={this.state.allVehicleSpotsType}
                                    aria-label="Default select example"
                                    // onChange={(e) => this.setState({ countryID: e.value, countryIDVal: false })}
                                 />

                                 {this.state.vehicleSpotsTypeVal ? (
                                    <div className="modelValidation">
                                       Select vehicle type
                                    </div>
                                 ) : null}
                              </div>
                           </div>
                        </div>

                        <div className="modal-footer">
                           <button
                              onClick={this.AllClear}
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           {this.state.vehicleSpotsName != "" &&
                           this.state.vehicleSpotsName != undefined &&
                           this.state.vehicleSpotsSize != "" &&
                           this.state.vehicleSpotsSize != undefined &&
                           this.state.showEditProfileImage != "" &&
                           this.state.vehicleSpotsWidth != undefined &&
                           this.state.vehicleSpotsWidth != "" &&
                           this.state.showEditProfileImage != undefined &&
                           this.state.selectedVehicleType != "" &&
                           this.state.selectedVehicleType != undefined ? (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 data-bs-dismiss="modal"
                                 onClick={() => this.updateVehicleSpots()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Save
                              </button>
                           ) : (
                              <button
                                 type="button"
                                 style={{
                                    background:
                                       "linear-gradient(#3E94A6, #323F4B)",
                                 }}
                                 className="btn btn-primary"
                                 onClick={() => this.updateVehicleSpots()}
                                 ref={(node) => (this.btnOne = node)}
                              >
                                 Save
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               {/* Add icon  */}

               <div
                  className="modal fade"
                  id="addIconModal"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog modal-lg">
                     <div className="modal-content ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Select icon
                           </h5>
                           <button
                              onClick={this.AllClear}
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body">
                           <div className="row ms-5">
                              {this.state.imagesIcons?.map((img, index) => (
                                 <div
                                    className="col-2 m-1"
                                    key={index}
                                    style={{
                                       border: `${
                                          this.state.showProfileImage ===
                                          img.birthday.default
                                             ? "2.5px solid #3e94a6"
                                             : "1px solid #88919a"
                                       }`,
                                    }}
                                 >
                                    <img
                                       className=" w-100  m-1 "
                                       src={img.birthday.default}
                                       style={{ height: 100 }}
                                       alt="Selected Image"
                                       // onLoad={true}
                                       onClick={(e) =>
                                          this.handleSelectedIcon(
                                             e,
                                             img.birthday.default,
                                             "add"
                                          )
                                       }
                                    />
                                    <i
                                       className={
                                          this.state.showProfileImage ===
                                          img.birthday.default
                                             ? "mdi mdi-check mdi-24px text-white position-absolute "
                                             : ""
                                       }
                                       style={{
                                          left: 0,
                                          top: 0,
                                          backgroundColor: "#3e94a6",
                                          borderBottomRightRadius: 100,
                                       }}
                                    ></i>
                                 </div>
                              ))}
                           </div>
                        </div>

                        <div className="modal-footer">
                           <button
                              onClick={this.AllClear}
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           <button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                           >
                              Save
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
               {/* Edit icon  */}
               <div
                  className="modal fade"
                  id="editIconModal"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
               >
                  <div className="modal-dialog modal-lg">
                     <div className="modal-content ">
                        <div className="modal-header">
                           <h5 className="modal-title" id="staticBackdropLabel">
                              Select icon
                           </h5>
                           <button
                              onClick={this.AllClear}
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           />
                        </div>
                        <div className="modal-body">
                           <div className="row ms-5">
                              {this.state.imagesIcons?.map((img, index) => (
                                 <div
                                    className="col-2 m-1"
                                    key={index}
                                    style={{
                                       border: `${
                                          this.state.showEditProfileImage ===
                                          img.birthday.default
                                             ? "2.5px solid #3e94a6"
                                             : "1px solid #88919a"
                                       }`,
                                    }}
                                 >
                                    <img
                                       className=" w-100  m-1 "
                                       src={img.birthday.default}
                                       style={{ height: 100 }}
                                       alt="Selected Image"
                                       // onLoad={true}
                                       onClick={(e) =>
                                          this.handleSelectedIcon(
                                             e,
                                             img.birthday.default,
                                             "edit"
                                          )
                                       }
                                    />
                                    <i
                                       className={
                                          this.state.showEditProfileImage ===
                                          img.birthday.default
                                             ? "mdi mdi-check mdi-24px text-white position-absolute "
                                             : ""
                                       }
                                       style={{
                                          left: 0,
                                          top: 0,
                                          backgroundColor: "#3e94a6",
                                          borderBottomRightRadius: 100,
                                       }}
                                    ></i>
                                 </div>
                              ))}
                           </div>
                        </div>

                        <div className="modal-footer">
                           <button
                              onClick={this.AllClear}
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>

                           <button
                              type="button"
                              style={{
                                 background:
                                    "linear-gradient(#3E94A6, #323F4B)",
                              }}
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                           >
                              Save
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default MangeVehicleSpots;
