import React, { Component } from "react";
import { Row, Col, Button, Alert, Container, Label } from "reactstrap";
import Select from "react-select";
// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";
import MerchantForm from "./MerchantRegister";
import CorporateForm from "./CorporateRegister";
import IndividualForm from "./IndividualRegister";
// action
import {
   registerUser,
   registerUserFailed,
   apiError,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import logoKark from "../../assets/images/logo-dark.png";

const registerOption = [
   {
      label: "Please select Registration Option",
      options: [
         { label: "Individual", value: "Individual" },
         { label: "Corporate", value: "Corporate" },
         { label: "Merchant", value: "Merchant" },
      ],
   },
];

class Register extends Component {
   constructor(props) {
      super(props);
      this.state = {
         email: "",
         username: "",
         password: "",
         RepeatPassword: "",

         selectedGroup: "Individual",
      };
      this.handleSubmit = this.handleSubmit.bind(this);
   }

   handleSubmit(event, values) {
      this.props.registerUser(values);
   }

   componentDidMount() {
      this.props.registerUserFailed("");
      this.props.apiError("");
      document.body.classList.add("auth-body-bg");
   }
   handleSelectGroup = (selectedGroup) => {
      this.setState({ selectedGroup: selectedGroup.value });
   };
   render() {
      const { selectedGroup } = this.state.selectedGroup;
      return (
         <React.Fragment>
            <div>
               <Container fluid className="p-0">
                  {/* <Row className='g-0'>
              <Col>
                <div className='p-2 lg-p-5 d-flex align-items-center'>
                  <div className='w-100'> */}
                  <Row className="justify-content-center">
                     <Col>
                        <div>
                           <div className="text-center">
                              <div>
                                 <Link to="#" className="logo">
                                    <img
                                       src={logoKark}
                                       height="70"
                                       alt="logo"
                                    />
                                 </Link>
                              </div>

                              <h4 className="font-size-18 mt-4">
                                 Register Account
                              </h4>
                              {/* <p className='text-muted text-center'>
                      Welcome to CharmVisor the Home of Happy People.
                    </p> */}
                           </div>

                           {this.props.user && (
                              <Alert color="success">
                                 Registration Done Successfully.
                              </Alert>
                           )}

                           {this.props.registrationError && (
                              <Alert color="danger">
                                 {this.props.registrationError}
                              </Alert>
                           )}

                           <div className="p-2 mt-5">
                              <AvForm
                                 onValidSubmit={this.handleSubmit}
                                 className="form-horizontal"
                              >
                                 <Col lg={4} className="offset-1">
                                    <div className="mb-4">
                                       <Label className="form-label">
                                          Please Select Register as ?
                                       </Label>
                                       <Select
                                          value={selectedGroup}
                                          onChange={this.handleSelectGroup}
                                          options={registerOption}
                                          classNamePrefix="select2-selection"
                                       />
                                    </div>
                                 </Col>

                                 {this.state.selectedGroup === "Individual" ? (
                                    <Row>
                                       <Col lg={12}>
                                          <IndividualForm />
                                       </Col>
                                    </Row>
                                 ) : this.state.selectedGroup ===
                                   "Corporate" ? (
                                    <Row>
                                       <Col lg={12}>
                                          <CorporateForm />
                                       </Col>
                                    </Row>
                                 ) : this.state.selectedGroup === "Merchant" ? (
                                    <Row>
                                       <Col lg={12}>
                                          <MerchantForm />
                                       </Col>
                                    </Row>
                                 ) : (
                                    <></>
                                 )}

                                 <div className="text-center mt-5">
                                    <Button
                                       color="primary"
                                       className="w-md waves-effect waves-light"
                                       type="submit"
                                    >
                                       {this.props.loading
                                          ? "Loading ..."
                                          : "Register"}
                                    </Button>
                                 </div>
                                 <div className="mt-4 text-center">
                                    <p className="mb-0">
                                       {/* By registering you agree to the Nazox{' '}
                          <Link to='#' className='text-primary'>
                            Terms of Use
                          </Link> */}
                                    </p>
                                 </div>
                              </AvForm>
                           </div>

                           <div className="mt-5 text-center">
                              <p>
                                 Already have an account ?{" "}
                                 <Link
                                    to="/login"
                                    className="fw-medium text-primary"
                                 >
                                    {" "}
                                    Login
                                 </Link>{" "}
                              </p>
                              {/* <p>
                      © 2021 Nazox. Crafted with{' '}
                      <i className='mdi mdi-heart text-danger'></i> by
                      Themesdesign
                    </p> */}
                           </div>
                        </div>
                     </Col>
                  </Row>
                  {/* </div>
                </div>
              </Col>
            </Row> */}
               </Container>
            </div>
         </React.Fragment>
      );
   }
}

const mapStatetoProps = (state) => {
   const { user, registrationError, loading } = state.Account;
   return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
   registerUser,
   apiError,
   registerUserFailed,
})(Register);
